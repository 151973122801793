import INTERNAL_ROUTES from 'Internal/redux/config/RouteNames';
import {
    BUSINESS_GROUP_MANAGEMENT_PERMISSION_KEY, FACILITY_MANAGEMENT_PERMISSION_KEY,
    USER_MANAGEMENT_PERMISSION_KEY,
} from 'Commons/components/business/user/config/Constants';
import LATE_EVENT_PERMISSION_KEYS from 'External/containers/operations/components/lateEvent/PermissionKeys';
import {
    DELINQUENCY_TRACKING_LATE_EVENT_STORE,
    DELINQUENCY_TRACKING_LOCKOUT_EVENT_STORE,
    DELINQUENCY_TRACKING_SCHEDULED_EVENT_STORE,
    DELINQUENCY_TRACKING_OVERVIEW_STORE,
    DELINQUENCY_TRACKING_WRONG_ENTRY_STORE,
} from 'Internal/containers/delinquencyTracking/list/config/Constants';
import {
    JOURNAL_CONFIGURATION_CHANGES_STORE,
    JOURNAL_LATE_EVENT_STORE,
    JOURNAL_LOCKOUT_EVENT_STORE,
    JOURNAL_USER_MANAGEMENT_STORE,
    JOURNAL_USER_ROLE_STORE,
} from 'Internal/containers/journal/list/config/Constants';

/**
 * Structure of Dependency Config
 * Dependency Config contains all component names at the top level.
 * If it is not a component name it is considered as a route.
 * To specify config by Component Name:
 * DependencyConfig['componentName'] = {
 *  critical: {
 *      'storeKey1': { //Each key can specify the list of dependencies
 *          dependency: ['storeKey2', 'storeKey3']
 *      },
 *  }, // These are the critical keys that the component requires to even load.
 *  lazy: {
 *      'storeKey4': {
 *          dependency: ['storeKey5', 'storeKey6']
 *      }
 *  }, // These are the lazy store keys that needs to be loaded after the component is loaded
 *  onDemand: {
 *
 *  }, // These are the storeKeys that the component can have access to when needed, but will always listen.
 *  onMount: {
 *
 *  }, // The store keys to be fetched after the hoc has been mounted.
 * }
 * To specify config by Route:
 * Route: '/a/facilities/45/units'
 * DependencyConfig['a']['facilities/:id']['units]
 * While resolving the route directly,
 * We traverse the config from the top most level.
 * At Each level the critical keys are resolved along with its dependencies
 */

const userSetUpConfig = { lazy: { user: {} } };

const DependencyConfig = {
    HeaderInt: { onDemand: { userProfile: {} } },
    FacilityList: { lazy: { facilities: {}, allFacilities: {} } },
    FacilityForm: { onDemand: { facilities: {} } },
    AccountForm: { onDemand: { accounts: {} } },
    UserForm: {
        onDemand: { currentAccountId: {}, accounts: {}, userProfile: {} },
        permissionKey: USER_MANAGEMENT_PERMISSION_KEY,
    },
    UserSetupAction: {
        lazy: { userProfile: {}, account: {} },
        onDemand: { businessGroup: {}, userRoleAction: {}, facilities: {} },
        onMount: { facilities: {} },
        permissionKey: USER_MANAGEMENT_PERMISSION_KEY,
    },
    SearchableDropdown: { onDemand: { timezone: {} } },
    RentAssessmentHistory: { lazy: { rentAssessmentHistory: {} }, onDemand: { rentAssessmentHistory: {} } },
    AutoPaymentHistory: { lazy: { dailyTaskSummary: {} }, onDemand: { dailyTaskSummary: {} } },
    AutoInvoicingHistory: { lazy: { dailyTaskSummary: {} }, onDemand: { dailyTaskSummary: {} } },
    RentAdjustmentSummary: { lazy: { dailyTaskSummary: {} }, onDemand: { dailyTaskSummary: {} } },
    DelinquencySummary: { lazy: { dailyTaskSummary: {} }, onDemand: { dailyTaskSummary: {} } },
    TaskSummary: { critical: { allFacilities: {} } },
    LateEventsTracking: { lazy: { [DELINQUENCY_TRACKING_LATE_EVENT_STORE]: {} } },
    LockoutEventsTracking: { lazy: { [DELINQUENCY_TRACKING_LOCKOUT_EVENT_STORE]: {} } },
    DelinquencyOverview: { lazy: { [DELINQUENCY_TRACKING_OVERVIEW_STORE]: {} } },
    JournalConfigurationChanges: { lazy: { [JOURNAL_CONFIGURATION_CHANGES_STORE]: {} } },
    JournalLateEvents: { lazy: { [JOURNAL_LATE_EVENT_STORE]: {} } },
    JournalLockoutEvents: { lazy: { [JOURNAL_LOCKOUT_EVENT_STORE]: {} } },
    JournalUserRoles: { lazy: { [JOURNAL_USER_ROLE_STORE]: {} } },
    JournalUserManagements: { lazy: { [JOURNAL_USER_MANAGEMENT_STORE]: {} } },
    SchedulerCreation: { lazy: { missingSchedulers: {} } },
    ScheduledEventsTracking: { lazy: { [DELINQUENCY_TRACKING_SCHEDULED_EVENT_STORE]: {} } },
    WrongEntriesTracking: { lazy: { [DELINQUENCY_TRACKING_WRONG_ENTRY_STORE]: {} } },
    [INTERNAL_ROUTES.INTERNAL_MAIN]: {
        critical: { userProfile: {}, permissionRoles: {}, permission: { dependency: ['permissionRoles'] } },
        onMount: { permission: {}, permissionRoles: {} },
        [INTERNAL_ROUTES.USER_PROFILE]: { onDemand: { userProfile: {} } },
        [INTERNAL_ROUTES.SYRASOFT_USER_ROLES]: {
            critical: { userRole: {} },
            permissionKey: USER_MANAGEMENT_PERMISSION_KEY,
        },
        [INTERNAL_ROUTES.EDIT_SYRASOFT_USER_ROLE]: {
            critical: { userRole: {} },
            permissionKey: USER_MANAGEMENT_PERMISSION_KEY,
        },
        [INTERNAL_ROUTES.ACCOUNT_LIST]: { lazy: { accounts: {} } },
        [INTERNAL_ROUTES.DELINQUENCY_TRACKING_OVERVIEW]: {
            permissionKey:
            LATE_EVENT_PERMISSION_KEYS.ASSESS_LATE_CHARGES,
            onDemand: {
                [DELINQUENCY_TRACKING_LATE_EVENT_STORE]: {},
                [DELINQUENCY_TRACKING_LOCKOUT_EVENT_STORE]: {},
                [DELINQUENCY_TRACKING_SCHEDULED_EVENT_STORE]: {},
                [DELINQUENCY_TRACKING_OVERVIEW_STORE]: {},
                [DELINQUENCY_TRACKING_WRONG_ENTRY_STORE]: {},
            },
        },
        [INTERNAL_ROUTES.JOURNAL]: {
            onDemand: {
                [JOURNAL_CONFIGURATION_CHANGES_STORE]: {},
                [JOURNAL_LATE_EVENT_STORE]: {},
                [JOURNAL_LOCKOUT_EVENT_STORE]: {},
                [JOURNAL_USER_ROLE_STORE]: {},
                [JOURNAL_USER_MANAGEMENT_STORE]: {},
            },
        },
        [INTERNAL_ROUTES.SCHEDULE_LIST]: { critical: { schedules: {}, facilities: {} } },
        [INTERNAL_ROUTES.USER_LIST]: { lazy: { user: {}, allUsers: {} }, permissionKey: USER_MANAGEMENT_PERMISSION_KEY },
        [INTERNAL_ROUTES.DAILY_SUMMARY_LIST]: {
            critical: { allFacilities: {} },
            onDemand: { rentAssessmentHistory: {}, dailyTaskSummary: {} },
        },
        [INTERNAL_ROUTES.SAVED_DOCUMENTS_LIST]: {
            critical: { allFacilities: {} },
            lazy: { savedDocuments: {} },
            onDemand: { savedDocuments: {} },
        },
        [INTERNAL_ROUTES.ASYNC_TASK_LIST]: { lazy: { asyncTaskList: {} } },
        [INTERNAL_ROUTES.ACCOUNT_MANAGEMENT]: {
            onDemand: { facilities: {}, currentAccount: {}, userRole: {} },
            [INTERNAL_ROUTES.BUSINESS_GROUP_LIST]: { lazy: { businessGroups: {} } },
        },
        [INTERNAL_ROUTES.SANDBOX]: { lazy: { sandbox: {} }, critical: { userProfile: {}, userRole: {} } },
        [INTERNAL_ROUTES.FACILITY_VIEW]: { onDemand: { facilities: {}, user: {} } },
        [INTERNAL_ROUTES.USER_SETUP]: { ...userSetUpConfig },
        [INTERNAL_ROUTES.EDIT_USER_SET_UP]: { ...userSetUpConfig },
        [INTERNAL_ROUTES.FACILITY_CREATE_FROM_ACCOUNT]: { permissionKey: BUSINESS_GROUP_MANAGEMENT_PERMISSION_KEY },
        [INTERNAL_ROUTES.FACILITY_EDIT_FROM_ACCOUNT]: { permissionKey: FACILITY_MANAGEMENT_PERMISSION_KEY },
    },
};

export default DependencyConfig;
