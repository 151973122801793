import localisable from 'Commons/config/strings/localisable';
import { createEnumMap, mapEnum } from 'Commons/helpers/utils/Formatter';
import { RENTAL_STATUS } from 'External/containers/unit/form/config/Constants';
import { capitalize } from 'Commons/helpers/utils/DataHelpers';
import enums from './Enums';

const DATA_FETCH_TIMEOUT = 300;

const RENT_ADJUSTMENT_STATUS = createEnumMap(enums.status);

const CHARGES_AFTER_RENT_ADJUSTMENT_REQUEST_SOURCE = createEnumMap(enums.chargesAfterRentAdjustmentRequestSource);

const rentalStatusRadioList = [{
    label: localisable.rented,
    value: RENTAL_STATUS.Rented.value,
}, {
    label: localisable.vacant,
    value: RENTAL_STATUS.Vacant.value,
}];

const BY_TENANTS_FILTERS_TYPE = createEnumMap(enums.byTenantFiltersType);

const RECUR_PERIOD = mapEnum(enums.recurPeriod, (label) => {
    if (label === 'Twenty_Eight_Day') return '28 Days';
    return label.replace(/_/g, ' ');
});

const ROUNDING_TYPE = mapEnum(enums.roundingType);

const CALCULATION_TYPES_FOR_SCHEDULING_ON_RENTED_UNITS = mapEnum(enums.calcTypesForSchedulingOnRentedUnits, label => label.replace('Cur', 'Current').replace('Std', 'Standard').replace('Amt', 'Amount').replace(/_/g, ' '));

const CALCULATION_TYPES_FOR_SCHEDULING_ON_VACANT_UNITS = mapEnum(enums.calcTypesForSchedulingOnVacantUnits, label => label.replace('Std', 'Standard').replace(/_/g, ' '));

const WARNING_TYPE = createEnumMap(enums.warningType);

const WARNING_RESOLUTION_TYPE = mapEnum(enums.warningResolutionType, label => label.replace('Percent', '%').replace(/_/g, ' '));

const RENT_ADJUSTMENT_ALREADY_EXISTS_WARNING_CURRENT_ROW_ID = '-1';
const RENT_ADJUSTMENT_PERIOD_LIST = mapEnum(enums.rentAdjustmentPeriod, label => capitalize(label));
const RENT_ADJUSTMENT_STATUS_LIST = mapEnum(enums.status, label => (label === 'Pending' ? localisable.scheduled : label));

export {
    RECUR_PERIOD,
    WARNING_TYPE,
    ROUNDING_TYPE,
    DATA_FETCH_TIMEOUT,
    rentalStatusRadioList,
    RENT_ADJUSTMENT_STATUS,
    WARNING_RESOLUTION_TYPE,
    BY_TENANTS_FILTERS_TYPE,
    CALCULATION_TYPES_FOR_SCHEDULING_ON_RENTED_UNITS,
    CALCULATION_TYPES_FOR_SCHEDULING_ON_VACANT_UNITS,
    RENT_ADJUSTMENT_ALREADY_EXISTS_WARNING_CURRENT_ROW_ID,
    CHARGES_AFTER_RENT_ADJUSTMENT_REQUEST_SOURCE,
    RENT_ADJUSTMENT_PERIOD_LIST,
    RENT_ADJUSTMENT_STATUS_LIST,
};
