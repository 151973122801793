import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

export default (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { data: { Letter: { data = [] } = {} } = {}, totalCount } = {} } = processedData;
    const modifiedData = {
        ...processedData,
        data: {
            totalCount,
            data,
        },
    };
    return modifiedData;
};
