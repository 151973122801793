import { labelFormatter, mapEnum } from 'Commons/helpers/utils/Formatter';
import { CONFIGURATION_TYPE, DELINQUENCY_STATUS } from 'Commons/config/constants/Constants';
import { LATE_TYPE } from 'Internal/containers/delinquencyTracking/list/config/Constants';
import enums from './Enums';

const SEARCH_TYPE = mapEnum(enums.searchType);
const GATE_ACCESS_TYPE = mapEnum(enums.gateAccessType);
const USER_MANAGEMENT_CATEGORY_TYPE = mapEnum(enums.userManagementCategory);

const FACILITY_CONTEXT_SEARCH_TYPES = [SEARCH_TYPE.Configuration_Changes.value, SEARCH_TYPE.Late_Events.value, SEARCH_TYPE.Lockout_Events.value];
const ACCOUNT_CONTEXT_SEARCH_TYPES = [SEARCH_TYPE.User_Role.value, SEARCH_TYPE.User_Management.value];


const JOURNAL_CONFIG_LIST = [
    CONFIGURATION_TYPE.Facility_Configuration.value.toLowerCase(),
    CONFIGURATION_TYPE.Financial_Configuration.value.toLowerCase(),
    CONFIGURATION_TYPE.Unit_Configuration.value.toLowerCase(),
    CONFIGURATION_TYPE.Customer_Configuration.value.toLowerCase(),
    CONFIGURATION_TYPE.Rental_Configuration.value.toLowerCase(),
    CONFIGURATION_TYPE.Customer_Engagement_Configuration.value.toLowerCase(),
];


const JOURNAL_CONFIGURATION_CHANGES_DOES_NOT_EXISTS_FILTER = {
    oldValue__value__templateContent: true,
    oldValue__value__text_friendly__templateContent: true,
};

// Default Filters
const JOURNAL_CONFIGURATION_CHANGES_DEFAULT_FILTER = {
    terms: { category: JOURNAL_CONFIG_LIST },
    notExists: JOURNAL_CONFIGURATION_CHANGES_DOES_NOT_EXISTS_FILTER,
};
const JOURNAL_LOCKOUT_EVENTS_DEFAULT_FILTER = {
    terms: {
        changeType: [
            GATE_ACCESS_TYPE.Ledger_Gate_Access.value,
            GATE_ACCESS_TYPE.Unit_Gate_Access.value,
            GATE_ACCESS_TYPE.Unit_Overlock_Status.value,
        ],
    },
};
const JOURNAL_USER_ROLES_DEFAULT_FILTER = { terms: { changeType: [SEARCH_TYPE.User_Role.value] } };
const JOURNAL_USER_MANAGEMENT_DEFAULT_FILTER = { terms: { changeType: [SEARCH_TYPE.User_Management.value] } };

const ALLOWED_LATE_EVENT_STATUS = [
    DELINQUENCY_STATUS.Waived.value,
];

const ALLOWED_LATE_EVENT_TYPE = [
    LATE_TYPE.One_Time.value,
    LATE_TYPE.Recurring.value,
    LATE_TYPE.Dollar_Per_Day.value,
];

const DELINQUENCY_TRACKING_LATE_EVENTS_DEFAULT_FILTER = {
    terms: {
        status: ALLOWED_LATE_EVENT_STATUS,
        type: ALLOWED_LATE_EVENT_TYPE,
    },
};

// stores
const JOURNAL_CONFIGURATION_CHANGES_STORE = 'journalConfigurationChanges';
const JOURNAL_LATE_EVENT_STORE = 'journalLateEvent';
const JOURNAL_LOCKOUT_EVENT_STORE = 'journalLockoutEvent';
const JOURNAL_USER_ROLE_STORE = 'journalUserRole';
const JOURNAL_USER_MANAGEMENT_STORE = 'journalUserManagement';

const getJournalStoreKey = (journalType) => {
    let storeKey;
    switch (journalType) {
        case (SEARCH_TYPE.Late_Events.value):
            storeKey = JOURNAL_LATE_EVENT_STORE;
            break;
        case (SEARCH_TYPE.Lockout_Events.value):
            storeKey = JOURNAL_LOCKOUT_EVENT_STORE;
            break;
        case (SEARCH_TYPE.User_Role.value):
            storeKey = JOURNAL_USER_ROLE_STORE;
            break;
        case (SEARCH_TYPE.User_Management.value):
            storeKey = JOURNAL_USER_MANAGEMENT_STORE;
            break;
        default:
            storeKey = JOURNAL_CONFIGURATION_CHANGES_STORE;
            break;
    }
    return storeKey;
};


const getHeaderText = (journalSearchType = '', facilityName) => {
    let headerText = labelFormatter(journalSearchType);
    if (facilityName) headerText = headerText.concat(` - ${facilityName}`);
    return headerText;
};

const ADDITIONAL_FILTERS_TO_INCLUDE_WITH_FACILITY_CONFIG = ['facility', CONFIGURATION_TYPE.Retail_Sale_Configuration.value.toLowerCase()];

const TABLE_WIDTH = 1020;
const TABLE_LEFT_AND_RIGHT_PADDING = 0;
const JOURNAL_LIST_ROW_HEIGHT = 100;
const TABLE_WIDTH_OFFSET = 16;
const TABLE_HEIGHT_OFFSET = 196;

export {
    SEARCH_TYPE, getJournalStoreKey, getHeaderText,
    JOURNAL_CONFIGURATION_CHANGES_STORE, JOURNAL_LATE_EVENT_STORE, JOURNAL_LOCKOUT_EVENT_STORE,
    JOURNAL_USER_ROLE_STORE, JOURNAL_USER_MANAGEMENT_STORE, TABLE_WIDTH_OFFSET, TABLE_HEIGHT_OFFSET,
    JOURNAL_CONFIGURATION_CHANGES_DEFAULT_FILTER, JOURNAL_LOCKOUT_EVENTS_DEFAULT_FILTER,
    TABLE_WIDTH, TABLE_LEFT_AND_RIGHT_PADDING, JOURNAL_USER_ROLES_DEFAULT_FILTER, JOURNAL_USER_MANAGEMENT_DEFAULT_FILTER,
    FACILITY_CONTEXT_SEARCH_TYPES, ACCOUNT_CONTEXT_SEARCH_TYPES, USER_MANAGEMENT_CATEGORY_TYPE, JOURNAL_LIST_ROW_HEIGHT,
    JOURNAL_CONFIGURATION_CHANGES_DOES_NOT_EXISTS_FILTER, ADDITIONAL_FILTERS_TO_INCLUDE_WITH_FACILITY_CONFIG,
    DELINQUENCY_TRACKING_LATE_EVENTS_DEFAULT_FILTER,
};
