import { setValue, getValue, deCapitalize } from 'Commons/helpers/utils/DataHelpers';
import { curQueue } from 'Commons/helpers/api/Queue';
import STATUS from 'Commons/config/constants/StoreKeyStatus';
import dependentConfig from '../config/ConfigurationDependencies';
import { handleConfigInheritance } from './configSDPostProcessor';

const pushIfDoesNotExist = (array, item) => {
    if (!array.includes(item)) {
        array.push(item);
    }
};

const getResolvedData = async (currentData, store, keysToResolveInfo = [], api, {
    body: {
        filter: [{
            terms: {
                entityType: [entityType] = [],
                entityId: [entityId] = [],
            } = {},
        } = {}] = [],
    } = {},
    headers,
}) => {
    const idsToFetch = [];
    const indicesToProcessWhileMerging = [];
    let processedData = [];

    const configTypes = keysToResolveInfo.reduce((updatedConfigTypes, { configType, path }) => {
        processedData = currentData.reduce((updatedData, dataItem, dataIndex) => {
            const { value = {}, childConfiguration = [] } = dataItem;
            const { value: childValue = {} } = childConfiguration.find(({ entityType: selectedEntityType }) => selectedEntityType === entityType) || {};

            const { [deCapitalize(configType.replace('_', ''))]: { dataObject = {} } = {} } = store || {};
            let nestedData = getValue(value, path);
            let childNestedData = getValue(childValue, path);
            if (typeof nestedData === 'object' && !Object.keys(nestedData).length) {
                nestedData = [];
            }
            if (typeof childNestedData === 'object' && !Object.keys(childNestedData).length) {
                childNestedData = [];
            }
            if (nestedData) {
                pushIfDoesNotExist(indicesToProcessWhileMerging, dataIndex);
                nestedData = !Array.isArray(nestedData) ? [nestedData] : nestedData;
                nestedData.forEach((nestedDataId) => {
                    if (!dataObject[dataItem]) {
                        idsToFetch.push(nestedDataId);
                    }
                });
            }
            if (childNestedData) {
                pushIfDoesNotExist(indicesToProcessWhileMerging, dataIndex);
                childNestedData = !Array.isArray(childNestedData) ? [childNestedData] : childNestedData;
                childNestedData.forEach((nestedDataId) => {
                    if (!dataObject[dataItem]) {
                        idsToFetch.push(nestedDataId.toString());
                    }
                });
            }
            setValue(value, path, nestedData || []);
            updatedData.push({ ...dataItem, value });
            return updatedData; // Avoid directly return of updatedData.push, it will return promise
        }, []);
        updatedConfigTypes.push(configType);
        return updatedConfigTypes;
    }, []);

    let fetchedData = [];
    if (idsToFetch.length) {
        fetchedData = await api({
            api: {
                methodType: 'POST',
                endPoint: 'configuration/search',
                body: {
                    view: 'detail',
                    filter: [{
                        terms: {
                            configType: [...new Set(configTypes)],
                            entityType: [entityType],
                            ...(entityId && { entityId: [entityId] }),
                            id: [...new Set(idsToFetch)],
                        },
                    }],
                },
                headers,
            },
        });
    }
    return { processedData, fetchedData, indicesToProcessWhileMerging };
};

const postProcessResolvedData = (data, store, keysToResolveInfo, entityType) => {
    const {
        indicesToProcessWhileMerging, processedData,
        fetchedData: { error, response, timestamp },
    } = data || {};
    curQueue.remove(timestamp);
    if (error) {
        return {
            data: null,
            status: STATUS.ERROR,
        };
    }
    const finalData = [...processedData];
    if (response) {
        keysToResolveInfo.forEach(({ path, configType }) => {
            const { data: { [configType]: { data: fetchedData = [] } = {} } = {} } = response;
            const {
                [deCapitalize(configType.replace('_', ''))]: { data: { data: dataFromStore = [] } = {} } = {},
                dataObject = {},
            } = store || {};

            indicesToProcessWhileMerging.forEach((currentDataIndex) => {
                const dataToProcessAndMerge = getValue(processedData[currentDataIndex].value, path);
                const mergedNestedData = dataToProcessAndMerge.reduce((mergedData, id) => {
                    const resolvedData = dataFromStore[dataObject[id] || -1]
                        || fetchedData.find(({ id: dataId }) => String(dataId) === String(id));
                    mergedData.push(resolvedData);
                    return mergedData;
                }, []);
                setValue(finalData[currentDataIndex].value, path, mergedNestedData);
                if (processedData[currentDataIndex].childConfiguration) {
                    const selectedOverriddenProcessedConfig = processedData[currentDataIndex].childConfiguration.find(({ entityType: selectedEntityType }) => entityType === selectedEntityType);
                    const dataToProcessAndMergeForChild = getValue(selectedOverriddenProcessedConfig.value, path);
                    const mergedNestedDataForChild = dataToProcessAndMergeForChild.reduce((mergedData, id) => {
                        const stringId = id.toString();
                        const resolvedData = dataFromStore[dataObject[stringId] || -1]
                            || fetchedData.find(({ id: dataId }) => dataId === stringId);
                        mergedData.push(resolvedData);
                        return mergedData;
                    }, []);
                    const selectedOverriddenFinalConfig = finalData[currentDataIndex].childConfiguration.find(({ entityType: selectedEntityType }) => entityType === selectedEntityType);
                    setValue(selectedOverriddenFinalConfig.value, path, mergedNestedDataForChild);
                }
            });
        });
    }
    return finalData;
};

const fetchExtraData = async (dataToProcess, store, api, keysToResolveInfo, requestOptions) => {
    const { body: { filter: [{ terms: { entityType: [entityType] = [] } = {} } = {}] = [] } = {} } = requestOptions;
    const data = await getResolvedData(dataToProcess, store, keysToResolveInfo, api, requestOptions);
    const { fetchedData: { response = {}, ...restFetchedData }, ...restData } = data || {};
    const { data: { data: { success, totalCount, ...cleanData } = {} } = {} } = handleConfigInheritance(response);
    const updatedData = { fetchedData: { response: { ...response, data: cleanData }, ...restFetchedData }, ...restData };
    return postProcessResolvedData(updatedData, store, keysToResolveInfo, entityType);
};

const configSubPostProcessor = async (currentData = {}, store = {}, api = () => { },
    requestOptions = {}) => Object.keys(currentData).reduce(async (updatedData, key) => {
    const resolvedUpdatedData = await updatedData;
    const {
        [key]: otherData = {}, [key]: {
            data = {},
            data: { data: dataToProcess = [], totalCount = 0 } = {},
        } = {},
    } = currentData;
    const keysToResolveInfo = dependentConfig[key];
    if (keysToResolveInfo) {
        const finalData = await fetchExtraData(dataToProcess, store, api, keysToResolveInfo, requestOptions);
        const filteredFinalData = finalData.filter(({ value: { description } = {} }) => description);
        return {
            ...resolvedUpdatedData,
            [key]: {
                ...otherData,
                data: {
                    ...data,
                    totalCount: totalCount - (dataToProcess.length - filteredFinalData.length),
                    data: filteredFinalData,
                },
            },
        };
    }
    return currentData;
}, currentData, Promise.resolve([]));

export default configSubPostProcessor;
