import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import getName from 'Commons/helpers/utils/NameHelper';
import { WALK_IN_CUSTOMER_NAME, WALK_IN_CUSTOMER_ID } from 'External/containers/pointOfSale/config/Constants';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions) || {};
    const { data = {} } = processedData;
    if (data) {
        const { data: failedReturns = [], relationalData: { tenant = [], user = [] } = {}, ...restData } = data;
        const tenantAndLedgerData = {};
        const userData = {};
        tenant.forEach((tenantData) => {
            const { ledger, name } = tenantData;
            ledger.forEach((eachLedger) => {
                const { id, label } = eachLedger;
                tenantAndLedgerData[id] = {
                    tenantName: getName(name),
                    ledgerName: label,
                    tenant: tenantData,
                    ledger: eachLedger,
                };
            });
        });
        user.forEach(({ id, firstName, lastName }) => {
            userData[id] = getName({ firstName, lastName });
        });
        const failedReturnsData = failedReturns.map((failedReturnsItem) => {
            const { customerId: ledgerId, createdBy: { id }, ...restFailedReturnsItem } = failedReturnsItem;
            return {
                ...restFailedReturnsItem,
                tenantName: WALK_IN_CUSTOMER_NAME,
                ledger: { id: WALK_IN_CUSTOMER_ID },
                tenant: { id: WALK_IN_CUSTOMER_ID, name: { lastName: WALK_IN_CUSTOMER_NAME } },
                ...tenantAndLedgerData[ledgerId],
                user: userData[id],
                customerId: ledgerId || WALK_IN_CUSTOMER_ID,
            };
        });
        processedData = {
            ...processedData,
            data: { data: failedReturnsData, ...restData },
        };
    }
    return processedData;
};
