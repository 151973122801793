import { createEnumMap } from 'Commons/helpers/utils/Formatter';

const CONFIG_TYPE = createEnumMap([
    'Charge_Category', 'Tax_Code', 'Tax_Exempt_Category', 'Payment_Method', 'Unit_Type', 'Financial_Payment',
    'Promo_Plan', 'Promo_Plan_Template',
    'Facility_General', 'Customer_General', 'Financial_General', 'Rental_General',
    'Charge_Category_Mapping', 'Move_In', 'Move_Out', 'Reservation', 'Gate',
    'Tenant_Change_Tracking', 'Tenant_Account_Type', 'Tenant_Sections', 'Letter', 'Required_Fields',
    'Suggested_Fields', 'Inventory', 'Late_Event', 'Bev_Colors', 'Maintenance_Event', 'Tenant_Websites', 'Level',
    'Card_Processor', 'Lease_General', 'Lease_Style', 'Invoicing', 'Ach', 'Transfer', 'Insurance', 'Insurance_Coverage',
    'Insurance_Provider', 'Custom_Merge_Fields', 'Quickbooks', 'Vehicle', 'Walk_Around', 'Placeholder',
    'Financial_Export', 'Violation',
]);

export default CONFIG_TYPE;
