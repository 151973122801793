import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapRelationalData } from '../../../commons/redux/helper/RelationalDataMap';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const keys = [{
        dataKey: 'unitTypeId',
        relationalDataKey: 'unitType',
    }];
    processedData = mapRelationalData(processedData, keys);
    return processedData;
};
