import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapRelationalData } from 'Commons/redux/helper/RelationalDataMap';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);

    const {
        data: {
            data: processedItems = [],
            relationalData: {
                tenant = [],
                tenant: isTenantRelationalData,
                unit: isUnitRelationalData,
                unit: unitRelationalData = [],
            } = {},
        } = {},
    } = processedData;
    const ledgerMap = tenant.reduce((accumulator, { ledger = [], ...eachTenant }) => {
        ledger.forEach(({ id, ...eachLedger }) => {
            accumulator[id] = { ...eachLedger, id, tenant: eachTenant };
        });
        return accumulator;
    }, {});

    if (processedItems.length) {
        const modifiedData = processedItems.map((obj) => {
            const modifiedObj = obj;
            const { metaInfo: { requestEndpoint = '', units = [], tenantId, unitId, ledgerId } = {}, changeType, categoryId } = obj;
            if (tenantId || unitId || ledgerId || units.length) {
                ['tenantId', 'unitId', 'ledgerId', 'units'].forEach((key) => {
                    if (key === 'units') {
                        const unitDetails = units.map((eachUnitId) => {
                            // eslint-disable-next-line radix
                            const [{ label: unitLabel = 'Default Label' } = {}] = unitRelationalData.filter(({ id }) => id === parseInt(eachUnitId)) || [{}];
                            return { id: eachUnitId, label: unitLabel };
                        });
                        modifiedObj[key] = unitDetails;
                    } else if (obj.metaInfo[key]) modifiedObj[key] = obj.metaInfo[key];
                });
            } else {
                const requestEndpointParts = requestEndpoint.split('/');
                const entityId = requestEndpointParts[requestEndpointParts.length - 1]; // tenant/unit id
                let entityType;
                if (changeType === 'Ledger_Gate_Access') {
                    entityType = 'tenantId';
                    modifiedObj.ledgerId = categoryId;
                } else if (changeType === 'Unit_Gate_Access') {
                    entityType = 'unitId';
                }
                if (entityType) modifiedObj[entityType] = entityId;
            }
            return modifiedObj;
        });
        processedData.data.data = modifiedData;
    }
    processedData.data.relationalData.ledger = ledgerMap;
    const keys = [
        { dataKey: 'createdBy.id', relationalDataKey: 'user' },
        { dataKey: 'ledgerId', relationalDataKey: 'ledger' },
        ...(isTenantRelationalData ? [{ dataKey: 'tenantId', relationalDataKey: 'tenant' }] : []),
        ...(isUnitRelationalData ? [{ dataKey: 'unitId', relationalDataKey: 'unit' }] : []),
    ];
    processedData = mapRelationalData(processedData, keys);
    return processedData;
};
