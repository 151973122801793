import getName from 'Commons/helpers/utils/NameHelper';

const getCustomerList = (tenants, leads, conversations, conversationKey) => {
    const tenantObject = {};
    const leadObject = {};
    tenants.forEach(({ id, name }) => {
        tenantObject[id] = getName(name);
    });
    leads.forEach(({ id, name }) => {
        leadObject[id] = getName(name);
    });
    const customerWithConversations = [];
    Object.keys(conversations).forEach((conversationType) => {
        const conversationEntry = conversations[conversationType];
        const { customerType, customerId } = conversationEntry.additionalData[0];
        conversationEntry.additionalData[0].displayName = (customerType === 'Tenant' ? tenantObject[customerId] : leadObject[customerId]) || '';
        customerWithConversations.push({ [conversationKey]: conversationType, ...conversationEntry });
    });
    return customerWithConversations;
};

export default getCustomerList;
