/* eslint-disable import/prefer-default-export */
import handleError, { handleStatusError } from 'Commons/redux/error/ErrorHandler';
import STATUS from 'Commons/config/constants/StoreKeyStatus';
import { curQueue } from 'Commons/helpers/api/Queue';
import { toObject, pascalCaseWithUnderscore, camelCase } from 'Commons/helpers/utils/DataHelpers';
import { getPostProcessor } from '../../../commons/redux/postProcessor/allProcessors';

const processPayload = (keys, response) => keys.reduce((payload, key) => {
    const transformedKey = pascalCaseWithUnderscore(key);
    const data = (response && response[transformedKey] && response[transformedKey].data) || [];
    const singleConfigData = {
        data: {
            data,
            totalCount: data ? data.length : 0,
        },
        dataObject: toObject(data),
        status: STATUS.LOADED,
    };
    return {
        ...payload,
        [key]: singleConfigData,
    };
}, {});

const processValidResponse = (apiResult, keys, storeData, api) => {
    const { data: response = {}, totalCount = 0 } = apiResult;
    if (keys.length && totalCount) {
        return processPayload(keys, response, storeData, api);
    }

    if (totalCount) {
        const responseKeys = Object.keys(response).map(key => camelCase(key));
        const payload = processPayload(responseKeys, response, storeData, api);
        return {
            data: {
                data: payload,
                totalCount,
            },
            status: STATUS.LOADED,
        };
    }
    return response;
};

const processOtherResponses = ({ data, status }, keys) => keys.reduce((payload, key) => ({
    ...payload,
    [key]: {
        data,
        status,
    },
}), {});

// eslint-disable-next-line consistent-return
const configPostProcessor = async (apiResult, callback, dispatch, localReqOptions) => {
    const { keys, storeData, api, subProcessor, requestOptions } = localReqOptions;
    const { error: err, response, timestamp } = apiResult;
    const isQueued = response && response.status === STATUS.QUEUED;

    if (!isQueued) {
        // Removing request via timestamp
        curQueue.remove(timestamp, storeData);
    }

    if (err) {
        console.log('Error:', err);
        handleError(err, callback);
        const error = handleStatusError(err, dispatch, localReqOptions);
        return processOtherResponses(error, keys);
    }

    // No Error, Successful response
    if (isQueued) {
        return processOtherResponses({
            data: null,
            status: STATUS.LOADING,
        }, keys);
    }
    let configData = processValidResponse(response, keys, storeData, api);
    if (subProcessor) {
        const postProcessor = getPostProcessor(`${subProcessor}SubPostProcessor`);
        configData = await postProcessor(configData,
            storeData, api, requestOptions);
    }
    if (!isQueued && callback) {
        callback(err, configData);
    }
    return configData;
};
export { configPostProcessor };
