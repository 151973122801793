import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { flattenObject } from 'Commons/helpers/utils/DataHelpers';
import { SETUP_STATUS } from 'Commons/config/constants/Constants';

export default (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { relationalData, ...data } = {}, data: { data: facilityData = [] } = {} } = processedData;
    const {
        user = [], permissions: {
            permissions: [{ permission: { facility: facilityToRoleMapping = {} } = {} } = {}] = [],
            roles = [],
        } = {},
        missingConfigs = {},
        accountNumber = '',
        facilityGeneral = {},
    } = relationalData || {};

    // converting roles to key, value pair where key is the id of role
    const processedRoles = roles.reduce((acc, { id: profileId, permission }) => ({
        ...acc,
        [profileId]: permission ? flattenObject(permission) : {},
    }), {});

    let updatedFacilityData = facilityData;
    // adding profile id to the facility, to resolve it on facility select
    updatedFacilityData = facilityData.map(({ id, ...facility }) => {
        const { [id]: { roleId } = {} } = facilityToRoleMapping;
        return {
            ...facility,
            id,
            permissionRoleId: roleId,
            setupStatus: missingConfigs[id].length ? SETUP_STATUS.INCOMPLETE : SETUP_STATUS.COMPLETE,
            ...facilityGeneral[id] || {},
        };
    });

    return {
        facility: {
            ...processedData,
            data: {
                ...data,
                relationalData,
                data: updatedFacilityData,
            },
        },
        userProfile: {
            ...processedData,
            data: {
                ...data,
                data: user,
                totalCount: user.length,
                accountNumber,
            },
        },
        permissionRoles: {
            ...processedData,
            data: {
                ...data,
                data: processedRoles,
                totalCount: roles.length,
            },
        },
    };
};
