import { CURRENCY_MAP, STATUS } from 'Commons/config/constants/Constants';
import { RENTAL_STATUS } from 'External/containers/unit/list/config/Constants';


const getLabelWithSymbol = (label, currency, otherSymbol) => `${label.toUpperCase()} (${currency ? (CURRENCY_MAP[currency] || '') : otherSymbol})`;

const viewRentAdjustmentDataFormatter = (response) => {
    const processedData = response;
    const { data = [], relationalData: { tenant = [], unit = [], unitType = [] } = {} } = processedData;
    const unitTypes = unitType.reduce((a, v) => ({ ...a, [v.id]: v }), {});
    const units = unit.reduce((a, v) => ({ ...a, [v.id]: v }), {});
    const tenants = {};
    tenant.forEach((el) => {
        const { ledger = [] } = el;
        ledger.forEach((singleLedger) => { tenants[singleLedger.id] = { ...el, ledger: [singleLedger] }; });
    });
    // filtering out rented units data
    const newData = [];
    data.forEach((el) => {
        const { unit: unitId = '' } = el;
        const { [unitId]: { status = '', rentalStatus = '', ledger: { id = '' } = {}, unitType: { id: unitTypeId = '' } = {}, label = '', period = '', currentRate = '', recurringCharges = [] } = {} } = units;
        if (rentalStatus === RENTAL_STATUS.Rented.value && status !== STATUS.Inactive.value) {
            const tenantTemp = tenants[id];
            newData.push({ ...el, ledger: { id }, tenant: tenantTemp, unitType: unitTypes[unitTypeId], label, period, currentRate, recurringCharges });
        }
    });
    processedData.data = newData;
    return processedData;
};

export { getLabelWithSymbol, viewRentAdjustmentDataFormatter };
