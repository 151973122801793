import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { data: reservationList = [], relationalData = {}, ...restData } = {} } = processedData;
    const reservationData = reservationList.map((reservation) => {
        const { unitTypeId = '', unitId = '', tenant: { id: tenantId = '' } = {} } = reservation;
        const unitType = relationalData.unitType.find(({ id }) => id === unitTypeId) || {};
        const unit = relationalData.unit.find(({ id }) => id === unitId) || {};
        const tenant = relationalData.tenant.find(({ id }) => id === tenantId) || {};
        return { ...reservation, unitType, unit, tenant };
    });

    if (processedData) {
        processedData = {
            ...processedData,
            data: { data: reservationData, ...restData },
        };
    }
    return processedData;
};
