/* eslint-disable consistent-return */
import STATUS from '../../../commons/config/constants/StoreKeyStatus';

const CustomHeaders = {
    ACCOUNT: 'Account',
    BUSINESS_GROUP: 'Business_Group',
    FACILITY: 'Facility',
};

const StoreCustomHeaders = {
    facility: {
        type: CustomHeaders.FACILITY,
        id: ({ currentFacility }) => {
            if (currentFacility && currentFacility.status > STATUS.LOADING) {
                const { data: { id: facilityId } } = currentFacility;
                return facilityId;
            }
        },
    },
    account: {
        type: CustomHeaders.ACCOUNT,
        id: ({ currentAccountId }) => {
            if (currentAccountId && currentAccountId.status > STATUS.LOADING) {
                const { data: { id: accountId } } = currentAccountId;
                return accountId;
            }
        },
    },
};

export {
    CustomHeaders,
    StoreCustomHeaders,
};
