import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { dateFormatter } from 'Commons/helpers/utils/DateTime';
import getName from 'Commons/helpers/utils/NameHelper';
import { convertToNumber } from 'Commons/helpers/utils/Utils';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions) || {};
    const { data = {} } = processedData;
    if (data) {
        const { data: transactions = [], relationalData: { ledger: ledgers = [] } = {} } = data;
        const ledgerObject = {};
        ledgers.forEach(({ id, label, tenantName }) => {
            ledgerObject[id] = { label, tenantName: getName(tenantName) };
        });
        const finalData = [];
        transactions.forEach(({ customerId, effectiveDate, value: { amount, ...restValues }, ...rest }) => {
            finalData.push({
                customerId,
                ...ledgerObject[customerId],
                ...rest,
                ...restValues,
                effectiveDate: dateFormatter(effectiveDate),
                ...(amount > 0
                    ? { paymentAmount: convertToNumber(amount).toFixed(2) }
                    : { refundAmount: convertToNumber(-amount).toFixed(2) }),
            });
        });
        processedData = {
            ...processedData,
            data: { data: finalData },
        };
    }
    return processedData;
};
