import { createEnumMap } from 'Commons/helpers/utils/Formatter';

const formatter = label => label.replace(/([A-Z])/g, match => `_${match}`).toUpperCase();
const REPORT_PERMISSION_KEYS = createEnumMap(
    ['accountConsolidatedReport', 'businessGroupConsolidatedReport', 'facilityConsolidatedReport', 'facilityIncomeReport',
        'facilityCustomCategories'],
    formatter,
);

export { REPORT_PERMISSION_KEYS, formatter };
