import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapRelationalData } from '../../../commons/redux/helper/RelationalDataMap';

export default (apiResult, callback, dispatch, localReqOptions) => {
    const { isDataReversed } = localReqOptions;
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { data: arData = [], relationalData = {}, ...restData } = {} } = processedData;
    if (isDataReversed) {
        processedData = { data: { data: arData.reverse(), relationalData, ...restData } };
    }
    const keys = [{ dataKey: 'chargeCategoryId', relationalDataKey: 'chargeCategory' }];
    processedData = mapRelationalData(processedData, keys);
    return processedData;
};
