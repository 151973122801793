const enums = {
    websiteAccess: ['Restricted_Login', 'View_Only', 'Full'],
    visibleAt: ['All', 'Move_in', 'Payment', 'View_Tenant'],
    contactLogCustomerType: ['Tenant', 'Lead'],
    contactLogType: ['Auto', 'Manual'],
    contactLogAutoMode: ['Text', 'Email', 'Print'],
    contactLogSource: ['Lead', 'Tenant'],
    invoiceType: ['All_Charges_Due', 'Pre-Billing'],
    invoiceStyle: ['Detailed_Transactions', 'Summarized_Transactions'],
    invoicingStatus: ['Enabled', 'Disabled', 'Disable_After_Current_Period'],
    recipientType: ['User', 'Tenant', 'Lead', 'Unknown'],
    viewTenantAlerts: ['discard', 'unit', 'invoice'],
    arAggregation: ['Tenant_Deposit_To_Be_Paid'],
    documentationStatus: ['Active', 'Inactive', 'Generate_Error', 'Upload_Error', 'Delivery_Error', 'Pending'],
    autoInvoiceDeliveryType: ['Automatic', 'Manual'],
    alertConfigurationModules: ['Move_In', 'View_Tenant', 'Payment'],
};

export default enums;
