import STATUS from 'Commons/config/constants/StoreKeyStatus';
import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapRelationalData } from '../../../commons/redux/helper/RelationalDataMap';


const manualLateEventPostProcessor = (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);

    const keys = [
        { dataKey: 'lateEventConfigId', relationalDataKey: 'lateEvent' },
        { dataKey: 'unitId', relationalDataKey: 'unit' },
    ];
    const processedResponse = mapRelationalData(processedData, keys);

    // Processing Tenant Explicitly because the way ledger and tenant is associated
    const data = [];
    const { data: allProcessedData, data: { data: processedLateEvents = [] } = {} } = processedResponse || {};
    const { data: responseData } = processedData;
    const { relationalData: { tenant: tenants = [] } = {} } = responseData || {};

    if (tenants && Array.isArray(tenants) && tenants.length) {
        const ledgerTenantObj = {};
        tenants.forEach((tenant) => {
            const { ledger = [] } = tenant;
            ledger.forEach(({ id }) => {
                ledgerTenantObj[id] = tenant;
            });
        });

        processedLateEvents.forEach((lateEvent) => {
            const { ledgerId } = lateEvent || {};
            const tenant = ledgerTenantObj[ledgerId];
            data.push({ ...lateEvent, tenant });
        });
    }

    return {
        data: {
            ...allProcessedData,
            data,
        },
        status: STATUS.LOADED,
    };
};

export { manualLateEventPostProcessor };
