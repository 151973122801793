import { createEnumMap } from 'Commons/helpers/utils/Formatter';

const formatter = label => label.replace(/([A-Z])/g, match => `_${match}`).toUpperCase();
const CONFIGURATION_PERMISSION_KEYS = createEnumMap(
    [
        'facilityDeactivateConfig',
        'facilityAccess',
        'facilityAccessFinancials',
        'facilityAccessFinancialGrouping',
        'facilityAccessOccupancy',
        'facilityAccessDelinquency',
        'facilityAccessLetter',
        'facilityAccessUnitType',
        'facilityAccessTfw',
        'facilityAccessCustomer',
        'businessGroupDeactivateConfig',
        'businessGroupAccess',
        'businessGroupAccessFinancials',
        'businessGroupAccessFinancialGrouping',
        'businessGroupAccessOccupancy',
        'businessGroupAccessDelinquency',
        'businessGroupAccessLetter',
        'businessGroupAccessUnitType',
        'businessGroupAccessTfw',
        'businessGroupAccessCustomer',
    ],
    formatter,
);

export { CONFIGURATION_PERMISSION_KEYS };
