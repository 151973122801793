import { mapEnum, createEnumMap, labelFormatter } from 'Commons/helpers/utils/Formatter';
import localisable from 'Commons/config/strings/localisable';

const enums = {
    billingTypes: ['Prorate_First', 'Prorate_Second', 'Prorate_First_Bill_Second',
        'Prorate_First_Bill_Second_After_Days'],
    actions: ['Prorate_Out', 'Do_Nothing'],
    actionsOnDueBalance: ['Apply_Payment', 'Hold_Balance_On_Ledger', 'Write_Off'],
    actionsOnDueCredit: ['Hold_Refund_On_Ledger', 'Assess_To_Tenant_Refund', 'Apply_Cash_Refund',
        'Apply_To_Bank_Via_Ach', 'Apply_To_Credit_Card'],
    depositCalculation: ['Flat', 'Unit_Deposit', 'Unit_Rate', 'Percent_Of_Unit_Rate'],
    depositTypes: ['Refundable', 'Non_Refundable'],
    features: ['Move_In', 'Ledger_Review', 'Estimates'],
    moveOutDocuments: ['Move_Out_Document', 'Ledger_History', 'Contact_Log', 'Thank_You_Letter'],
    moveInDocuments: ['Welcome_Letter', 'Lease_Agreement', 'Demographics_Letter'],
    transferDocuments: ['Lease_Agreement', 'Unit_Transfer'],
    // Add lease letter when completed
    reservationDocuments: ['Reservation_Quote', 'Cancellation_Letter'],
    reservationConfigFields: ['deposit', 'cancellation'],
    fieldAction: ['add', 'remove', 'replace'],
    roundingType: ['Nearest_Cent', 'Round_To_Nearest_Dollar', 'Round_Up_To_Nearest_Dollar'],
};

const PRORATE_CALC_DAYS = [
    {
        label: 'Actual days in a period',
        value: 'Actual_Days',
    },
    {
        label: '30 days in a month',
        value: 'Thirty_Days',
    },
];

const PERIOD_LIST = [
    {
        label: 'Daily',
        value: 'Day',
    },
    {
        label: 'Weekly',
        value: 'Week',
    },
    {
        label: 'Monthly',
        value: 'Month',
    },
    {
        label: '28-days',
        value: 'Twenty_Eight_Day',
    },
    {
        label: 'Seasonal',
        value: 'Seasonal',
    },
    {
        label: 'Quarterly',
        value: 'Quarter',
    },
    {
        label: 'Bi-Annual',
        value: 'Bi_Annual',
    },
    {
        label: 'Annual',
        value: 'Annual',
    },
];
const RESERVATION_LOCKED_RATE_FIELDS = ['Unit Rate', 'Tenant Deposit', 'Unit Deposit', 'Setup Fee'];

const BILLING_TYPE = mapEnum(enums.billingTypes);
const ACTIONS = mapEnum(enums.actions);
const BALANCE_DUE = mapEnum(enums.actionsOnDueBalance);
const CREDIT_DUE = mapEnum(enums.actionsOnDueCredit);
const DEPOSIT_CALC = mapEnum(enums.depositCalculation);
const DEPOSIT_TYPE = mapEnum(enums.depositTypes);
const FEATURES = mapEnum(enums.features);
const MOVE_OUT_DOCUMENTS = mapEnum(enums.moveOutDocuments);
const MOVE_IN_DOCUMENTS = mapEnum(enums.moveInDocuments);
const RESERVATION_DOCUMENTS = mapEnum(enums.reservationDocuments);
const RESERVATION_CONFIG_FIELDS = createEnumMap(enums.reservationConfigFields);
const FIELD_ACTIONS = createEnumMap(enums.fieldAction);
const ROUNDING_TYPE = mapEnum(enums.roundingType);

const MAX_DAYS_BEFORE_MOVE_IN = 7;

const DEFAULT_CONFIG_VALUES = {
    general: { maxDaysBeforeMoveIn: MAX_DAYS_BEFORE_MOVE_IN },
    deposit: { type: DEPOSIT_TYPE.Refundable.value, calculationType: DEPOSIT_CALC.Flat.value },
    cancellation: { type: DEPOSIT_TYPE.Non_Refundable.value },
};

// Transfer
const TRANSFER_INIT_VALUES = {
    isChargeable: true,
    fee: '0.00',
};
const TRANSFER_DOCS = createEnumMap(enums.transferDocuments);
const TRANSFER_DOCUMENTS = [
    {
        label: labelFormatter(TRANSFER_DOCS.LEASE_AGREEMENT),
        value: TRANSFER_DOCS.LEASE_AGREEMENT,
    },
    {
        label: localisable.transferDocument,
        value: TRANSFER_DOCS.UNIT_TRANSFER,
        isLetter: false,
    },
];

export {
    PRORATE_CALC_DAYS, BILLING_TYPE, ACTIONS, BALANCE_DUE, CREDIT_DUE, DEPOSIT_CALC, DEPOSIT_TYPE, FEATURES,
    MOVE_OUT_DOCUMENTS, MOVE_IN_DOCUMENTS, RESERVATION_CONFIG_FIELDS, RESERVATION_DOCUMENTS, ROUNDING_TYPE,
    PERIOD_LIST, FIELD_ACTIONS, RESERVATION_LOCKED_RATE_FIELDS, DEFAULT_CONFIG_VALUES, TRANSFER_INIT_VALUES,
    TRANSFER_DOCUMENTS, TRANSFER_DOCS,
};
