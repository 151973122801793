import { USER_MANAGEMENT_PERMISSION_KEY } from 'Commons/components/business/user/config/Constants';
import { INVENTORY_PERMISSION_KEYS } from 'External/containers/inventory/list/config/InventoryPermissionKeys';
import { RESERVATION_PERMISSION_KEYS } from 'External/containers/createoccupancy/config/PermissionKeys';
import { ROUTE } from 'External/redux/config/RouteNames';
import { RENT_ADJUSTMENT_KEYS } from 'External/containers/operations/components/rentAdjustment/config/PermissionKey';
import { CONFIGURATION_PERMISSION_KEYS } from 'External/containers/configuration/config/PermissionKeys';

/**
 * Structure of Dependency Config
 * Dependency Config contains all component names at the top level.
 * If it is not a component name it is considered as a route.
 * To specify config by Component Name:
 * DependencyConfig['componentName'] = {
 *  critical: {
 *      'storeKey1': { //Each key can specify the list of dependencies
 *          dependency: ['storeKey2', 'storeKey3']
 *      },
 *  }, // These are the critical keys that the component requires to even load.
 *  lazy: {
 *      'storeKey4': {
 *          dependency: ['storeKey5', 'storeKey6']
 *      }
 *  }, // These are the lazy store keys that needs to be loaded after the component is loaded
 *  onDemand: {
 *
 *  }, // These are the storeKeys that the component can have access to when needed, but will always listen.
 *  onMount: {
 *
 *  }, // The store keys to be fetched after the hoc has been mounted.
 * }
 * To specify config by Route:
 * Route: '/a/facilities/45/units'
 * DependencyConfig['a']['facilities/:id']['units]
 * While resolving the route directly,
 * We traverse the config from the top most level.
 * At Each level the critical keys are resolved along with its dependencies
 */

const tenantFormCriticalKeys = {
    requiredFields: {},
    suggestedFields: {},
    taxExemptCategory: {},
    tenantSections: {},
    paymentMethod: {},
    gate: {},
    cardProcessor: {},
    financialPayment: {},
    leaseStyle: {},
};

const tenantFormOnMountKeys = {
    requiredFields: {},
    suggestedFields: {},
    taxExemptCategory: {},
    tenantSections: {},
    paymentMethod: { funcName: 'resolveActivePaymentMethod' },
    gate: {},
    cardProcessor: {},
    financialPayment: {},
    leaseStyle: {},
};

const tenantFormConfig = {
    critical: {
        ...tenantFormCriticalKeys,
        ledgerAccessCode: {},
    },
    onDemand: { tenant: {}, facility: {} },
    onMount: {
        ...tenantFormOnMountKeys,
        ledgerAccessCode: {},
    },
};

const leadFormConfig = { onDemand: { lead: {} }, critical: { suggestedFields: {} }, onMount: { suggestedFields: {} } };

const editUserFormConfig = {
    critical: { userRole: {}, facility: {}, userProfile: {} },
    lazy: { account: {}, businessGroup: {} },
    onMount: { facility: {} },
    onDemand: { user: {} },
    permissionKey: USER_MANAGEMENT_PERMISSION_KEY,
};

const createUserFormConfig = {
    critical: { userRole: {}, facility: {} },
    lazy: { account: {}, businessGroup: {} },
    onMount: { facility: {} },
    onDemand: { user: {} },
    permissionKey: USER_MANAGEMENT_PERMISSION_KEY,
};

const reportWcConfig = {
    critical: { standaloneApp: {} },
    onDemand: {
        facility: {},
        userProfile: {},
        reportCategory: {},
        reports: {},
        permissionRoles: {},
        currentFacility: {},
        currentAccountId: {},
    },
    lazy: { users: {}, userRole: {} },
};

const occupancyConfig = {
    onDemand: {
        unit: {},
        tenant: {},
        ledgerAccessCode: {},
        unitAccessCode: {},
        vacantUnitsCountByUnitType: {},
        vacantAndReservedUnitsCountByUnitType: {},
        printer: {},
    },
    critical: { ...tenantFormCriticalKeys, preRequisite: {}, gate: {}, facilityGeneral: {} },
    onMount: { ...tenantFormOnMountKeys, gate: {}, facilityGeneral: {} },
};

const bevConfig = {
    onDemand: { placeholder: {} },
    critical: { unitBevMini: {}, level: {}, bevColors: {}, facilityGeneral: {}, maintenanceEvent: {}, lateEvent: {}, rentalGeneral: {} },
    lazy: { unitBev: {} },
    onMount: { level: {}, bevColors: {}, facilityGeneral: {}, maintenanceEvent: {}, lateEvent: {}, placeholder: {}, rentalGeneral: {} },
};

const externalConfig = {
    critical: {
        facility: {},
        currentFacility: { dependency: ['facility'] },
        permission: { dependency: ['currentFacility'] },
    },
    onDemand: { userProfile: {}, permissionRoles: {} },
    onMount: { facility: {}, currentFacility: {}, permission: {} },
    [ROUTE.ONBOARDING]: { critical: { onboarding: {}, chargeCategory: {} }, onMount: { chargeCategory: {} } },
    [ROUTE.DELINQUENCY_LIST]: { lazy: { delinquency: {} } },
    [ROUTE.TENANT_LIST]: { lazy: { tenant: {} } },
    [ROUTE.RESERVATION_LIST]: { lazy: { reservation: {} } },
    [ROUTE.DOCS_RB]: { lazy: { reprintDocuments: {} } },
    [ROUTE.DOCS_GD]: {
        critical: { unitForDocuments: {} },
        lazy: { tenantsForDocument: {} },
        onMount: { unitForDocuments: {} },
    },
    [ROUTE.WAITING_LIST]: { lazy: { waitingList: {} } },
    // NOTE: Temporary for financial List page
    [ROUTE.FINANCIAL_LIST]: {
        critical: { chargeCategory: {}, paymentMethod: {} },
        onMount: { chargeCategory: { funcName: 'resolveChargeCategoryWithRestricted' }, paymentMethod: {} },
        lazy: { ar: {}, cash: {} },
    },
    [ROUTE.UNIT_LIST]: { lazy: { unit: {} } },
    [ROUTE.POINT_OF_SALE]: {
        lazy: { inventoryList: {} },
        critical: { inventory: {} },
        onMount: { inventory: {} },
    },
    [ROUTE.RETAIL_SALE_RETURNS]: {
        critical: { financialPayment: {} },
        onMount: { financialPayment: {} },
        permissionKey: INVENTORY_PERMISSION_KEYS.RETURN_INVENTORY,
    },
    [ROUTE.CONVERSATIONS]: {
        lazy: { textConversation: {}, emailConversation: {} },
        critical: { textOrEmailUnreadCount: {} },
        onDemand: { text: {} },
    },
    [ROUTE.OPERATIONS]: {
        onDemand: {
            savedDocument: {},
            creditCardBatches: {},
            unit: {},
            unitType: {},
            viewRentAdjustmentGrid: {},
            rentAdjustmentGrid: {},
            tenant: {},
        },
        [ROUTE.OPERATIONS_MAINTENANCE_EVENTS_LIST]: { lazy: { maintenanceOperation: {} } },
        [ROUTE.MANUAL_LATE_EVENT]: {
            lazy: { manualLateEvent: {} },
            critical: { lateEvent: {} },
            onMount: { lateEvent: { funcName: 'resolveActiveLateEvents' } },
        },
        [ROUTE.RENT_ADJUSTMENT_LIST]: { lazy: { rentAdjustment: {} } },
        [ROUTE.AUTO_RENT_ADJUSTMENT_CREATE]: {
            critical: { rentalGeneral: {} },
            onMount: { rentalGeneral: {} },
            lazy: { rentAdjustmentGrid: {} },
            permissionKey: RENT_ADJUSTMENT_KEYS.SCHEDULE,
        },
        [ROUTE.VIEW_RENT_ADJUSTMENT]: { lazy: { viewRentAdjustmentGrid: {} } },
        [ROUTE.FINANCIAL_EXPORT]: { critical: { lastFinancialExport: {} } },
    },
    [ROUTE.VENDOR_LIST]: { lazy: { vendor: {} }, onDemand: { inventoryList: {} } },
    [ROUTE.INVENTORY_LIST]: { lazy: { inventoryList: {} }, onDemand: { inventoryList: {} } },
    [ROUTE.LEAD_LIST]: { lazy: { lead: {} } },
    [ROUTE.EDIT_TENANT]: { ...tenantFormConfig },
    [ROUTE.CREATE_TENANT]: { ...tenantFormConfig },
    [ROUTE.CREATE_LEAD]: { ...leadFormConfig },
    [ROUTE.EDIT_LEAD]: { ...leadFormConfig, lazy: { estimate: {} } },

    // NOTE: Temporary for financial List page
    [ROUTE.CREATE_AR]: { onDemand: { unit: {} } },
    [ROUTE.MOVE_IN]: {
        ...occupancyConfig,
        permissionKey: 'moveIn',
        critical: {
            ...occupancyConfig.critical,
            paymentMethod: {},
            moveIn: {},
            invoicing: {},
            leaseStyle: {},
            vehicle: {},
            letter: {},
        },
        onMount: {
            ...occupancyConfig.onMount,
            paymentMethod: {},
            tenantWebsites: {},
            moveIn: {},
            invoicing: {},
            leaseStyle: {},
            vehicle: {},
            letter: {},
        },
        lazy: { tenantWebsites: {}, ledgerAccessCode: {} },
    },
    [ROUTE.TRANSFER]: {
        permissionKey: 'moveIn',
        critical: {
            leaseStyle: {},
            transfer: {},
            preRequisite: {},
            gate: {},
            paymentMethod: {},
            vacantUnitsCountByUnitType: {},
        },
        onMount: {
            gate: {},
            leaseStyle: {},
            transfer: {},
            paymentMethod: {},
        },
        onDemand: { unitAccessCode: {}, ledgerAccessCode: {} },
    },
    [ROUTE.RESERVATION]: { ...occupancyConfig, permissionKey: RESERVATION_PERMISSION_KEYS.ACCESS },
    [ROUTE.EDIT_RESERVATION]: { ...occupancyConfig },
    [ROUTE.ESTIMATE]: { ...occupancyConfig },
    [ROUTE.WAITING]: { ...occupancyConfig },
    [ROUTE.EDIT_WAITING]: { ...occupancyConfig },
    [ROUTE.MOVE_OUT]: {
        onDemand: { unit: {}, tenant: {} },
        permissionKey: 'moveOut',
        critical: {
            unitType: {},
            rentalGeneral: {},
            customerGeneral: {},
            moveOut: {},
            paymentMethod: {},
            cardProcessor: {},
            requiredFields: {},
            suggestedFields: {},
            financialPayment: {},
        },
        onMount: {
            unitType: {},
            rentalGeneral: {},
            customerGeneral: {},
            moveOut: {},
            paymentMethod: {},
            cardProcessor: {},
            requiredFields: {},
            suggestedFields: {},
            financialPayment: {},
        },
    },
    [ROUTE.BEV_VIEW]: { ...bevConfig },
    [ROUTE.BEV_SETUP]: { ...bevConfig },
    [ROUTE.VIEW_TENANT]: {
        critical: {
            paymentMethod: {},
            invoicing: {},
            taxExemptCategory: {},
            gate: {},
            facilityGeneral: {},
            leaseStyle: {},
            letter: {},
            requiredFields: {},
            suggestedFields: {},
        },
        onMount: {
            paymentMethod: {},
            tenantWebsites: {},
            invoicing: {},
            taxExemptCategory: {},
            gate: {},
            facilityGeneral: {},
            leaseStyle: {},
            letter: {},
            requiredFields: {},
            suggestedFields: {},
        },
        onDemand: { savedDocument: {} },
        lazy: { tenantWebsites: {} },
    },
    [ROUTE.LEDGER_REVIEW]: {
        lazy: { ar: {} },
        critical: { cardProcessor: {}, financialPayment: {}, paymentMethod: {}, financialGeneral: {} },
        onMount: { cardProcessor: {}, financialPayment: {}, paymentMethod: {}, financialGeneral: {} },
    },
    [ROUTE.USER_PROFILE]: { critical: { facility: {}, userProfile: {} } },
    [ROUTE.REPORT_WC]: { ...reportWcConfig },
    [ROUTE.PENDING_OVERLOCK_UNLOCK_LIST]: { lazy: { unitsPendingOverlockUnlock: {} } },
    [ROUTE.AUTOMATIC_CREDIT_CARD_DECLINES_LIST]: {
        lazy: { automaticCreditCardDeclines: {} },
        critical: { financialPayment: {} },
        onMount: { financialPayment: {} },
    },
    [ROUTE.FAILED_RETURNS_LIST]: { lazy: { failedReturns: {} } },
    [ROUTE.TENANT_CHANGES]: { critical: { tenantChangeTypeCount: {} } },
    [ROUTE.PRINT_FAILURES]: { lazy: { printFailures: {} }, critical: { subDocumentPrintFailures: {} } },
    [ROUTE.OTHER_EXTERNAL_ACTIVITY('moveIn')]: { lazy: { moveInSearch: {} } },
    [ROUTE.OTHER_EXTERNAL_ACTIVITY('reservation')]: { lazy: { reservation: {} } },
    [ROUTE.OTHER_EXTERNAL_ACTIVITY('payment')]: { lazy: { payment: {} } },
    [ROUTE.VEHICLE]: {
        critical: {
            requiredFields: {},
            suggestedFields: {},
        },
        onMount: {
            requiredFields: {},
            suggestedFields: {},
        },
        lazy: { vehicleData: {} },
    },
    [ROUTE.OPERATIONS_CREDIT_CARD_BATCH_CREATE]: {
        critical: { paymentMethod: {}, financialPayment: {} },
        onMount: { paymentMethod: {}, financialPayment: {} },
        lazy: { ccBatchTenants: {} },
    },
    [ROUTE.SINGLE_UNIT_RENT_ADJUSTMENT]: {
        critical: { pendingRentAdjustmentsForAUnit: {} },
        onMount: { pendingRentAdjustmentsForAUnit: { funcName: 'resolvePendingRentAdjustmentsForAUnit' } },
        lazy: { unit: {} },
    },
    [ROUTE.DELIVER_INVOICE]: {
        critical: { invoicing: {} },
        lazy: { pendingInvoiceDelivery: {} },
        onMount: { invoicing: {} },
    },
    [ROUTE.EMAIL_AUDIT]: {
        onMount: { emailAudit: {} },
        lazy: { emailAudit: {} },
    },
};

const configurationDependencies = {
    critical: { facility: {} },
    lazy: { businessGroup: {} },
    onDemand: { permissionRoles: {}, userProfile: {} },
    [ROUTE.CHARGE_CATEGORY_LIST]: { lazy: { chargeCategory: {} }, onDemand: { taxCode: {} } },
    // NOTE: Permission key for charge category will only work at facility level, it will fail for BG for now
    // TODO: Depending on selected level check the right permission key
    [ROUTE.CHARGE_CATEGORY]: { permissionKey: CONFIGURATION_PERMISSION_KEYS.FACILITY_ACCESS_FINANCIAL_GROUPING },
    [ROUTE.CARD_PROCESSOR_LIST]: { lazy: { cardProcessor: {} } },
    [ROUTE.TAX_CODE_LIST]: { lazy: { taxCode: {} }, onDemand: { chargeCategory: {} } },
    [ROUTE.TAX_EXEMPT_LIST]: { lazy: { taxExemptCategory: {} }, onDemand: { taxCode: {} } },
    [ROUTE.PAYMENT_METHOD_LIST]: { lazy: { paymentMethod: {} } },
    [ROUTE.UNIT_TYPE_LIST]: { lazy: { unitType: {} }, onDemand: { chargeCategory: {} } },
    [ROUTE.PROMO_PLAN_LIST]: { lazy: { promoPlan: {} }, onDemand: { chargeCategory: {} } },
    [ROUTE.PROMO_PLAN_TEMPLATE_LIST]: { lazy: { promoPlanTemplate: {} } },
    [ROUTE.LETTER_LIST]: { lazy: { letter: {} } },
    [ROUTE.LETTER_CONFIGURATION]: { onDemand: { customMergeFields: {} } },
    [ROUTE.LATE_EVENT_LIST]: { lazy: { lateEvent: {} } },
    [ROUTE.LATE_EVENT_FORM]: { lazy: { unitType: {} }, onMount: { unitType: {} } },
    [ROUTE.BEV_COLOR_SETUP]: { lazy: { bevColors: {} }, onMount: { bevColors: {} } },
    [ROUTE.MAINTENANCE_EVENT_LIST]: { lazy: { maintenanceEvent: {} }, onDemand: { chargeCategory: {} } },
    [ROUTE.LEASE_STYLE_LIST]: { lazy: { leaseStyle: {} } },
    [ROUTE.INSURANCE]: { lazy: { insuranceCoverage: {} } },
    [ROUTE.INTERNAL_APP]: { critical: { standaloneApp: {} } },
    [ROUTE.UNIT_TYPE_FORM]: { critical: { rentalGeneral: {} }, onMount: { rentalGeneral: {} } },
    [ROUTE.UNIT_TYPE_BULK]: {
        critical: { rentalGeneral: {}, unitType: {}, chargeCategory: {} },
        onMount: { rentalGeneral: {}, unitType: {}, chargeCategory: {} },
    },
    [ROUTE.VIOLATION_LIST]: { lazy: { violation: {} } },
    [ROUTE.VIOLATION_CONFIGURATION]: { lazy: { violation: {} }, critical: { chargeCategory: {} }, onMount: { chargeCategory: {} } },
};

const settingsDependencies = {
    critical: {
        userProfile: {},
        facility: { dependency: ['userProfile'] },
        currentFacility: { dependency: ['facility'] },
        permission: { dependency: ['currentFacility'] },
    },
    onDemand: { userProfile: {}, permissionRoles: {} },
    onMount: { permission: {}, currentFacility: {}, permissionRoles: {} },
    [ROUTE.USER_PROFILE]: { critical: { facility: {}, userProfile: {} } },
    [ROUTE.USER_LIST]: {
        lazy: { user: {} },
        onDemand: { userProfile: {} },
        permissionKey: USER_MANAGEMENT_PERMISSION_KEY,
    },
    [ROUTE.CREATE_USER]: { ...createUserFormConfig },
    [ROUTE.EDIT_USER]: { ...editUserFormConfig },
    [ROUTE.USER_ROLE]: { critical: { userRole: {}, userProfile: {} }, permissionKey: USER_MANAGEMENT_PERMISSION_KEY },
    [ROUTE.EDIT_USER_ROLE]: {
        critical: { userRole: {}, userProfile: {} },
        permissionKey: USER_MANAGEMENT_PERMISSION_KEY,
    },
    [ROUTE.CREATE_ROLE]: {
        critical: { userRole: {}, userProfile: {} },
        permissionKey: USER_MANAGEMENT_PERMISSION_KEY,
    },
    [ROUTE.CONFIGURATION]: { ...configurationDependencies },
    [ROUTE.SYSTEM_CONFIGURATION]: { ...configurationDependencies },
    [ROUTE.REPORT_WC]: { ...reportWcConfig },
    [ROUTE.CONSOLIDATED_REPORT_WC]: { ...reportWcConfig },
};

const liveDataConfig = {
    ...externalConfig,
    critical: {
        ...externalConfig.critical,
        currentAccountId: { dependency: ['facility'] },
    },
    onMount: {
        ...externalConfig.onMount,
        facility: {},
        currentAccountId: {},
    },
};

const liveSettingsConfig = {
    ...settingsDependencies,
    critical: {
        ...settingsDependencies.critical,
        currentAccountId: { dependency: ['facility'] },
    },
    onMount: {
        ...settingsDependencies.onMount,
        facility: {},
        currentAccountId: {},
    },
    [ROUTE.CONFIGURATION]: {
        ...settingsDependencies[ROUTE.CONFIGURATION],
        onMount: { ...settingsDependencies[ROUTE.CONFIGURATION].onMount, facility: {} },
    },
    [ROUTE.SYSTEM_CONFIGURATION]: {
        ...settingsDependencies[ROUTE.SYSTEM_CONFIGURATION],
        onMount: { ...settingsDependencies[ROUTE.SYSTEM_CONFIGURATION].onMount, facility: {} },
    },
};

const DependencyConfig = {
    Header: { onDemand: { userProfile: {}, facility: {}, permissionRoles: {}, businessGroup: {} } },
    Login: { onDemand: { userProfile: {} } },
    PendingTasks: {
        lazy: {
            printFailuresCount: {},
            pendingOverlockUnlockCount: {},
            restockInventoryCount: {},
            inventoryAuditCount: {},
            applyLateEventsCount: {},
            pastDueReservationsCount: {},
            automaticCreditCardDeclinesCount: {},
            userProfile: {},
            failedReturnsCount: {},
            tenantChangesCount: {},
            moveInCount: {},
            tfwReservationCount: {},
            paymentCount: {},
            textOrEmailUnreadCount: {},
            ccAboutToExpire: {},
            pendingInvoiceDeliveryCount: {},
        },
        critical: { tenantWebsites: {}, gate: {} },
        onMount: { tenantWebsites: {}, gate: {} },
    },
    TenantInvalidAccessCodeList: { lazy: { tenantsWithInvalidAccessCodes: {} } },
    UnitInvalidAccessCodeList: { lazy: { unitsWithInvalidAccessCodes: {} } },
    [ROUTE.EXTERNAL_MAIN]: { ...externalConfig },
    [ROUTE.LIVE_DATA]: { ...liveDataConfig },
    [ROUTE.PULLED_DATA]: { ...liveDataConfig },
    TenantForm: { ...tenantFormConfig },
    LeadForm: { ...leadFormConfig, lazy: { estimate: {} } },
    UnitForm: {
        critical: { level: {}, gate: {}, leaseStyle: {}, moveIn: {} },
        onMount: { level: {}, gate: {}, leaseStyle: {}, moveIn: {} },
        onDemand: { rateHistory: {}, unitAccessCode: {} },
    },
    RateHistory: { lazy: { rateHistory: {} } },
    LateEvents: { lazy: { lateEvents: {} }, onDemand: { lateEvents: {} } },
    VendorForm: { onDemand: { vendor: {} } },
    InventoryAudit: { critical: { inventory: {}, inventoryList: {} }, onMount: { inventory: {} } },
    InventoryBulkRestock: { critical: { inventoryList: {} } },
    InventoryIndividualRestock: { onDemand: { inventoryList: {} } },
    InventoryForm: { onDemand: { inventoryList: {} }, critical: { inventory: {} }, onMount: { inventory: {} } },
    PointOfSale: {
        lazy: { inventoryList: {} },
        critical: { inventory: {}, financialPayment: {} },
        onMount: { inventory: {}, financialPayment: {} },
    },
    HomePage: { onDemand: { userProfile: {} } },
    Payment: {
        critical: { paymentMethod: {}, financialPayment: {}, cardProcessor: {}, userProfile: {}, promoPlan: {} },
        permissionKey: 'payment',
        onMount: {
            paymentMethod: { funcName: 'resolveActivePaymentMethod' },
            financialPayment: {},
            cardProcessor: { funcName: 'resolveActiveCardProcessor' },
            promoPlan: { funcName: 'resolvePaymentAccessiblePromoPlans' },
        },
    },
    DeliveryMethodDropdown: { onDemand: { printer: {}, currentFacility: {} } },
    Documentation: { lazy: { savedDocument: {} } },
    TenantAlerts: { lazy: { tenantAlert: {} } },
    UnpaidBills: { lazy: { unpaidAr: {} } },
    ReservationCRM: { lazy: { reservation: {} } },
    ContactLog: { lazy: { contactLog: {} } },
    TenantDeposit: { lazy: { tenantDepositToBePaid: {} } },
    WrappedInventoryBulkRestock: { lazy: { inventoryList: {} } },
    WrappedInventoryAudit: { lazy: { inventoryList: {} } },
    SearchableDropdown: { onDemand: { timezone: {} } },
    AllBatches: { lazy: { savedDocument: {} } },
    CurrentBatch: { lazy: { achTransaction: {} } },
    CreditCardBatches: { lazy: { creditCardBatches: {} } },
    TextList: { lazy: { text: {} } },
    EmailList: { lazy: { email: {} } },
    TextOrEmailSelection: {
        lazy: {
            textConversation: {},
            emailConversation: {},
        },
        onDemand: { textOrEmailUnreadCount: {} },
    },
    ListOrCompose: { onDemand: { text: {} } },
    UnitDepositUpdate: { lazy: { unit: {} }, onDemand: { unit: {} } },
    DepositUpdate: { lazy: { unit: {}, tenant: {} }, onDemand: { unit: {}, tenant: {} } },
    TenantDepositUpdate: { lazy: { tenant: {} }, onDemand: { tenant: {} } },

    [ROUTE.SETTINGS_MAIN]: { ...settingsDependencies },
    [ROUTE.SETTINGS_LIVE]: { ...liveSettingsConfig },
    [ROUTE.SETTINGS_PULLED]: { ...liveSettingsConfig },
    TenantAddressChanges: { lazy: { tenantAddressChanges: {} }, onDemand: { tenantAddressChanges: {} } },
    TenantAlternateContactChanges: {
        lazy: { tenantAlternateContactChanges: {} },
        onDemand: { tenantAlternateContactChanges: {} },
    },
    TenantEmailChanges: { lazy: { tenantEmailChanges: {} }, onDemand: { tenantEmailChanges: {} } },
    TenantPhoneChanges: { lazy: { tenantPhoneChanges: {} } },
    GenerateLeaseAgreement: {
        critical: { moveIn: {}, leaseStyle: {} },
        onMount: { moveIn: {}, leaseStyle: {} },
    },
    LetterTemplateList: { lazy: { sampleLetter: {} }, onMount: { sampleLetter: {} } },
    LeaseStyleBulk: { lazy: { unitType: {} }, critical: { leaseStyle: {} }, onMount: { unitType: {}, leaseStyle: {} } },
    CCAboutToExpireList: { lazy: { ccAboutToExpire: {} } },
};

export default DependencyConfig;
