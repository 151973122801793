import { createEnumMap } from 'Commons/helpers/utils/Formatter';
import { ROUTE } from 'External/redux/config/RouteNames';
import enums from './Enums';
import CONFIG_TYPE from './ConfigRequestType';

const TEXT_FIELD_WIDTH = 250;
const DROPDOWN_MAX_HEIGHT = 300;
const SMALL_DROPDOWN_MAX_HEIGHT = 250;
const SMALL_TEXTFIELD_WIDTH = 200;
const EXTRA_SMALL_TEXTFIELD_WIDTH = 50;
const LARGE_TEXTFIELD_WIDTH = 300;
const EXTRA_LARGE_TEXTFIELD_WIDTH = 350;

const CONFIGURATION_DEFAULT_SORT = {
    label: 'Description',
    value: 'Description',
    sort: [{ value: { description: { order: 'asc' } } }],
};
const FIXED_FILTERS_KEYS = ['configType', 'entityType', 'entityId'];
const OVERRIDABLE_CONFIGS = [CONFIG_TYPE.CHARGE_CATEGORY, CONFIG_TYPE.PAYMENT_METHOD];

const ENTITY_TYPE = createEnumMap(enums.entityType);
const CONFIG_LEVEL_TYPE = createEnumMap(enums.configType);
const DEACTIVATE_TYPE = createEnumMap(enums.deactivateType);
const CONFIGURATION_TABS = createEnumMap(enums.configurationTab);


const RESOURCE_NAME_TO_CONFIG_MAPPING = {
    [ROUTE.RETAIL_SALE]: 'retail_sale_configuration',
    [ROUTE.FACILITY_GENERAL]: 'facility_configuration',
    [ROUTE.BEV_COLOR_SETUP]: 'facility_configuration',
    [ROUTE.MAINTENANCE_EVENT_LIST]: 'facility_configuration',
    [ROUTE.FINANCIAL_GENERAL]: 'financial_configuration',
    [ROUTE.CUSTOMER_GENERAL]: 'customer_configuration',
    [ROUTE.RENTAL_GENERAL]: 'rental_configuration',
    [ROUTE.UNIT_TYPE_LIST]: 'unit_configuration',
    [ROUTE.PROMO_PLAN_LIST]: 'rental_configuration',
    [ROUTE.PROMO_PLAN_TEMPLATE_LIST]: 'rental_configuration',
    [ROUTE.MOVE_IN]: 'rental_configuration',
    [ROUTE.MOVE_OUT]: 'rental_configuration',
    [ROUTE.RESERVATION]: 'rental_configuration',
    [ROUTE.CHARGE_CATEGORY_LIST]: 'financial_configuration',
    [ROUTE.PAYMENT_METHOD_LIST]: 'financial_configuration',
    [ROUTE.TAX_CODE_LIST]: 'financial_configuration',
    [ROUTE.TAX_EXEMPT_LIST]: 'financial_configuration',
    [ROUTE.PAYMENT_CONFIGURATION]: 'financial_configuration',
    [ROUTE.CHARGE_CATEGORY_MAPPING]: 'financial_configuration',
    [ROUTE.TENANT_SECTIONS]: 'customer_configuration',
    [ROUTE.LETTER_LIST]: 'customer_engagement_configuration',
    [ROUTE.TENANT_WEBSITES]: 'facility_configuration',
    [ROUTE.LATE_EVENT_LIST]: 'rental_configuration',
    [ROUTE.FINANCIAL_EXPORT]: 'financial_configuration',
};

const RESOURCE_URL_TO_CONFIG_TAB_MAPPING = {
    [ROUTE.RETAIL_SALE]: CONFIGURATION_TABS.BILLING_AND_ALLOCATION,
    [ROUTE.FACILITY_GENERAL]: CONFIGURATION_TABS.FACILITY,
    [ROUTE.BEV_COLOR_SETUP]: CONFIGURATION_TABS.UNITS,
    [ROUTE.MAINTENANCE_EVENT_LIST]: CONFIGURATION_TABS.BILLING_AND_ALLOCATION,
    [ROUTE.FINANCIAL_GENERAL]: CONFIGURATION_TABS.BILLING_AND_ALLOCATION,
    [ROUTE.CUSTOMER_GENERAL]: CONFIGURATION_TABS.TENANTS,
    [ROUTE.RENTAL_GENERAL]: CONFIGURATION_TABS.BILLING_AND_ALLOCATION,
    [ROUTE.UNIT_TYPE_LIST]: CONFIGURATION_TABS.UNITS,
    [ROUTE.PROMO_PLAN_LIST]: CONFIGURATION_TABS.BILLING_AND_ALLOCATION,
    [ROUTE.MOVE_IN]: CONFIGURATION_TABS.OCCUPANCY,
    [ROUTE.MOVE_OUT]: CONFIGURATION_TABS.OCCUPANCY,
    [ROUTE.RESERVATION]: CONFIGURATION_TABS.OCCUPANCY,
    [ROUTE.TRANSFER]: CONFIGURATION_TABS.OCCUPANCY,
    [ROUTE.CHARGE_CATEGORY_LIST]: CONFIGURATION_TABS.BILLING_AND_ALLOCATION,
    [ROUTE.PAYMENT_METHOD_LIST]: CONFIGURATION_TABS.PAYMENTS,
    [ROUTE.TAX_CODE_LIST]: CONFIGURATION_TABS.BILLING_AND_ALLOCATION,
    [ROUTE.TAX_EXEMPT_LIST]: CONFIGURATION_TABS.BILLING_AND_ALLOCATION,
    [ROUTE.PAYMENT_CONFIGURATION]: CONFIGURATION_TABS.PAYMENTS,
    [ROUTE.CHARGE_CATEGORY_MAPPING]: CONFIGURATION_TABS.BILLING_AND_ALLOCATION,
    [ROUTE.TENANT_SECTIONS]: CONFIGURATION_TABS.TENANTS,
    [ROUTE.LETTER_LIST]: CONFIGURATION_TABS.DOCUMENTATION,
    [ROUTE.TENANT_WEBSITES]: CONFIGURATION_TABS.FACILITY,
    [ROUTE.LATE_EVENT_LIST]: CONFIGURATION_TABS.BILLING_AND_ALLOCATION,
    [ROUTE.FINANCIAL_EXPORT]: CONFIGURATION_TABS.BILLING_AND_ALLOCATION,
    [ROUTE.DELINQUENCY_LIST]: CONFIGURATION_TABS.BILLING_AND_ALLOCATION,
    [ROUTE.ACH]: CONFIGURATION_TABS.PAYMENTS,
    [ROUTE.UNIT_TYPE_BULK]: CONFIGURATION_TABS.UNITS,
    [ROUTE.VEHICLE]: CONFIGURATION_TABS.TENANTS,
    [ROUTE.INVOICING]: CONFIGURATION_TABS.DOCUMENTATION,
    [ROUTE.LEASE_GENERAL]: CONFIGURATION_TABS.DOCUMENTATION,
    [ROUTE.LEASE_STYLE_LIST]: CONFIGURATION_TABS.DOCUMENTATION,
    [ROUTE.INTERNAL_APP]: CONFIGURATION_TABS.DOCUMENTATION,
    [ROUTE.GATES]: CONFIGURATION_TABS.THIRD_PARTY_SUPPORT,
    [ROUTE.INSURANCE]: CONFIGURATION_TABS.THIRD_PARTY_SUPPORT,
};


const FACILITY_CONFIG_TAB_ID_MAPPING = {
    [CONFIGURATION_TABS.FACILITY]: 0,
    [CONFIGURATION_TABS.BILLING_AND_ALLOCATION]: 1,
    [CONFIGURATION_TABS.PAYMENTS]: 2,
    [CONFIGURATION_TABS.OCCUPANCY]: 3,
    [CONFIGURATION_TABS.UNITS]: 4,
    [CONFIGURATION_TABS.TENANTS]: 5,
    [CONFIGURATION_TABS.DOCUMENTATION]: 6,
    [CONFIGURATION_TABS.THIRD_PARTY_SUPPORT]: 7,
};

const BUSINESS_GROUP_CONFIG_TAB_ID_MAPPING = {
    [CONFIGURATION_TABS.BILLING_AND_ALLOCATION]: 0,
    [CONFIGURATION_TABS.PAYMENTS]: 1,
    [CONFIGURATION_TABS.DOCUMENTATION]: 2,
};

export {
    TEXT_FIELD_WIDTH, DROPDOWN_MAX_HEIGHT, SMALL_TEXTFIELD_WIDTH, LARGE_TEXTFIELD_WIDTH, ENTITY_TYPE,
    EXTRA_SMALL_TEXTFIELD_WIDTH, CONFIGURATION_DEFAULT_SORT, FIXED_FILTERS_KEYS, CONFIG_LEVEL_TYPE,
    RESOURCE_NAME_TO_CONFIG_MAPPING, SMALL_DROPDOWN_MAX_HEIGHT, OVERRIDABLE_CONFIGS, EXTRA_LARGE_TEXTFIELD_WIDTH,
    DEACTIVATE_TYPE, CONFIGURATION_TABS, RESOURCE_URL_TO_CONFIG_TAB_MAPPING, FACILITY_CONFIG_TAB_ID_MAPPING, BUSINESS_GROUP_CONFIG_TAB_ID_MAPPING,
};
