import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { data = [], ...remainingData } = {} } = processedData;
    const newData = data.reduce((prevValue, { id, label } = {}) => ({
        ...prevValue,
        [label]: id,
    }), {});
    processedData = {
        ...processedData,
        data: { ...remainingData, data: newData },
    };
    return processedData;
};
