import { mapRelationalData } from 'Commons/redux/helper/RelationalDataMap';
import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

const mapTenantData = (processedUnits, tenantData) => {
    const { data: { data: allUnitsData = [], ...nestedData } = {} } = processedUnits;
    const units = [];
    const tenantsMappedByLedgerId = tenantData.reduce((acc, tenant) => {
        const { ledger = [] } = tenant;
        return {
            ...acc,
            ...ledger.reduce((ac, { id }) => ({
                ...ac,
                [id]: tenant,
            }), {}),
        };
    }, {});
    allUnitsData.forEach((unit) => {
        const { ledger: { id: unitLedgerId } = {} } = unit;
        let tenant;
        if (unitLedgerId) {
            tenant = tenantsMappedByLedgerId[unitLedgerId];
        }
        units.push({ ...unit, tenant });
    });
    return { data: units, ...nestedData };
};

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const {
        data: { relationalData: { tenant: unMappedTenantData = [] } = {} } = {},
        ...outerData
    } = processedData;

    const keys = [{ dataKey: 'unitType.id', relationalDataKey: 'unitType' }];
    processedData = mapRelationalData(processedData, keys);
    processedData = mapTenantData(processedData, unMappedTenantData);

    return {
        ...outerData,
        data: { ...processedData },
    };
};
