import { labelFormatter, mapEnum, createEnumMap } from 'Commons/helpers/utils/Formatter';
import { DELINQUENCY_STATUS, LOCKOUT_TYPE, STATUS } from 'Commons/config/constants/Constants';
import enums from './Enums';

const SEARCH_TYPE = mapEnum(enums.searchType);
const LATE_TYPE = mapEnum(enums.lateType);
const LATE_EVENT_TYPE = createEnumMap(['Late', 'Lockout']);

const ALLOWED_LATE_EVENT_STATUS = [
    DELINQUENCY_STATUS.Active.value,
    DELINQUENCY_STATUS.Processed.value,
    DELINQUENCY_STATUS.Skipped.value,
    DELINQUENCY_STATUS.Waived.value,
];
const ALL_LOCKOUT_EVENT_TYPES = [
    LOCKOUT_TYPE.Unit_Overlock.value,
    LOCKOUT_TYPE.Gate_Lockout.value,
    LOCKOUT_TYPE.Tenant_Portal_Lockout.value,
];

const ALLOWED_LATE_EVENT_TYPE = [
    LATE_TYPE.One_Time.value,
    LATE_TYPE.Recurring.value,
    LATE_TYPE.Dollar_Per_Day.value,
];

// Default Filters
const DELINQUENCY_TRACKING_OVERVIEW_DEFAULT_FILTER = { terms: { anniversary: ['true', 'false'] } };
const DELINQUENCY_TRACKING_LATE_EVENTS_DEFAULT_FILTER = {
    terms: {
        status: ALLOWED_LATE_EVENT_STATUS,
        type: ALLOWED_LATE_EVENT_TYPE,
    },
};
const DELINQUENCY_TRACKING_LOCKOUT_EVENTS_DEFAULT_FILTER = {
    terms: {
        status: [DELINQUENCY_STATUS.Active.value, DELINQUENCY_STATUS.Processed.value],
        type: ALL_LOCKOUT_EVENT_TYPES,
    },
};
const DELINQUENCY_TRACKING_SCHEDULED_EVENTS_DEFAULT_FILTER = {
    terms: {
        status: [STATUS.Active.value,
            STATUS.Inactive.value],
    },
};
const DELINQUENCY_TRACKING_WRONG_ENTRY_DEFAULT_FILTER = { terms: { events: ALL_LOCKOUT_EVENT_TYPES } };

// stores
const DELINQUENCY_TRACKING_OVERVIEW_STORE = 'delinquencyTrackingOverview';
const DELINQUENCY_TRACKING_LATE_EVENT_STORE = 'delinquencyTrackingLateEvent';
const DELINQUENCY_TRACKING_LOCKOUT_EVENT_STORE = 'delinquencyTrackingLockoutEvent';
const DELINQUENCY_TRACKING_SCHEDULED_EVENT_STORE = 'delinquencyTrackingScheduledEvent';
const DELINQUENCY_TRACKING_WRONG_ENTRY_STORE = 'delinquencyTrackingWrongEntries';

const getDelinquencyTrackingStoreKey = (delinquencyType) => {
    let storeKey;
    switch (delinquencyType) {
        case (SEARCH_TYPE.Late_Events.value):
            storeKey = DELINQUENCY_TRACKING_LATE_EVENT_STORE;
            break;
        case (SEARCH_TYPE.Lockout_Events.value):
            storeKey = DELINQUENCY_TRACKING_LOCKOUT_EVENT_STORE;
            break;
        case (SEARCH_TYPE.Scheduled_Events.value):
            storeKey = DELINQUENCY_TRACKING_SCHEDULED_EVENT_STORE;
            break;
        case (SEARCH_TYPE.Wrong_Entries.value):
            storeKey = DELINQUENCY_TRACKING_WRONG_ENTRY_STORE;
            break;
        default:
            storeKey = DELINQUENCY_TRACKING_OVERVIEW_STORE;
            break;
    }
    return storeKey;
};


const getHeaderText = (delinquencySearchType = '', facilityName) => {
    let headerText = labelFormatter(delinquencySearchType);
    if (facilityName) headerText = headerText.concat(` - ${facilityName}`);
    return headerText;
};


const TABLE_WIDTH = 1020;
const TABLE_LEFT_AND_RIGHT_PADDING = 0;

export {
    SEARCH_TYPE, LATE_TYPE, getDelinquencyTrackingStoreKey, getHeaderText,
    DELINQUENCY_TRACKING_OVERVIEW_STORE, DELINQUENCY_TRACKING_LATE_EVENT_STORE, DELINQUENCY_TRACKING_LOCKOUT_EVENT_STORE,
    DELINQUENCY_TRACKING_SCHEDULED_EVENT_STORE, DELINQUENCY_TRACKING_WRONG_ENTRY_STORE,
    DELINQUENCY_TRACKING_OVERVIEW_DEFAULT_FILTER, DELINQUENCY_TRACKING_LATE_EVENTS_DEFAULT_FILTER,
    DELINQUENCY_TRACKING_LOCKOUT_EVENTS_DEFAULT_FILTER, DELINQUENCY_TRACKING_SCHEDULED_EVENTS_DEFAULT_FILTER,
    DELINQUENCY_TRACKING_WRONG_ENTRY_DEFAULT_FILTER,
    TABLE_WIDTH, TABLE_LEFT_AND_RIGHT_PADDING, LATE_EVENT_TYPE,
};
