import { mapEnum, createEnumMap } from 'Commons/helpers/utils/Formatter';
import LabelWithAddons from 'Generic/labelwithaddons/components/LabelWithAddons';
import { Typography, makeStyles } from 'Generic/componentlibrary/components/Components';
import { clsx } from 'Commons/helpers/utils/clsx';
import enums from './Enums';
import { severityLevel, mode, tenantAlertList } from './SeverityLevelAndMode';
import formatterStyle from '../styles/FormatterStyle';

const useStyles = makeStyles(formatterStyle, { name: 'LabelFormatter' });

const LabelComponent = ({ Component = 'div', value = '', icon, isSeverityIcon }) => {
    const classes = useStyles({ ...isSeverityIcon && { value } });
    return (
        <LabelWithAddons start={<Component icon={icon} className={clsx(isSeverityIcon && classes.iconColor)} />}>
            <Typography variant="body2" noWrap>
                {value}
            </Typography>
        </LabelWithAddons>
    );
};

LabelComponent.propTypes = {
    Component: PropTypes.func,
    value: PropTypes.string,
    icon: PropTypes.string,
    isSeverityIcon: PropTypes.bool,
};

const labelFormatter = props => <LabelComponent {...props} />;

const autoInvoiceDeliveryTypeFormatter = (label) => {
    let updatedLabel = label.replace(/_/g, ' ');
    if (label === 'Manual') updatedLabel += '/ Pending Tasks';
    return updatedLabel;
};


const mapObject = (list, formatter = labelFormatter, isSeverityIcon = false) => {
    const mappedObject = {};
    list.forEach((object) => {
        const { value, ...rest } = object;
        mappedObject[value] = {
            label: formatter({ ...object, isSeverityIcon }),
            value,
            ...rest,
        };
    });
    return mappedObject;
};

const WEBSITE_ACCESS = mapEnum(enums.websiteAccess);

const SEVERITY_LEVEL = mapObject(severityLevel, labelFormatter, true);

const MODE = mapObject(mode, labelFormatter);

const NUMBER_OF_ROWS_WHEN_COLLAPSED = 3;
const NUMBER_OF_ROWS_WHEN_EXPANDED = 5;

const MAX_VISIBLE_UNITS = 2;
const MAX_VISIBLE_UNITS_FOR_MOBILE = 1;

const TOTAL_MARGIN = 64;
const TOTAL_MARGIN_CRM = 32;
const SMART_LIST_DEFAULT_WIDTH = 1000;

const SMART_LIST_WIDTH_FOR_VIEW_TENANT_COMPONENTS = 800;

const DROPDOWN_MAX_HEIGHT = 100;
const DROPDOWN_WIDTH = 150;

const TABLE_HEADER_HEIGHT = 48;

const VISIBLE_AT = createEnumMap(enums.visibleAt);

const CONTACT_LOG_CUSTOMER_TYPE = createEnumMap(enums.contactLogCustomerType);
const CONTACT_LOG_TYPE = createEnumMap(enums.contactLogType);
const CONTACT_LOG_AUTO_MODE = createEnumMap(enums.contactLogAutoMode);

const DOCUMENTATION_DEFAULT_WIDTH = 864;
const DOCUMENTATION_DEFAULT_HEIGHT = 796;
const DOCUMENTATION_RESPONSIVE_WIDTH = 1024;
const DOCUMENTATION_RESPONSIVE_HEIGHT = 300;

const INVOICE_STYLE = mapEnum(enums.invoiceStyle);
const INVOICE_TYPE = mapEnum(enums.invoiceType);
const INVOICING_STATUS = createEnumMap(enums.invoicingStatus);
const RECIPIENT_TYPE = createEnumMap(enums.recipientType);
const AUTO_INVOICE_DELIVERY_TYPE = mapEnum(enums.autoInvoiceDeliveryType, autoInvoiceDeliveryTypeFormatter);

const getInvoicingInitValues = ({ daysBeforeRentAssessment = 0 } = {}) => ({
    invoicing: {
        isEnabled: false,
        daysBeforeRentAssessment,
        generateForZeroBalance: false,
    },
});

const TENANT_ALERT = mapEnum(tenantAlertList);

const MODULE = mapEnum(enums.alertConfigurationModules);

const VIEW_TENANT_ALERT_NAME = createEnumMap(enums.viewTenantAlerts);

const UNPAID_AR_STORE = 'unpaidAr';

const AR_AGGREGATION = createEnumMap(enums.arAggregation);

const DOCUMENTATION_STATUS = mapEnum(enums.documentationStatus);


export {
    WEBSITE_ACCESS, SEVERITY_LEVEL, NUMBER_OF_ROWS_WHEN_COLLAPSED, MAX_VISIBLE_UNITS, VISIBLE_AT,
    NUMBER_OF_ROWS_WHEN_EXPANDED, MODE, TOTAL_MARGIN, TOTAL_MARGIN_CRM, SMART_LIST_DEFAULT_WIDTH, DROPDOWN_MAX_HEIGHT,
    DROPDOWN_WIDTH, TABLE_HEADER_HEIGHT, MAX_VISIBLE_UNITS_FOR_MOBILE, DOCUMENTATION_DEFAULT_WIDTH,
    DOCUMENTATION_DEFAULT_HEIGHT, DOCUMENTATION_RESPONSIVE_WIDTH, DOCUMENTATION_RESPONSIVE_HEIGHT,
    CONTACT_LOG_CUSTOMER_TYPE, CONTACT_LOG_TYPE, CONTACT_LOG_AUTO_MODE, INVOICE_STYLE, INVOICE_TYPE,
    RECIPIENT_TYPE, getInvoicingInitValues, TENANT_ALERT, SMART_LIST_WIDTH_FOR_VIEW_TENANT_COMPONENTS,
    VIEW_TENANT_ALERT_NAME, INVOICING_STATUS, UNPAID_AR_STORE, AR_AGGREGATION, DOCUMENTATION_STATUS, AUTO_INVOICE_DELIVERY_TYPE, MODULE,
};
