import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

export default (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const data = [];
    const { data: { data: tenants = [] } = {} } = processedData;
    tenants.forEach((tenant) => {
        const { unitList = [] } = tenant || {};
        if (unitList.length > 1) {
            unitList.forEach(unit => data.push({ ...tenant, unitList: [{ ...unit }] }));
        } else {
            data.push(tenant);
        }
    });
    processedData.data.data = data;
    return processedData;
};
