import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions) || {};
    const { isDataReversed } = localReqOptions;
    const { data: { data: textData = [], relationalData = {}, ...restData } = {} } = processedData;
    if (isDataReversed) {
        processedData = { data: { data: textData.reverse(), relationalData, ...restData } };
    }
    return processedData;
};
