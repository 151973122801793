import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

const mapUnitData = (processedUnits, unitData) => {
    const { data: { data: tenantData = [], ...nestedData } = {} } = processedUnits;
    const ledgerList = tenantData.flatMap(tenant => tenant.ledger.map((eachLedger) => {
        const newTenant = { ...tenant };
        newTenant.ledger = [eachLedger];
        newTenant.unitList = unitData
            .filter(unit => unit.ledger.id === eachLedger.id)
            .map(unit => ({ id: unit.id, label: unit.label }));
        return newTenant;
    }));
    return { data: ledgerList, ...nestedData };
};

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const {
        storeData:
        {
            tenantsWithInvalidAccessCodes:
            { data: { actualRows = 0 } = {} } = {},
        } = {},
    } = localReqOptions || {};

    const {
        data: { relationalData: { Unit: unMappedUnitData = [] } = {}, data: unMappedTenantData, totalCount: initialCount = 0 } = {},
        ...outerData
    } = processedData;

    processedData = mapUnitData(processedData, unMappedUnitData);

    const totalCount = unMappedTenantData.length < 20 ? initialCount : 10000;
    return {
        ...outerData,
        data: { ...processedData, totalCount, actualRows: actualRows + unMappedTenantData.length },
    };
};
