const enums = {
    searchType: [
        'Delinquency_Overview',
        'Late_Events',
        'Lockout_Events',
        'Scheduled_Events',
        'Wrong_Entries',
    ],
    lockoutEventType: ['Unit_Overlock', 'Gate_Lockout', 'Tenant_Portal_Lockout'],
    lateType: ['One_Time', 'Recurring', 'Dollar_Per_Day'],
};
export default enums;
