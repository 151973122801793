import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import getName from 'Commons/helpers/utils/NameHelper';
import STATUS from 'Commons/config/constants/StoreKeyStatus';

export default (apiResult, callback, dispatch, localReqOptions) => {
    const { error, response = {} } = apiResult;
    const processedData = baseProcessor(apiResult, undefined, dispatch, localReqOptions);
    const { data: { relationalData: { tenant: tenants = [] } = {}, data = [] } = {} } = processedData;
    const allTenantsMap = tenants.reduce((a, v) => ({ ...a, [v.id]: v }), {});
    const updatedData = data.map((document) => {
        const tenantData = allTenantsMap[document.tenantId];
        const ledgerData = tenantData && tenantData.ledger.filter(item => item.id === document.ledgerId)[0];
        return {
            ...document,
            ...(tenantData && tenantData.name && { tenantName: getName(tenantData.name) }),
            ...(ledgerData && ledgerData.label && { ledgerName: ledgerData.label }),
        };
    });
    processedData.data.data = updatedData;
    const isQueued = response.status === STATUS.QUEUED;
    if (!isQueued && callback) {
        callback(error, processedData.data);
    }
    return processedData;
};
