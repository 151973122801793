const enums = {
    status: ['Pending', 'Completed', 'Stopped'],
    byTenantFiltersType: ['last_adjustment', 'occupancy_over'],
    recurPeriod: ['Day', 'Week', 'Month', 'Annual', 'Quarter', 'Bi_Annual', 'Twenty_Eight_Day', 'Unit_Billing_Period'],
    roundingType: ['Nearest_Cent', 'Round_To_Nearest_Dollar', 'Round_Up_To_Nearest_Dollar'],
    calcTypesForSchedulingOnRentedUnits: ['Flat', 'Percent_Of_Std', 'Increase_To_Std', 'Percent_Of_Cur_Amt',
        'Lower_Of_Flat_Or_Percent_Of_Cur', 'Lower_Of_Flat_Or_Percent_Of_Std',
        'Higher_Of_Flat_Or_Percent_Of_Cur', 'Higher_Of_Flat_Or_Percent_Of_Std',
    ],
    calcTypesForSchedulingOnVacantUnits: ['Flat', 'Percent_Of_Std', 'Increase_To_Std',
        'Lower_Of_Flat_Or_Percent_Of_Std', 'Higher_Of_Flat_Or_Percent_Of_Std',
    ],
    warningType: ['Rent_Adj_Already_Exists', 'New_Rate_Exceeds_Std_Rate',
        'New_Rate_Exceeds_X_Percent_Of_Cur_Rate', 'Invoice_To_Date_Is_After_Rent_Adj_Date'],
    warningResolutionType: ['Cancel_This', 'Cancel_Existing', 'Continue_as_is', 'Match_XPercent_Increase', 'Increase_Effective_Date',
        'Create_New_Invoice', 'Increase_to_Standard'],
    chargesAfterRentAdjustmentRequestSource: ['ViewRentAdjustment', 'RentAdjustmentCreate'],
    rentAdjustmentPeriod: ['months', 'days'],
};

export default enums;
