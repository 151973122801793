
const PATHS = {
    AUTO_PAYMENT: 'auto-pay',
    RENT_ASSESSMENT: 'ra',
    AUTO_INVOICING: 'auto-invoicing',
    DELINQUENCY: 'delinquency',
};

const INTERNAL_ROUTES = {
    INTERNAL_MAIN: 'i',
    FACILITY_LIST: 'facility/list',
    SCHEDULE_LIST: 'schedule/list',
    ASYNC_TASK_LIST: 'async-task/list',
    ACCOUNT_MANAGEMENT: 'account-management',
    BUSINESS_GROUP_MANAGEMENT: 'business-group-management',
    ACCOUNT_LIST: 'account/list',
    USER_LIST: 'user/list',
    DAILY_SUMMARY_LIST: 'daily-summary/list',
    AUTO_PAYMENT_TASK_SUMMARY: `${PATHS.AUTO_PAYMENT}/:summaryId/summary`,
    RENT_ASSESSMENT_TASK_SUMMARY: `${PATHS.RENT_ASSESSMENT}/:summaryId/summary`,
    DELINQUENCY_TASK_SUMMARY: `${PATHS.DELINQUENCY}/:summaryId/summary`,
    SAVED_DOCUMENTS_LIST: 'saved-documents/list',
    AUTO_INVOICING_TASK_SUMMARY: `${PATHS.AUTO_INVOICING}/:summaryId/summary`,
    EDIT_FACILITY: 'facility/:facilityId/edit',
    CREATE_FACILITY: 'facility/create',
    EDIT_ACCOUNT: 'account/:accountId/edit',
    CREATE_ACCOUNT: 'account/create',
    EDIT_USER: 'user/:userId/edit',
    CREATE_USER: 'user/create',
    DELINQUENCY_TRACKING_OVERVIEW: 'delinquency-tracking',
    JOURNAL: 'journal',
    SYRASOFT_USER_ROLES: 'syrasoft-user-roles',
    EDIT_SYRASOFT_USER_ROLE: 'syrasoft-user-roles/:userType/edit',
    USER_PROFILE: 'user-profile/:userId',
    SANDBOX: 'sandbox',
    MIGRATION: 'migration',
    MIGRATION_DETAILS: ':migrationId/migration-details',
    FACILITY_VIEW: 'account/:accountId/facility/:fid/view',
    USER_SETUP: 'account/:accountId/user/list',
    EDIT_USER_SET_UP: 'account/:accountId/user/:userId/edit',
    CREATE_USER_SET_UP: 'account/:accountId/user/create',
    ACCOUNT_FACILITY_LIST: 'account/:accountId/facility/list',
    BUSINESS_GROUP_LIST: 'account/:accountId/bg/list',
    BG_LIST: 'bg/list',
    FACILITY_CREATE_FROM_ACCOUNT: 'account/:accountId/facility/create',
    FACILITY_EDIT_FROM_ACCOUNT: 'account/:accountId/facility/:facilityId/edit',
};

export default INTERNAL_ROUTES;

export { PATHS as INTERNAL_PATHS };
