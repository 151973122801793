import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapRelationalData } from 'Commons/redux/helper/RelationalDataMap';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { relationalData: { tenant = [] } = {} } = {} } = processedData;
    const ledgerMap = {};
    tenant.forEach((eachTenant) => {
        const { ledger = [] } = eachTenant;
        ledger.forEach((eachLedger) => {
            const { id } = eachLedger;
            ledgerMap[id] = { ...eachLedger, tenant: eachTenant };
        });
    });

    const keys = [
        { dataKey: 'lateEventConfigId', relationalDataKey: 'lateEvent' },
        { dataKey: 'unitId', relationalDataKey: 'unit' },
    ];
    processedData = mapRelationalData(processedData, keys);

    processedData.data.relationalData.ledger = ledgerMap;
    const ledgerDataKeys = [{ dataKey: 'ledgerId', relationalDataKey: 'ledger' }];
    processedData = mapRelationalData(processedData, ledgerDataKeys, true);
    return processedData;
};
