import STATUS from 'Commons/config/constants/StoreKeyStatus';
import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapRelationalData } from '../../../commons/redux/helper/RelationalDataMap';


export default (apiResult, callback, dispatch, localReqOptions) => {
    const { error, response = {} } = apiResult;
    let processedData = baseProcessor(apiResult, undefined, dispatch, localReqOptions);
    const keys = [{ dataKey: 'businessGroup', relationalDataKey: 'businessGroup' }];
    processedData = mapRelationalData(processedData, keys);
    const isQueued = response.status === STATUS.QUEUED;
    if (!isQueued && callback) {
        callback(error, processedData.data);
    }
    return processedData;
};
