import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { dateDiff } from 'Commons/helpers/utils/DateTime';
import { TIME_WINDOW } from 'Commons/config/constants/Constants';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions) || {};
    const { data: { data: emailData = [], relationalData = {}, ...restData } = {} } = processedData;
    const parentMailIds = [];
    const emails = [];
    emailData.forEach((email) => {
        const { createdDate, comments, additionalInfo: { externalId, references = [] } = {} } = email;
        if (!externalId) {
            emails.push({ comments, emails: [email], mostRecentEmailDate: createdDate });
        } else if (!references.length) {
            parentMailIds.push(externalId);
        }
    });
    parentMailIds.forEach((parentMailId) => {
        const emailsInAThread = [];
        let parentMailIdSubject = '';
        emailData.forEach((email) => {
            const { comments, additionalInfo: { externalId, references = [] } = {} } = email;
            if (externalId === parentMailId) {
                parentMailIdSubject = comments;
            }
            if (references.includes(parentMailId) || externalId === parentMailId) {
                emailsInAThread.push(email);
            }
        });
        emails.push({ comments: parentMailIdSubject, emails: emailsInAThread, mostRecentEmailDate: emailsInAThread[0].createdDate });
    });
    emails.sort((a, b) => dateDiff(b.mostRecentEmailDate, a.mostRecentEmailDate, TIME_WINDOW.MILLISECONDS));
    processedData = { data: { data: emails, relationalData, ...restData } };
    return processedData;
};
