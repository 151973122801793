import { createEnumMap } from 'Commons/helpers/utils/Formatter';

const formatter = label => label.replace(/([A-Z])/g, match => `_${match}`).toUpperCase();
const MOVE_IN_PERMISSION_KEYS = createEnumMap(
    ['period', 'moveInDate', 'editSetupFee', 'waiveSetupFee', 'waiveDeposits', 'editDeposits',
        'prorate', 'billToDate', 'unitRateUp', 'unitRateDown'],
    formatter,
);

const RESERVATION_PERMISSION_KEYS = createEnumMap(['access', 'waveDeposit'], formatter);

export { MOVE_IN_PERMISSION_KEYS, RESERVATION_PERMISSION_KEYS, formatter };
