import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapRelationalData } from '../../../commons/redux/helper/RelationalDataMap';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    processedData = mapRelationalData(processedData);
    const { data: oldData, data: { data = [], relationalData: { unitType: unitTypeData = [], user: userData = [] } = {} } = {} } = processedData;
    const newData = data.map((eachData) => {
        const { createdBy: { id: userId } = {} } = eachData;
        let { interestedUnitTypes = [] } = eachData;
        if (interestedUnitTypes && interestedUnitTypes.length) {
            interestedUnitTypes = interestedUnitTypes.map(unitTypeId => unitTypeData[unitTypeId]);
        }
        return {
            ...eachData,
            interestedUnitTypes,
            createdBy: userId ? userData[userId] : {},
        };
    });
    processedData = {
        ...processedData,
        data: {
            ...oldData,
            data: newData,
        },
    };
    return processedData;
};
