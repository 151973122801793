/* eslint-disable no-underscore-dangle */
import { Component } from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { Provider as RollbarProvider } from '@rollbar/react';

// import { whyDidYouUpdate } from 'why-did-you-update';
import { SnackbarProvider } from 'notistack';
import { debounce } from 'Commons/helpers/utils/Utils';
import { DEFAULT_EXTERNAL_ROUTE } from 'Commons/redux/config/Constants';
import { ROLLBAR_CONFIG } from 'Commons/config/constants/ErrorTracking';
import RouterContext from 'Commons/contexts/RouterContext';
import { getRouter } from 'Commons/helpers/utils/RouteHelpers';
import Theme from './commons/theme/Theme';
import reducer from './commons/redux/reducers/reducers';
import withProvider from './commons/components/business/provider/hoc';
import { getCookie, deleteCookie } from './commons/redux/helper/CookieManager';
import {
    MuiThemeProvider,
    withStyles,
    RouterProvider,
} from './commons/components/generic/componentlibrary/components/Components';
import baseStyle from './commons/theme/styles/BaseStyle';
import newEventEmitter from './commons/helpers/EventEmitters';
import ErrorBoundaries from './commons/components/generic/errorboundaries/components/ErrorBoundaries';
import { screenMaxWidth, screenMinWidth } from './commons/config/constants/ScreenWidths';
import { getDeviceOSInfo } from './commons/helpers/deviceFingerprint';
import COOKIE_KEY from './commons/redux/config/CookieKey';

const createAStore = () => {
    if (process.env.MODE === 'DEV') {
        return createStore(
            reducer, /* preloadedState, */
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        );
    }
    return createStore(reducer);
};
const store = createAStore();
class App extends Component {
    constructor(props) {
        super(props);
        window.withProvider = withProvider;
        window.deviceInfo = {};
        this.HomePage = withProvider('HomePage', this.props, false);
        this.state = { isUserLoggedIn: !!getCookie(COOKIE_KEY.TOKEN) };
        this.eventEmitter = newEventEmitter;
        this.eventEmitter.subscribe('logout', () => {
            deleteCookie(COOKIE_KEY.TOKEN);
            deleteCookie(COOKIE_KEY.DEFAULT_FACILITY_ID);
            deleteCookie(COOKIE_KEY.PAYMENT_TOKEN);
            this.setState({ isUserLoggedIn: false }, () => {
                const { dispatch } = store;
                dispatch(
                    {
                        type: 'clear_store',
                        payload: {},
                        storeKey: {},
                    },
                );
            });
        });
        this.updateWindowDimensions();
        this.updateDeviceInfo();
        // if (process.env.MODE !== 'production') {
        //     whyDidYouUpdate(React);
        // }
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = debounce(() => {
        this.updateWindowDimensions();
        this.forceUpdate();
    }, 100);

    updateDeviceInfo = () => {
        const { deviceInfo } = window;
        deviceInfo.info = getDeviceOSInfo();
    }

    updateWindowDimensions = () => {
        const { deviceInfo } = window;
        deviceInfo.isMobile = window.innerWidth <= screenMaxWidth.mobile;
        deviceInfo.isPhablet = (screenMinWidth.phablet <= window.innerWidth)
            && (window.innerWidth < screenMaxWidth.phablet);
        deviceInfo.isTablet = (screenMinWidth.tablet <= window.innerWidth)
            && (window.innerWidth <= screenMaxWidth.tablet);
        deviceInfo.isDesktop = (screenMinWidth.laptop <= window.innerWidth);
    };

    setUserLoginState = ({ loginStatus, pathToRedirect = '' }) => {
        this.setState({ isUserLoggedIn: loginStatus, pathToRedirect });
    }

    render() {
        const { isUserLoggedIn, pathToRedirect = DEFAULT_EXTERNAL_ROUTE } = this.state;
        const redirectTo = isUserLoggedIn ? pathToRedirect : '/';
        const { HomePage, setUserLoginState } = this;
        const { classes, ...newProps } = this.props;

        const router = getRouter(isUserLoggedIn, newProps, redirectTo, HomePage, setUserLoginState);

        return (
            <RollbarProvider config={ROLLBAR_CONFIG}>
                <ErrorBoundaries useAnchorTag>
                    <Provider store={store}>
                        <div className={classes.base}>
                            <MuiThemeProvider theme={Theme}>
                                <SnackbarProvider
                                    maxSnack={3}
                                    classes={{ containerAnchorOriginBottomLeft: classes.bottomLeftSnackbarContainer }}
                                >
                                    <RouterContext.Provider value={{ router }}>
                                        <RouterProvider router={router} />
                                    </RouterContext.Provider>
                                </SnackbarProvider>
                            </MuiThemeProvider>
                        </div>
                    </Provider>
                </ErrorBoundaries>
            </RollbarProvider>
        );
    }
}

App.propTypes = { classes: PropTypes.object, deviceInfo: PropTypes.object };

export default withStyles(baseStyle)(App);
