/* eslint-disable import/prefer-default-export */
import handleError, { handleStatusError } from 'Commons/redux/error/ErrorHandler';
import STATUS from 'Commons/config/constants/StoreKeyStatus';
import { curQueue } from 'Commons/helpers/api/Queue';

let ledgerIds = {};
const onResolvedLedgerIds = (processedDeclines, { err, response, timestamp }) => {
    const { data: allDeclinesData = [], ...nestedData } = processedDeclines;

    curQueue.remove(timestamp);
    const declines = [];
    let customTotalCount = 0;
    if (err) {
        return {
            data: null,
            status: STATUS.ERROR,
        };
    }
    if (response) {
        const { totalCount = 0, data: tenants = [] } = response;
        customTotalCount = totalCount;
        if (totalCount > 0) {
            tenants.forEach((tenant) => {
                const { ledger: ledgers = [] } = tenant;
                ledgers.forEach((ledger = {}) => {
                    const { id } = ledger;
                    if (ledgerIds[id]) {
                        ledgerIds[id] = { tenant, ledger };
                    }
                });
            });
            allDeclinesData.forEach((decline) => {
                const { ledgerId = '' } = decline;
                const { tenant = {}, ledger = {} } = ledgerIds[ledgerId] || {};
                if (ledgerId) {
                    declines.push({ ...decline, tenant, ledger });
                } else {
                    declines.push(decline);
                }
            }, []);
        }
    }
    return { totalCount: customTotalCount, data: declines, ...nestedData };
};

const resolveCustomHeaders = (apiOptions, processCustomHeaders) => {
    if (processCustomHeaders) {
        const { api: processedApiOptions } = processCustomHeaders({ api: apiOptions });
        return processedApiOptions;
    }
    return apiOptions;
};

const resolveLedgerIds = async (passedLedgerIds = {}, api, processCustomHeaders) => {
    let resolveLedgerResults = {};
    if (Object.keys(passedLedgerIds).length > 0) {
        const resolveLedgerIdBody = {
            view: 'mini_list',
            filter: [{ terms: { ledger: { id: Object.keys(passedLedgerIds) } } }],
        };

        const resolveLedgerApiOptions = resolveCustomHeaders({
            methodType: 'POST',
            endPoint: 'tenant/search',
            body: resolveLedgerIdBody,
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        }, processCustomHeaders);
        resolveLedgerResults = await api({ api: resolveLedgerApiOptions });
    }
    return resolveLedgerResults;
};

const processDeclines = async (data, api, processCustomHeaders) => {
    /* Could have combined the processing of ledgerIds and unit types in one loop, since we already
    have a util to map relational data properly, it is being used here. Once we start getting the
    tenant data in relational data, traversing to get ledger ids will go away. Also, remove this
    comment once start getting tenant data in relational data */

    const { data: allDeclines = [], success, totalCount, ...nestedData } = data;
    if (success) {
        ledgerIds = allDeclines.reduce((result, { ledgerId }) => {
            if (ledgerId) {
                return {
                    ...result,
                    [ledgerId]: {},
                };
            }
            return result;
        }, {});
    }

    if (!Object.keys(ledgerIds).length) {
        return { data: allDeclines, success, ...nestedData };
    }

    const tenantData = await resolveLedgerIds(ledgerIds, api, processCustomHeaders);
    const declinesData = onResolvedLedgerIds(data, tenantData);
    declinesData.totalCount = totalCount;

    return declinesData;
};


// eslint-disable-next-line consistent-return
const automaticCreditCardDeclinesPostProcessor = async (apiResult, callback, dispatch, localReqOptions) => {
    const { api, processCustomHeaders } = localReqOptions;
    const { error: err, response, timestamp } = apiResult;
    curQueue.remove(timestamp);
    if (err) {
        handleError(err, callback);
        return handleStatusError(err, dispatch, localReqOptions);
    }
    const isQueued = response.status === STATUS.QUEUED;
    if (!isQueued && callback) {
        callback(err, response);
    }
    if (isQueued) {
        return {
            data: null,
            status: STATUS.LOADING,
        };
    }
    // custom process logic
    const declines = await processDeclines(response, api, processCustomHeaders);
    const { data = [], ...restDeclines } = declines;
    return {
        data: {
            ...response,
            data,
            ...restDeclines,
        },
        status: STATUS.LOADED,
    };
};

export default automaticCreditCardDeclinesPostProcessor;
