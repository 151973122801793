import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapRelationalData } from '../../../commons/redux/helper/RelationalDataMap';
import { mergeChildConfigWithParent } from './configSDPostProcessor';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { relationalData = {}, ...restInnerData } = {}, ...outerData } = processedData;
    const processedRelationalData = Object.keys(relationalData).reduce((acc, key) => ({
        ...acc,
        [key]: mergeChildConfigWithParent(relationalData[key]),
    }), {});
    processedData = { ...outerData, data: { ...restInnerData, relationalData: processedRelationalData } };
    const keys = [{ dataKey: 'chargeCategoryId', relationalDataKey: 'chargeCategory' }];
    processedData = mapRelationalData(processedData, keys);
    return processedData;
};
