import { createEnumMap } from 'Commons/helpers/utils/Formatter';
import Enums from './Enums';

const WALK_IN_CUSTOMER_ID = -1;
const WALK_IN_CUSTOMER_NAME = 'Walk-In';
const ACTION = createEnumMap(Enums.action);
const CUSTOMER_TYPE = createEnumMap(Enums.customerType);
const CALLING_SOURCE = createEnumMap(Enums.source);

// Cart
const MINIMUM_CART_WIDTH = 538;
const DEFAULT_CART_WIDTH = 710;
const DEFAULT_CART_HEIGHT = 300;
const POST_MOVE_IN_FOOTER_HEIGHT = 290;
const POS_FOOTER_HEIGHT = 272;
const CART_LEFT_RIGHT_PADDING = {
    desktop: 48,
    responsive: 32,
};
const CART_TOP_BOTTOM_PADDING = {
    desktop: 72,
    responsive: 32,
};
const SCAN_BARCODE_TIMEOUT = 100;

// CustomerProductList
const DEFAULT_LIST_WIDTH = 300;
const DEFAULT_LIST_HEIGHT = 400;
const HORIZONTAL_PADDING = 48;
const VERTICAL_PADDING = 496;
const CONTAINER_PADDING = {
    desktop: 44,
    responsive: 32,
};


export {
    WALK_IN_CUSTOMER_ID,
    WALK_IN_CUSTOMER_NAME,
    ACTION,
    CUSTOMER_TYPE,
    CALLING_SOURCE,
    MINIMUM_CART_WIDTH,
    DEFAULT_CART_WIDTH,
    DEFAULT_CART_HEIGHT,
    POST_MOVE_IN_FOOTER_HEIGHT,
    POS_FOOTER_HEIGHT,
    CART_LEFT_RIGHT_PADDING,
    DEFAULT_LIST_WIDTH,
    DEFAULT_LIST_HEIGHT,
    HORIZONTAL_PADDING,
    VERTICAL_PADDING,
    CONTAINER_PADDING,
    SCAN_BARCODE_TIMEOUT,
    CART_TOP_BOTTOM_PADDING,
};
