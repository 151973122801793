import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { toObject } from 'Commons/helpers/utils/DataHelpers';
import localisable from 'Commons/config/strings/localisable';
import { curQueue } from 'Commons/helpers/api/Queue';
import CONFIG_TYPE from 'External/containers/configuration/config/ConfigRequestType';
import { ENTITY_LEVEL } from 'Commons/config/constants/Constants';
import { CONFIG_ENDPOINTS, REPORT_WC_ENDPOINTS } from 'Commons/config/constants/Endpoints';

const resolveCustomHeaders = (apiOptions, processCustomHeaders) => {
    if (processCustomHeaders) {
        const { api: processedApiOptions } = processCustomHeaders({ api: apiOptions });
        return processedApiOptions;
    }
    return apiOptions;
};

const filterInsuranceVendor = (providerId, insuranceVendorResponse) => {
    const { response, timestamp } = insuranceVendorResponse;
    curQueue.remove(timestamp);
    if (response) {
        const { data: { Insurance_Provider: { data: vendors = [] } = {} } = {} } = response;
        const insuranceVendor = vendors.find(vendor => vendor.id === providerId.toString()) || {};
        return insuranceVendor;
    }
    return {};
};

const resolveInsuranceResponse = (insuranceConfig, insuranceVendors) => {
    const { response: configResponse, timestamp: configResponseTimestamp } = insuranceConfig;
    curQueue.remove(configResponseTimestamp);
    if (configResponse) {
        const {
            data: {
                Insurance: { data: [{ value: { providerId = '' } = {} } = {}] = [] }
                = {},
            } = {},
        } = configResponse;
        if (providerId) {
            return filterInsuranceVendor(providerId, insuranceVendors);
        }
    }
    return {};
};

const getInsuranceConfigApiOptions = (facilityId, processCustomHeaders) => {
    const insuranceConfigBody = {
        view: 'detail',
        filter: [{
            terms: {
                config_type: [CONFIG_TYPE.INSURANCE],
                entity_type: [ENTITY_LEVEL.Facility.value],
                entity_id: [facilityId],
            },
        }],
    };
    return resolveCustomHeaders({
        methodType: 'POST',
        endPoint: CONFIG_ENDPOINTS.search,
        body: insuranceConfigBody,
    }, processCustomHeaders);
};

const getInsuranceVendorApiOptions = (processCustomHeaders) => {
    const insuranceProviderConfigBody = {
        view: 'detail',
        filter: [{
            terms: {
                config_type: [CONFIG_TYPE.INSURANCE_PROVIDER],
                entity_type: [ENTITY_LEVEL.System_Facility.value],
            },
        }],
    };
    return resolveCustomHeaders({
        methodType: 'POST',
        endPoint: CONFIG_ENDPOINTS.search,
        body: insuranceProviderConfigBody,
        customHeaderOptions: {
            type: 'store',
            key: 'facility',
        },
    }, processCustomHeaders);
};

const getCategoryApiOptions = processCustomHeaders => resolveCustomHeaders({
    methodType: 'POST',
    baseUrl: process.env.REPORT_URL,
    endPoint: REPORT_WC_ENDPOINTS.category.search,
    body: {},
    customHeaderOptions: {
        type: 'store',
        key: 'facility',
    },
}, processCustomHeaders);

const getInsuranceCategoryReportsApiOptions = (insuranceCategoryId, processCustomHeaders) => resolveCustomHeaders({
    methodType: 'POST',
    baseUrl: process.env.REPORT_URL,
    endPoint: REPORT_WC_ENDPOINTS.report.search,
    body: { filter: [{ terms: { category: { id: [insuranceCategoryId] } } }] },
    customHeaderOptions: {
        type: 'store',
        key: 'facility',
    },
}, processCustomHeaders);

const fetchInsuranceConfig = async (facilityId, api, processCustomHeaders) => {
    // api options for fetching insurance configuration for facility
    const insuranceConfigApiOptions = getInsuranceConfigApiOptions(facilityId, processCustomHeaders);

    // api options for fetching insurance providers
    const insuranceProviderApiOptions = getInsuranceVendorApiOptions(processCustomHeaders);

    // doing parallel calls to save time, edge case (Facility doesn't have insurance provider but still we will call both)
    const [insuranceConfig, insuranceProviders] = await Promise.all(
        [
            api({ api: insuranceConfigApiOptions }),
            api({ api: insuranceProviderApiOptions }),
        ],
    );
    return resolveInsuranceResponse(insuranceConfig, insuranceProviders);
};

const fetchInsuranceCategoryReports = async (insuranceCategoryId, api, processCustomHeaders) => {
    const insuranceCategoryApiOptions = getInsuranceCategoryReportsApiOptions(insuranceCategoryId, processCustomHeaders);
    const insuranceCategoryReports = await api({ api: insuranceCategoryApiOptions });
    const { response, timestamp } = insuranceCategoryReports;
    curQueue.remove(timestamp);
    if (response) {
        const { data = [] } = response;
        return data;
    }
    return [];
};

const fetchInsuranceCategory = async (api, processCustomHeaders) => {
    const categoryApiOptions = getCategoryApiOptions(processCustomHeaders);
    const category = await api({ api: categoryApiOptions });
    const { response, timestamp } = category;
    curQueue.remove(timestamp);
    if (response) {
        const { data = [] } = response;
        const insuranceCategory = data.find(({ description }) => description === localisable.insurance) || {};
        const { id: insuranceCategoryId = '' } = insuranceCategory;
        return insuranceCategoryId.toString();
    }
    return '';
};

const resolveInsuranceVendorReports = (insuranceVendor, data, restData, others) => {
    const { value: { description } = {} } = insuranceVendor;
    const reports = data.filter(item => item.name.includes(description));
    return {
        data: {
            data: reports,
            dataObject: toObject(reports),
            ...restData,
        },
        ...others,
        totalCount: reports.length,
    };
};

export default async (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { data = [], ...others } = {}, ...restData } = processedData || {};
    const {
        api,
        processCustomHeaders,
        requestOptions: {
            body: {
                filter: [
                    { terms: { category: { id: [categoryId] = '' } = {} } = {} } = {},
                ] = [],
            } = {},
        } = {},
        storeData: {
            currentFacility: { data: { id: facilityId } = {} } = {},
            reportCategory: { data: { data: categoryData = [] } = {} } = {},
        } = {},
    } = localReqOptions;
    const insuranceCategory = categoryData.find(category => category.description === localisable.insurance) || {};
    const { id: insuranceCategoryId = '' } = insuranceCategory;
    if (categoryId === insuranceCategoryId.toString()) {
        const insuranceVendor = await fetchInsuranceConfig(facilityId, api, processCustomHeaders);
        return resolveInsuranceVendorReports(insuranceVendor, data, restData, others, categoryId);
    }
    if (!categoryId) {
        const insuranceCategoryIdForAllReports = await fetchInsuranceCategory(api, processCustomHeaders);
        if (insuranceCategoryIdForAllReports) {
            const insuranceReports = await fetchInsuranceCategoryReports(insuranceCategoryIdForAllReports, api, processCustomHeaders);
            const insuranceReportsId = insuranceReports.map(({ id }) => id.toString());
            const insuranceVendor = await fetchInsuranceConfig(facilityId, api, processCustomHeaders);
            const { value: { description } = {} } = insuranceVendor;
            const filteredReports = data.filter(({ id, name }) => (insuranceReportsId.includes(id.toString()) ? name.includes(description) : true));
            return {
                data: {
                    data: filteredReports,
                    dataObject: toObject(filteredReports),
                    ...restData,
                },
                ...others,
                totalCount: filteredReports.length,
            };
        }
    }
    return {
        data: {
            data,
            dataObject: toObject(data),
            ...restData,
        },
        ...others,
    };
};
