const enums = {
    searchType: [
        'Configuration_Changes',
        'Late_Events',
        'Lockout_Events',
        'User_Role',
        'User_Management',
    ],
    lockoutEventType: ['Unit_Overlock', 'Gate_Lockout', 'Tenant_Portal_Lockout'],
    gateAccessType: ['Ledger_Gate_Access', 'Unit_Gate_Access', 'Unit_Overlock_Status'],
    userManagementCategory: ['Standard_Permission', 'User'],
};
export default enums;
