import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapIdsByAccessCode } from '../utils/AccessCodeProcessingHelper';

export default (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { data = [], ...innerData } = {}, ...outerData } = processedData;
    const mappedData = {};
    const relationalData = {};
    data.forEach(({ ledger = [], id }) => {
        ledger.forEach(({ id: ledgerId, gateAccess: { code } = {} }) => {
            mapIdsByAccessCode(mappedData, code, id);
            relationalData[ledgerId] = code;
        });
    });
    return { ...outerData, data: { ...innerData, data: mappedData, relationalData } };
};
