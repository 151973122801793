import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { STATUS } from 'Commons/config/constants/Constants';
import RequestTypes from 'Commons/config/constants/RequestTypes';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions) || {};
    const { storeData: { inventoryList: { activeInventoryList = [] } = {} } = {}, requestType } = localReqOptions;
    const { data = {} } = processedData;
    const activeInventoryListData = requestType === RequestTypes.PAGINATE ? activeInventoryList : [];
    if (data) {
        const { data: inventory = [], relationalData: { vendor = [] } = {}, ...restData } = data;
        const inventoryData = inventory.map((inventoryItem) => {
            const {
                id: vendorId,
                status: vendorStatus,
                ...rest
            } = vendor.find(({ id }) => id === inventoryItem.vendorId) || {};
            const mergedInventoryItem = { ...inventoryItem, vendorStatus, ...rest };
            if (inventoryItem.status === STATUS.Active.value && vendorStatus === STATUS.Active.value) {
                activeInventoryListData.push(mergedInventoryItem);
            }
            return mergedInventoryItem;
        });
        processedData = {
            ...processedData,
            data: { data: inventoryData, ...restData },
            activeInventoryList: activeInventoryListData,
        };
    }
    return processedData;
};
