import { SEVERITY_LEVEL } from '../config/Constants';

const formatterStyle = theme => ({
    indicator: {
        fontSize: '0.5rem',
        cursor: 'default',
    },
    required: { color: theme.palette.error.main },
    disabled: { color: theme.palette.text.disabled },
    input: ({ isTenantAlert } = {}) => ({
        textAlign: isTenantAlert ? 'center' : 'flex-start',
        height: '100%',
        paddingBottom: theme.spacing(0.375),
        paddingTop: isTenantAlert ? theme.spacing(1) : theme.spacing(1.25),
    }),
    dropdown: { width: '100%' },
    listItem: { padding: theme.spacing(1) },
    iconColor: ({ value = SEVERITY_LEVEL.Warning.value } = {}) => ({ color: theme.palette[value.toLowerCase()].icon }),
    topMargin: { marginTop: theme.spacing(0.5) },
    checkBoxLabel: { padding: theme.spacing(0, 1) },
    icon: { cursor: 'default' },
});

export default formatterStyle;
