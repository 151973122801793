import STATUS from 'Commons/config/constants/StoreKeyStatus';
import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

export default (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const data = [];
    const { data: allProcessedData, data: { data: tenants = [], totalCount: resTotalCount = 0 } = {} } = processedData;

    const {
        storeData:
         {
             ccAboutToExpire:
            { data: { actualRows = 0, totalCount = 0 } = {} } = {},
         } = {},
    } = localReqOptions || {};
    let newTotalCount = totalCount || resTotalCount;

    tenants.forEach((tenant) => {
        const { ledger = [] } = tenant || {};
        if (ledger.length > 1) {
            ledger.forEach((ledgerData) => {
                const { balance = 0 } = ledgerData;
                data.push({ ...tenant, ledger: [ledgerData], balance });
            });
        } else {
            const [{ balance = 0 }] = ledger;
            data.push({ ...tenant, balance, ledger });
        }
        newTotalCount += (ledger.length - 1);
    });
    processedData.data.data = data;
    return {
        data: {
            ...allProcessedData,
            data,
            totalCount: newTotalCount,
            actualRows: actualRows + tenants.length,
        },
        status: STATUS.LOADED,
    };
};
