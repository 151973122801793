import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapRelationalData } from 'Commons/redux/helper/RelationalDataMap';
import { CUSTOMER_TYPE } from 'Commons/components/business/filtersAndSorts/components/waitingListFilter/config/Constants';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { relationalData: { tenant: tenants = [] } = {} } = {} } = processedData;
    const tenantData = {};
    const ledgerData = {};
    tenants.forEach((tenant) => {
        const { ledger: ledgers = [] } = tenant;
        ledgers.forEach(({ id: ledgerId, ...ledger }) => {
            tenantData[ledgerId] = tenant;
            ledgerData[ledgerId] = { id: ledgerId, ...ledger };
        });
    });
    const keys = [{ dataKey: 'unitTypeId', relationalDataKey: 'unitType' }];
    processedData = mapRelationalData(processedData, keys);
    const { data: { data = {}, relationalData: { lead = {} } = {} } = {} } = processedData;
    const newData = data.map((eachData) => {
        const { customerId, customerType } = eachData;
        return {
            ...eachData,
            ...(customerType === CUSTOMER_TYPE.Tenant.value ? {
                tenant: tenantData[customerId],
                ledger: ledgerData[customerId],
            }
                : { lead: lead[customerId] }),
        };
    });
    processedData.data.data = newData;
    return processedData;
};
