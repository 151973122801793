import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapRelationalData } from 'Commons/redux/helper/RelationalDataMap';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { relationalData: { tenant: tenants = {} } } = {} } = processedData;
    const tenantData = {};
    const ledgerData = {};
    tenants.forEach((tenant) => {
        const { ledger: ledgers = [] } = tenant;
        ledgers.forEach(({ id: ledgerId, ...ledger }) => {
            tenantData[ledgerId] = tenant;
            ledgerData[ledgerId] = { id: ledgerId, ...ledger };
        });
    });
    const keys = [{ dataKey: 'unit.id', relationalDataKey: 'unit' }];
    processedData = mapRelationalData(processedData, keys);
    const { data: { data = {}, relationalData: { unitType = {} } = {} } } = processedData;
    const newData = data.map((eachData) => {
        const { ledger: { id: ledgerId = '' } = {}, value: { unitTypeId = '' } = {} } = eachData;
        const tenant = tenantData[ledgerId];
        return {
            ...eachData,
            tenant,
            email: tenant.email || {},
            phone: tenant.phone || {},
            ledger: ledgerData[ledgerId],
            unitType: unitType[unitTypeId],
        };
    });
    processedData.data.data = newData;
    return processedData;
};
