import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

const mapTenantData = (processedUnits, tenantData) => {
    const { data: { data: allUnitsData = [], ...nestedData } = {} } = processedUnits;
    const tenantLookup = {};
    tenantData.forEach((tenant) => {
        tenant.ledger.forEach((ledger) => {
            tenantLookup[ledger.id] = tenant;
        });
    });

    const unitDataWithTenants = allUnitsData.map((unit) => {
        const { ledger: { id } = {} } = unit || {};
        const tenant = id && tenantLookup[id];
        const updatedUnit = { ...unit };
        if (tenant) updatedUnit.tenant = tenant;
        if (tenant && tenant.phone) updatedUnit.phone = tenant.phone;
        if (tenant && tenant.email) updatedUnit.email = tenant.email;
        return updatedUnit;
    });
    return { data: unitDataWithTenants, ...nestedData };
};

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const {
        data: { relationalData: { tenant: unMappedTenantData = [] } = {} } = {},
        ...outerData
    } = processedData;

    processedData = mapTenantData(processedData, unMappedTenantData);

    return {
        ...outerData,
        data: { ...processedData },
    };
};
