import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { SETUP_STATUS } from 'Commons/config/constants/Constants';

export default (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { relationalData, ...data } = {}, data: { data: facilityData = [] } = {} } = processedData;
    const { missingConfigs = {}, facilityGeneral = {} } = relationalData || {};
    const processedFacilityData = facilityData.map(({ id, ...facility }) => ({
        id,
        ...facility,
        setupStatus: missingConfigs[id].length ? SETUP_STATUS.INCOMPLETE : SETUP_STATUS.COMPLETE,
        ...facilityGeneral[id] || {},
    }));
    return {
        ...processedData,
        data: {
            ...data,
            relationalData,
            data: processedFacilityData,
        },
    };
};
