import CONFIG_TYPE from 'External/containers/configuration/config/ConfigRequestType';

export default {
    chargeCategory: [{
        path: 'taxes',
        configType: CONFIG_TYPE.TAX_CODE,
    }],
    taxCode: [{
        path: 'chargeCategory',
        configType: CONFIG_TYPE.CHARGE_CATEGORY,
    }],
    taxExemptCategory: [{
        path: 'customExemptList',
        configType: CONFIG_TYPE.TAX_CODE,
    }],
    unitType: [{
        path: 'rate.chargeCategory',
        configType: CONFIG_TYPE.CHARGE_CATEGORY,
    }],
    maintenanceEvent: [{
        path: 'billing.chargeCategory',
        configType: CONFIG_TYPE.CHARGE_CATEGORY,
    }],
    leaseStyle: [
        {
            path: 'leaseDocument',
            configType: CONFIG_TYPE.LETTER,
        },
        {
            path: 'addendum',
            configType: CONFIG_TYPE.LETTER,
        },
    ],
    // Dummy entry to handle config inheritance. Need to refactor the code to handle for configs without dependents
    paymentMethod: [{
        path: 'taxes',
        configType: CONFIG_TYPE.TAX_CODE,
    }],
};
