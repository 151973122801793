// TODO We can move all external permission to common or remove the external and unused keys.
import { MOVE_IN_PERMISSION_KEYS, RESERVATION_PERMISSION_KEYS } from
    'External/containers/createoccupancy/config/PermissionKeys';
import { UNIT_PERMISSION_KEYS } from 'External/containers/unit/form/config/UnitPermissionKeys';
import { AUDIT_PERMISSION_KEYS } from 'External/containers/inventory/audit/config/AuditPermissionKeys';
import { INVENTORY_PERMISSION_KEYS } from 'External/containers/inventory/list/config/InventoryPermissionKeys';
import { MANUAL_CHARGE_PERMISSION_KEYS } from 'External/containers/manualForm/config/PermissionKeys';
import { TENANT_PERMISSION_KEYS } from 'External/containers/tenant/form/config/PermissionKeys';
import MOVE_OUT_PERMISSION_KEYS from 'External/containers/moveout/config/PermissionKeys';
import MAINTENANCE_EVENTS_PERMISSION_KEYS from
    'External/containers/operations/components/maintenanceEvents/config/PermmissionKeys';
import {
    USER_MANAGEMENT_PERMISSION_KEY,
    BUSINESS_GROUP_MANAGEMENT_PERMISSION_KEY,
    FACILITY_MANAGEMENT_PERMISSION_KEY,
} from 'Commons/components/business/user/config/Constants';
import PAYMENT_PERMISSION_KEYS from 'Commons/components/business/payment/config/PermissionKeys';
import CHARGE_PERMISSION_KEYS from 'External/containers/ledgerreview/config/PermissionKeys';
import { REPORT_PERMISSION_KEYS } from 'External/containers/report/config/PermissionKeys';
import { SYRAPAY_MERCHANT_PORTAL_PERMISSION } from 'External/containers/facilityConfiguration/config/PermissionKeys';
import { RENT_ADJUSTMENT_KEYS } from 'External/containers/operations/components/rentAdjustment/config/PermissionKey';
import LATE_EVENT_PERMISSION_KEYS from 'External/containers/operations/components/lateEvent/PermissionKeys';

export default {
    moveIn: ['facility.occupancy.moveIn.access'],
    rentAssessment: ['facility.occupancy.rentAssessment.access'],
    moveOut: ['facility.occupancy.moveOut.access'],
    [PAYMENT_PERMISSION_KEYS.PAYMENT]: ['facility.financials.payment.access'],
    [PAYMENT_PERMISSION_KEYS.EFFECTIVE_DATE]: ['facility.financials.payment.editEffectiveDate'],
    [PAYMENT_PERMISSION_KEYS.NEGATIVE_PAYMENT]: ['facility.financials.payment.negativeAmount'],
    [PAYMENT_PERMISSION_KEYS.REFUNDS]: ['facility.financials.refunds'],
    [PAYMENT_PERMISSION_KEYS.DELETE_PAYMENT]: ['facility.financials.payment.delete'],
    [PAYMENT_PERMISSION_KEYS.REVERSE_PAYMENT]: ['facility.financials.payment.reverse'],
    [MOVE_IN_PERMISSION_KEYS.PERIOD]: ['facility.occupancy.moveIn.nonStandardPeriods'],
    [MOVE_IN_PERMISSION_KEYS.MOVE_IN_DATE]: ['facility.occupancy.moveIn.editMoveInDate'],
    [MOVE_IN_PERMISSION_KEYS.EDIT_SETUP_FEE]: ['facility.occupancy.moveIn.editSetupFee'],
    [MOVE_IN_PERMISSION_KEYS.WAIVE_SETUP_FEE]: ['facility.occupancy.moveIn.waiveSetupFee'],
    [MOVE_IN_PERMISSION_KEYS.EDIT_DEPOSITS]: ['facility.occupancy.moveIn.editDeposits'],
    [MOVE_IN_PERMISSION_KEYS.WAIVE_DEPOSITS]: ['facility.occupancy.moveIn.waiveDeposits'],
    [MOVE_IN_PERMISSION_KEYS.PRORATE]: ['facility.occupancy.moveIn.waiveProrate'],
    [MOVE_IN_PERMISSION_KEYS.BILL_TO_DATE]: ['facility.occupancy.moveIn.billToDate'],
    [MOVE_IN_PERMISSION_KEYS.UNIT_RATE_UP]: ['facility.occupancy.moveIn.unitRateUp'],
    [MOVE_IN_PERMISSION_KEYS.UNIT_RATE_DOWN]: ['facility.occupancy.moveIn.unitRateDown'],
    [UNIT_PERMISSION_KEYS.UNIT_GENERAL]: ['facility.unit.edit.general'],
    [UNIT_PERMISSION_KEYS.CURRENT_RATE]: ['facility.unit.edit.currentRate'],
    [UNIT_PERMISSION_KEYS.NEXT_DATE]: ['facility.unit.edit.nextRentAssessmentDate'],
    [UNIT_PERMISSION_KEYS.PAID_TO_DATE]: ['facility.unit.edit.paidToDate'],
    [UNIT_PERMISSION_KEYS.UNIT_TYPE]: ['facility.unit.edit.unitType'],
    [UNIT_PERMISSION_KEYS.INVENTORY_REPORT]: ['facility.unit.edit.inventoryReport'],
    [UNIT_PERMISSION_KEYS.UNIT_MOVE_IN_DATE]: ['facility.unit.edit.moveInDate'],
    [UNIT_PERMISSION_KEYS.RENTAL_STATUS]: ['facility.unit.edit.rentalStatus'],
    [AUDIT_PERMISSION_KEYS.NEGATIVE_INVENTORY]: ['facility.retailSale.negativeInventory'],
    [RESERVATION_PERMISSION_KEYS.WAVE_DEPOSIT]: ['facility.reservation.waiveDeposit'],
    [INVENTORY_PERMISSION_KEYS.NEGATIVE_SALE]: ['facility.retailSale.negativeSale'],
    [INVENTORY_PERMISSION_KEYS.INVENTORY_AUDIT]: ['facility.retailSale.inventoryAudit'],
    [INVENTORY_PERMISSION_KEYS.EDIT_INVENTORY]: ['facility.retailSale.editInventory'],
    [INVENTORY_PERMISSION_KEYS.RESTOCK]: ['facility.retailSale.restock'],
    [INVENTORY_PERMISSION_KEYS.SELL]: ['facility.retailSale.sell'],
    [INVENTORY_PERMISSION_KEYS.ADD_INVENTORY_AT_POS]: ['facility.retailSale.addInventoryAtPos'],
    [CHARGE_PERMISSION_KEYS.REVERSE_CHARGE]: ['facility.financials.charge.reverse.access'],
    [CHARGE_PERMISSION_KEYS.DELETE_CHARGE]: ['facility.financials.charge.delete.access'],
    [CHARGE_PERMISSION_KEYS.REVERSE_SECURE_CHARGE]: ['facility.financials.charge.reverse.secureCategory'],
    [CHARGE_PERMISSION_KEYS.DELETE_SECURE_CHARGE]: ['facility.financials.charge.delete.secureCategory'],
    [MANUAL_CHARGE_PERMISSION_KEYS.MANUAL_CHARGE]: ['facility.financials.charge.manualCharge.access'],
    [MANUAL_CHARGE_PERMISSION_KEYS.NEGATIVE_MANUAL_CHARGE]: ['facility.financials.charge.manualCharge.negativeAmount'],
    [MANUAL_CHARGE_PERMISSION_KEYS.MANUAL_CHARGE_SECURE_CATEGORIES]: ['facility.financials.charge.manualCharge.secureCategory'],
    [MANUAL_CHARGE_PERMISSION_KEYS.EDIT_DEPOSIT]: ['facility.tenant.edit.deposit'],
    [TENANT_PERMISSION_KEYS.CONTACT_LOG]: ['facility.tenant.contactLog'],
    [TENANT_PERMISSION_KEYS.ALLOW_SECURE_INFO]: ['facility.tenant.edit.allowSecureInfo'],
    [TENANT_PERMISSION_KEYS.ALLOW_LATE_EXEMPTION]: ['facility.tenant.edit.allowLateExemption'],
    [TENANT_PERMISSION_KEYS.STATUS]: ['facility.tenant.edit.status'],
    [TENANT_PERMISSION_KEYS.TENANT_GENERAL]: ['facility.tenant.edit.general'],
    [TENANT_PERMISSION_KEYS.VIEW_SECURE_INFO]: ['facility.tenant.viewSecureInfo'],
    [MOVE_OUT_PERMISSION_KEYS.MOVE_OUT_DATE]: ['facility.occupancy.moveOut.editMoveOutDate'],
    [MOVE_OUT_PERMISSION_KEYS.OVERRIDE_RESOLVE_CHARGES]: ['facility.occupancy.moveOut.overrideResolveCharges'],
    [MOVE_OUT_PERMISSION_KEYS.WAIVE_NOTIFICATION_PENALTY]: ['facility.occupancy.moveOut.waiveNotificationPenalty'],
    [MAINTENANCE_EVENTS_PERMISSION_KEYS.CLOSE_EVENT]: ['facility.maintenanceEvent.close'],
    [MAINTENANCE_EVENTS_PERMISSION_KEYS.CANCEL_EVENT]: ['facility.maintenanceEvent.cancel'],
    [USER_MANAGEMENT_PERMISSION_KEY]: ['account.setup.userManagement'],
    [BUSINESS_GROUP_MANAGEMENT_PERMISSION_KEY]: ['account.setup.businessGroupManagement'],
    [FACILITY_MANAGEMENT_PERMISSION_KEY]: ['account.setup.facilityManagement'],
    [REPORT_PERMISSION_KEYS.ACCOUNT_CONSOLIDATED_REPORT]: ['account.reports.consolidatedReports'],
    [REPORT_PERMISSION_KEYS.BUSINESS_GROUP_CONSOLIDATED_REPORT]: ['businessGroup.reports.consolidatedReports'],
    [REPORT_PERMISSION_KEYS.FACILITY_CONSOLIDATED_REPORT]: ['facility.reports.consolidatedReports'],
    [RENT_ADJUSTMENT_KEYS.SCHEDULE]: ['facility.occupancy.rentAdjustment.schedule'],
    [RENT_ADJUSTMENT_KEYS.WAIVE]: ['facility.occupancy.rentAdjustment.waive'],
    [SYRAPAY_MERCHANT_PORTAL_PERMISSION]: [
        'facility.financials.payment.syrapayMerchantPortal.pciManagement',
        'facility.financials.payment.syrapayMerchantPortal.reporting',
        'facility.financials.payment.syrapayMerchantPortal.reverseOpenTransactions',
        'facility.financials.payment.syrapayMerchantPortal.reverseSettledTransactions',
        'facility.financials.payment.syrapayMerchantPortal.terminalManagement',
        'facility.financials.payment.syrapayMerchantPortal.transactionSearch',
    ],
    [LATE_EVENT_PERMISSION_KEYS.ASSESS_LATE_CHARGES]: ['facility.lateEvents.assessLateCharges'],
};
