import { createEnumMap } from 'Commons/helpers/utils/Formatter';

const formatter = label => label.replace(/([A-Z])/g, match => `_${match}`).toUpperCase();
const RENT_ADJUSTMENT_KEYS = createEnumMap(
    ['schedule', 'waive'],
    formatter,
);


export { RENT_ADJUSTMENT_KEYS };
