import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapRelationalData } from 'Commons/redux/helper/RelationalDataMap';
import { isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { relationalData: { tenant = [], unit = [] } = {} } = {} } = processedData;
    const ledgerMap = {};

    tenant.forEach((eachTenant) => {
        const { ledger = [] } = eachTenant;
        ledger.forEach((eachLedger) => {
            const { id } = eachLedger;
            ledgerMap[id] = { ...eachLedger, tenant: eachTenant };
        });
    });

    unit.forEach((eachUnit) => {
        const { ledger: { id: ledgerId } = {} } = eachUnit;
        const { [ledgerId]: { units = [], ...ledgerData } = {} } = ledgerMap;
        ledgerMap[ledgerId] = { ...ledgerData, units: [...units, eachUnit] };
    });

    if (isObjWithKeys(ledgerMap)) processedData.data.relationalData.ledger = ledgerMap;
    const ledgerDataKeys = [{ dataKey: 'ledgerId', relationalDataKey: 'ledger' }];
    processedData = mapRelationalData(processedData, ledgerDataKeys, true);
    return processedData;
};
