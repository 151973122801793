import { SEARCH_MAX_SIZE, VIEW } from 'Commons/config/constants/Constants';
import {
    ACCOUNT_ENDPOINTS,
    BUSINESS_GROUP_ENDPOINTS,
    DELINQUENCY_ENDPOINTS,
    DOCUMENT_ENDPOINTS,
    FACILITY_ENDPOINTS,
    JOURNAL_ENDPOINTS,
    OPERATIONS_ENDPOINTS,
    SANDBOX_ENDPOINTS,
    USER_ENDPOINTS,
} from 'Commons/config/constants/Endpoints';
import {
    DELINQUENCY_TRACKING_LATE_EVENT_STORE,
    DELINQUENCY_TRACKING_LOCKOUT_EVENT_STORE,
    DELINQUENCY_TRACKING_SCHEDULED_EVENT_STORE,
    DELINQUENCY_TRACKING_OVERVIEW_STORE,
    DELINQUENCY_TRACKING_WRONG_ENTRY_STORE,
} from 'Internal/containers/delinquencyTracking/list/config/Constants';
import {
    JOURNAL_CONFIGURATION_CHANGES_STORE,
    JOURNAL_LATE_EVENT_STORE,
    JOURNAL_LOCKOUT_EVENT_STORE,
    JOURNAL_USER_MANAGEMENT_STORE,
    JOURNAL_USER_ROLE_STORE,
} from 'Internal/containers/journal/list/config/Constants';

const delinquencyEvents = {
    read: {
        methodType: 'POST',
        endPoint: OPERATIONS_ENDPOINTS.lateEvent,
        body: { view: VIEW.list.value },
    },
};

const journal = {
    read: {
        methodType: 'POST',
        endPoint: JOURNAL_ENDPOINTS.search,
        body: {
            view: VIEW.list.value,
            includeRelations: { User: {} },
        },
    },
};

const ActionConfig = {
    missingSchedulers: {
        read: {
            methodType: 'POST',
            endPoint: DELINQUENCY_ENDPOINTS.searchMissingSchedulers,
        },
    },
    userRole: {
        read: {
            methodType: 'GET',
            endPoint: 'user-role/user-type',
            customHeaderOptions: {
                type: 'store',
                key: 'account',
            },
        },
        create: {
            methodType: 'POST',
            endPoint: 'user-role',
            customHeaderOptions: {
                type: 'store',
                key: 'account',
            },
        },
    },
    userProfile: {
        create: {
            methodType: 'POST',
            endPoint: 'user/',
        },
        read: {
            methodType: 'GET',
            endPoint: 'user',
        },
        update: {
            methodType: 'PUT',
            endPoint: 'user/edit',
        },
        delete: {
            methodType: 'DELETE',
            endPoint: 'user/remove',
        },
    },
    businessGroups: {
        read: {
            methodType: 'POST',
            endPoint: BUSINESS_GROUP_ENDPOINTS.search,
            body: { view: VIEW.detail.value },
        },
        update: {
            methodType: 'PUT',
            endPoint: 'businessgroup/edit',
        },
    },
    facilities: {
        read: {
            methodType: 'POST',
            endPoint: FACILITY_ENDPOINTS.search,
            body: {
                view: VIEW.list.value,
                includeRelations: { Missing_Configs: {} },
            },
        },
        update: {
            methodType: 'PUT',
            endPoint: 'facility/edit',
        },
        delete: {
            methodType: 'DELETE',
            endPoint: 'facility/remove',
        },
    },
    allFacilities: {
        read: {
            methodType: 'POST',
            endPoint: FACILITY_ENDPOINTS.searchAll,
            body: {
                view: VIEW.list.value,
                size: 1000,
            },
        },
    },
    account: {
        read: {
            methodType: 'POST',
            endPoint: ACCOUNT_ENDPOINTS.search,
            customHeaderOptions: {
                type: 'store',
                key: 'account',
            },
        },
    },
    accounts: {
        read: {
            methodType: 'POST',
            endPoint: ACCOUNT_ENDPOINTS.search,
            body: {
                view: VIEW.detail.value,
                size: SEARCH_MAX_SIZE,
            },
            // NOTE: Uncomment this if you want to test via default, and onInit
            /* customHeaderOptions: {
                type: 'default',
                context: {
                    type: CustomHeaders.ACCOUNT,
                    id: 'accountId',
                },
            }, */
            // NOTE: Uncomment this if you want to test via store, and onInit
            /* customHeaderOptions: {
                type: 'store',
                key: 'accounts',
            }, */
        },
        update: {
            methodType: 'PUT',
            endPoint: 'account/edit',
        },
        delete: {
            methodType: 'DELETE',
            endPoint: 'account/remove',
        },
    },
    tenant: {
        read: {
            methodType: 'GET',
            endPoint: 'tenant/search',
            body: { view: VIEW.detail.value },
        },
    },
    user: {
        read: {
            methodType: 'POST',
            endPoint: 'user/search',
            body: { view: VIEW.detail.value },
        },
    },
    allUsers: {
        read: {
            methodType: 'POST',
            endPoint: USER_ENDPOINTS.searchAll,
            body: {
                view: VIEW.detail.value,
                size: 1000,
            },
        },
    },
    rentAssessmentHistory: {
        read: {
            methodType: 'POST',
            endPoint: 'rental/assessment/history/search',
            body: {
                view: VIEW.list.value,
                sort: [
                    { created_date: { order: 'desc' } },
                ],
            },
        },
    },
    dailyTaskSummary: {
        read: {
            methodType: 'POST',
            endPoint: 'scheduler/daily-summary',
            body: {
                view: VIEW.detail.value,
                sort: [{ scheduledDate: { order: 'desc' } }],
            },
        },
    },
    savedDocuments: {
        read: {
            methodType: 'POST',
            endPoint: DOCUMENT_ENDPOINTS.searchForInternalConsole,
            body: {
                view: VIEW.list.value,
                sort: [{ createdDate: { order: 'desc' } }],
                includeRelations: { Tenant: {} },
            },
        },
    },
    schedules: {
        read: {
            methodType: 'POST',
            endPoint: 'scheduler/search',
            body: {
                view: VIEW.list.value,
                sort: [
                    { id: { order: 'desc' } },
                ],
            },
        },
    },
    asyncTaskList: {
        read: {
            methodType: 'POST',
            endPoint: 'tasks/result/search',
            body: {
                view: VIEW.list.value,
                sort: [
                    { date_done: { order: 'desc' } },
                ],
            },
        },
    },
    sandbox: {
        read: {
            methodType: 'POST',
            endPoint: SANDBOX_ENDPOINTS.search,
            body: {
                view: VIEW.detail.value,
                size: 100,
            },
        },
    },
    userRoleAction: {
        read: {
            methodType: 'POST',
            endPoint: 'user-role/search',
            body: { view: 'detail' },
            customHeaderOptions: {
                type: 'store',
                key: 'account',
            },
        },
    },
    businessGroup: {
        read: {
            methodType: 'POST',
            endPoint: BUSINESS_GROUP_ENDPOINTS.search,
            body: { view: 'detail' },
            customHeaderOptions: {
                type: 'store',
                key: 'account',
            },
        },
    },
    permissionRoles: {
        read: {
            methodType: 'POST',
            endPoint: 'user/permission/search',
        },
    },
    [DELINQUENCY_TRACKING_LATE_EVENT_STORE]: { ...delinquencyEvents },
    [DELINQUENCY_TRACKING_LOCKOUT_EVENT_STORE]: { ...delinquencyEvents },
    [DELINQUENCY_TRACKING_SCHEDULED_EVENT_STORE]: {
        read: {
            methodType: 'POST',
            endPoint: DELINQUENCY_ENDPOINTS.searchSchedulers,
            body: {
                view: VIEW.list.value,
                includeRelations: { Tenant: {}, Unit: {}, Late_Event: {} },
            },
        },
    },
    [DELINQUENCY_TRACKING_OVERVIEW_STORE]: {
        read: {
            methodType: 'POST',
            endPoint: DELINQUENCY_ENDPOINTS.overview,
            body: { },
        },
    },
    [DELINQUENCY_TRACKING_WRONG_ENTRY_STORE]: {
        read: {
            methodType: 'POST',
            endPoint: DELINQUENCY_ENDPOINTS.wrongEntries,
            body: { },
        },
    },
    [JOURNAL_CONFIGURATION_CHANGES_STORE]: { ...journal },
    [JOURNAL_LOCKOUT_EVENT_STORE]: {
        read: {
            methodType: 'POST',
            endPoint: JOURNAL_ENDPOINTS.search,
            body: {
                view: VIEW.list.value,
                includeRelations: { User: {}, Unit: {}, Tenant: {} },
            },
        },
    },
    [JOURNAL_USER_ROLE_STORE]: { ...journal },
    [JOURNAL_USER_MANAGEMENT_STORE]: { ...journal },
    [JOURNAL_LATE_EVENT_STORE]: {
        read: {
            methodType: 'POST',
            endPoint: OPERATIONS_ENDPOINTS.lateEvent,
            body: {
                view: VIEW.list.value,
                includeRelations: { Account_Receivable: {} },
            },
        },
    },
};

export default ActionConfig;
