import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { isStaging } from 'Commons/helpers/utils/Utils';
import { SENTRY_CONFIG } from 'Commons/config/constants/ErrorTracking';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';

if (isStaging()) {
    Sentry.init(SENTRY_CONFIG);
}

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
