import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapRelationalData } from '../../../commons/redux/helper/RelationalDataMap';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const keys = [{
        dataKey: 'unitIdOrUnitTypeId',
        relationalDataKey: 'unit',
    }];
    processedData = mapRelationalData(processedData, keys);
    const { data: oldData, data: { data = [], relationalData: { tenant: tenantData = {} } = {} } = {} } = processedData;

    const ledgerIdToTenantIdMapping = Object.keys(tenantData).reduce((prevVal, tenantId) => {
        const { [tenantId]: { ledger: ledgersOfTenant = [] } = {} } = tenantData || {};
        const ledgerIdsMappedToTenantId = ledgersOfTenant.reduce((acc, { id } = {}) => ({ ...acc, [id]: tenantId }), {});
        return { ...prevVal, ...ledgerIdsMappedToTenantId };
    }, {});

    const newData = data.map((eachData) => {
        const { unit: { ledger: { id: ledgerId } = {} } = {} } = eachData;
        const { [ledgerId]: requiredTenantId } = ledgerIdToTenantIdMapping;
        const { [requiredTenantId]: tenant = {} } = tenantData;
        return {
            ...eachData,
            tenant,
        };
    });
    processedData = {
        ...processedData,
        data: {
            ...oldData,
            data: newData,
        },
    };
    return processedData;
};
