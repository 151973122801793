import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { flattenObject } from 'Commons/helpers/utils/DataHelpers';

export default (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const {
        data: {
            data: {
                permissions: [{ permission: { roleId } = {} } = {}] = [],
                roles = [],
            } = {},
            relationalData: [user] = [],
            ...data
        },
    } = processedData || {};

    // converting roles to key, value pair where key is the id of role
    const processedRoles = roles.reduce((acc, { id: profileId, permission }) => ({
        ...acc,
        [profileId]: permission ? flattenObject(permission) : {},
    }), {});

    return {
        ...processedData,
        data: {
            ...data,
            data: processedRoles,
            relationalData: {
                user: {
                    ...user,
                    roleId,
                },
            },
            totalCount: roles.length,
        },
    };
};
