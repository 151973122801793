import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { dateDiff } from 'Commons/helpers/utils/DateTime';
import { TIME_WINDOW } from 'Commons/config/constants/Constants';
import getCustomerList from '../utils/ConversationHelper';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions) || {};
    const { data = {} } = processedData;
    if (data) {
        const { data: emails = {}, relationalData: { tenant: tenants = [], lead: leads = [] } = {} } = data;
        const customerWithConversations = getCustomerList(tenants, leads, emails, 'emailAddress');
        customerWithConversations.sort((a, b) => dateDiff(b.lastContacted, a.lastContacted, TIME_WINDOW.MILLISECONDS));
        processedData = {
            ...processedData,
            data: { data: customerWithConversations },
        };
    }
    return processedData;
};
