import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

export default (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const {
        data: {
            data = [],
            relationalData: { Is_Delinquent: { tenants: tenantsDelinquentStatus = {} } = {} } = {},
        } = {},
    } = processedData;
    processedData.data.data = data.map((eachData) => {
        const { id } = eachData;
        const { [id]: { isDelinquent = false } = {} } = tenantsDelinquentStatus;
        return {
            ...eachData,
            isDelinquent,
        };
    });
    return processedData;
};
