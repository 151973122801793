export const STAGE_STATUS = {
    VERIFY_USER: 1,
    SUCCESS_SIGNIN: 2,
    SUCCESS_SIGNIN_2FA: 3,
    RESET_PASSWORD: 4,
    CREATE_PASSWORD: 5,
    FORGOT_PASSWORD: 6,
    RESET_PASSWORD_2FA: 7,
    USER_LOCKED: 8,
};

export const PASSWORD_POLICY_STATUSES = [STAGE_STATUS.RESET_PASSWORD, STAGE_STATUS.CREATE_PASSWORD, STAGE_STATUS.FORGOT_PASSWORD];


export const SUBSTATUS_CODES = {
    // TODO: Handle User_Locked
    AUTHORIZED_USER: 'Authorized_User',
    UNAUTHORIZED_USER: 'Unauthorized_User',
    CREATED_USER: 'Created_User',
    EXPIRED_PASSWORD: 'Expired_Password',
    TWO_FACTOR_EXPIRED: 'Two_Factor_Code_Expired',
    ACCESS_CODE_EXPIRED: 'Access_Code_Expired',
    SAME_AS_PREVIOUS: 'Same_As_Previous',
    INVALID_TOKEN: 'Invalid_Token',
    USER_LOCKED: 'User_Locked',
    HAS_ACTIVE_ADMIN_SESSIONS: 'Has_Active_Admin_Sessions',
};

export const ERROR_MESSAGES = {
    TWO_FACTOR_EXPIRED: 'Invalid Access Code/Access Code Expired',
    ACCESS_CODE_EXPIRED: 'Access Code Expired',
    VERIFY_OLD_PASSWORD: 'Old password Does not Match',
    SAME_AS_PREVIOUS: 'Old & New Passwords are same',
    NEW_PASSWORD_MATCH: 'New Password Does Not Match',
    SEND_CODE_ERROR: 'Error during Send Access Code',
    SEND_CODE_UNSUCCESSFUL: 'Oops Something went wrong',
    RESET_PASSWORD_ERROR: 'Error during resetting password',
    PASSWORD_EXPIRED: 'Password has expired',
    GENERIC_ERROR: 'Something Went Wrong!!',
    USER_LOCKED: 'User Account Has Been Temporarily Locked',
};

export const AUTH_CODE_TYPES = {
    ACCESS_CODE: 'Access_Code',
    TWO_FACTOR: 'Two_Factor_Access_Code',
};

export const AUTH_CODE_SOURCES = {
    CREATE_PASSWORD: 'Create_Password',
    FORGOT_PASSWORD: 'Forgot_Password',
};
