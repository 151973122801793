import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapIdsByAccessCode } from '../utils/AccessCodeProcessingHelper';

export default (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data: { data = [], ...innerData } = {}, ...outerData } = processedData;
    const mappedData = {};
    const relationalData = {};
    data.forEach(({ id: unitId, gateAccess: { code } = {} }) => {
        mapIdsByAccessCode(mappedData, code, unitId);
        relationalData[unitId] = code;
    });
    return { ...outerData, data: { ...innerData, data: mappedData, relationalData } };
};
