const enums = {
    rentalStatus: ['Rented', 'Vacant'],
    overlockStatus: ['Unlocked', 'Unlock_Pending', 'Overlocked', 'Overlock_Pending'],
    period: ['Day', 'Month', 'Week', 'Quarter', 'Twenty_Eight_Day', 'Bi_Annual', 'Annual', 'Seasonal'],
    promoPlanState: ['Added', 'Deleted'],
    calculationStyle: ['Flat', 'Percentage'],
    nradActionType: ['Get_Charges', 'Create_Charges', 'Waive_Charges'],
    vacantAvailabilityStatus: ['Available', 'Unavailable'],
    rentedAvailabilityStatus: ['Available', 'Unavailable_On_Move_Out'],
    rateHistoryStatus: ['Pending', 'Applied', 'Moved_In', 'Moved_Out', 'Reversed', 'Reversed_To', 'Waived', 'Stopped', 'Unknown'],
    lateEventTrigger: ['Manual'],
    lateEventStatus: ['Active'],
    leaseSignStatus: ['Pending', 'Complete'],
};

export default enums;
