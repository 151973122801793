import Icon from 'Generic/icon/components/Icon';

const IconComponent = ({ icon, className }) => (
    <Icon
        type="custom"
        icon={icon}
        fullWidth
        className={className}
    />
);

IconComponent.propTypes = { icon: PropTypes.string, className: PropTypes.string };

const severityLevel = [
    { icon: 'cp-warning', Component: IconComponent, value: 'Warning' },
    { icon: 'cp-error', Component: IconComponent, value: 'Error' },
    { icon: 'cp-query', Component: IconComponent, value: 'Query' },
    { icon: 'cp-info-colored', Component: IconComponent, value: 'Information' },
];
const mode = [
    { icon: 'cp-email-outlined', Component: IconComponent, value: 'Email' },
    { icon: 'cp-phone', Component: IconComponent, value: 'Phone' },
    { icon: 'cp-text-outlined', Component: IconComponent, value: 'Text' },
    { icon: 'cp-print', Component: IconComponent, value: 'Print' },
];

const tenantAlertList = ['Do_Not_Rent', 'Do_Not_Accept_Checks'];

export { severityLevel, mode, tenantAlertList };
