import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { mapRelationalData } from 'Commons/redux/helper/RelationalDataMap';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const {
        data: {
            relationalData = {},
            relationalData: { facility = [], businessGroup = [] } = {}, ...restData
        } = {}, ...restProcessedData
    } = processedData;

    let processedFacility = { data: { data: facility, relationalData: { businessGroup } } };
    if (businessGroup.length) {
        processedFacility = mapRelationalData(
            processedFacility, [{ dataKey: 'businessGroup', relationalDataKey: 'businessGroup' }],
        );
    }
    if (facility.length) {
        const { data: { data: facilityData = [] } = {} } = processedFacility;
        processedData = {
            ...restProcessedData,
            data: { ...restData, relationalData: { ...relationalData, facility: facilityData } },
        };
        const keys = [{ dataKey: 'facility', relationalDataKey: 'facility' }];
        processedData = mapRelationalData(processedData, keys);
    }
    return processedData;
};
