import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

export default (apiResult, callback, dispatch, localReqOptions) => {
    const {
        data: { data: { unitType = [], ...restInnerData } = {}, ...restOuterData } = {},
        ...processedData
    } = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const updatedUnitType = {};
    unitType.forEach((unitTypeDetail = {}) => {
        const { value: { description } = {}, value, id } = unitTypeDetail;
        updatedUnitType[id] = { ...unitTypeDetail, label: description, data: value };
    });
    return {
        ...processedData,
        data: {
            ...restOuterData,
            data: {
                ...restInnerData,
                unitType: updatedUnitType,
            },
        },
    };
};
