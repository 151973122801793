import STATUS from 'Commons/config/constants/StoreKeyStatus';
import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import { OVERRIDABLE_CONFIGS } from 'External/containers/configuration/config/Constants';

const addParentConfigToList = (updatedConfigList, configData, id) => updatedConfigList.push({ ...configData, id: id.toString() });

const mergeChildConfigWithParent = (configList = [], shouldAddParentConfigToList = false) => {
    const updatedConfigList = [];
    configList.forEach((configData = {}) => {
        const {
            id, value = {}, value: { description } = {},
            childConfiguration = [],
        } = configData;
        if (description) {
            if (childConfiguration.length) {
                childConfiguration.forEach((eachChildConfiguration = {}) => {
                    const { id: childId, value: childValue = {} } = eachChildConfiguration;
                    updatedConfigList.push({
                        ...eachChildConfiguration,
                        id: childId.toString(),
                        value: { ...value, ...childValue },
                    });
                });
                if (shouldAddParentConfigToList) {
                    addParentConfigToList(updatedConfigList, configData, id);
                }
            } else {
                addParentConfigToList(updatedConfigList, configData, id);
            }
        }
    });
    return updatedConfigList;
};

/* If config(system one) is overridden, then it adds the detail of the configuration to each of its child configuration,
and remove it(system one) from the list. It(system one) remains as it is, if configuration is not overridden */
const handleConfigInheritance = (dataToProcess = {}, status) => {
    const { data = {}, ...restData } = dataToProcess;
    const updatedConfig = {};
    let totalCount = 0;
    Object.keys(data).forEach((configKey) => {
        const { [configKey]: { data: configList = [] } = {} } = data;
        let updatedConfigList = [];
        if (OVERRIDABLE_CONFIGS.includes(configKey)) {
            updatedConfigList = mergeChildConfigWithParent(configList);
        } else {
            updatedConfigList = configList;
        }
        updatedConfig[configKey] = { data: updatedConfigList };
        totalCount += updatedConfigList.length;
    });
    return {
        data: {
            data: {
                ...restData,
                ...updatedConfig,
            },
            totalCount,
            ...status && { status },
        },
    };
};

const configSDPostProcessor = (apiResult, callback, dispatch, localReqOptions) => {
    const { error, response = {} } = apiResult;
    const processedData = baseProcessor(apiResult, undefined, dispatch, localReqOptions);
    const { data, status } = processedData;
    const updatedData = handleConfigInheritance(data, status);
    const isQueued = response.status === STATUS.QUEUED;
    if (!isQueued && callback) {
        callback(error, updatedData.data);
    }
    return updatedData;
};

export { configSDPostProcessor, handleConfigInheritance, mergeChildConfigWithParent };
