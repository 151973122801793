const onMountConfigs = {
    resolvePermission: (store = {}) => {
        const {
            permissionRoles: {
                data: {
                    data: roles = {},
                    relationalData: { user: { roleId: permissionRoleId } = {} } = {},
                } = {},
            },
        } = store;
        return {
            store: {
                key: 'permission',
                actionKey: 'permission_create',
                payload: { data: { data: roles['-1'] || roles[permissionRoleId] || {} } },
            },
            method: 'create',
        };
    },
};

export default onMountConfigs;
