import {
    ACCOUNT_ENDPOINTS,
    ACH_ENDPOINTS,
    BATCH_PAYMENT_ENDPOINTS,
    BUSINESS_GROUP_ENDPOINTS,
    CARD_PROCESSOR_TRANSACTION_ENDPOINTS,
    CONFIG_ENDPOINTS,
    CONVERSATION_ENDPOINTS,
    DAILY_SUMMARY_ENDPOINTS,
    DELINQUENCY_ENDPOINTS,
    DEPOSIT_LOG_ENDPOINTS,
    DOCUMENT_ENDPOINTS,
    FACILITY_ENDPOINTS,
    FINANCIAL_ENDPOINTS,
    MAINTENANCE_OPERATION_ENDPOINTS,
    OCCUPANCY_HISTORY_ENDPOINTS,
    ONBOARDING_ENDPOINTS,
    OPERATIONS_ENDPOINTS,
    PAYMENT_ENDPOINTS,
    PENDING_TASKS_ENDPOINTS,
    PRINT_FAILURES_ENDPOINTS,
    RENT_ADJUSTMENT_ENDPOINTS,
    RENTAL_ENDPOINTS,
    RESERVATION_ENDPOINTS,
    RETAIL_SALE_ENDPOINTS,
    STANDALONE_APP_ENDPOINTS,
    TENANT_CHANGE_TRACKING_ENDPOINTS,
    TENANT_ENDPOINTS,
    UNIT_ENDPOINTS,
    VEHICLE_ENDPOINTS,
    WAITING_LIST_ENDPOINTS,
} from 'Commons/config/constants/Endpoints';
import {
    BATCH_PAYMENT_TASKS,
    CARD_PROCESSOR_TRANSACTION_STATUS,
    ENTITY_LEVEL,
    FULL_YEAR_FORMAT,
    OTHER_EXTERNAL_ACTIVITY_TRACKING_STATUS,
    RESERVATION_STATUS,
    SEARCH_DATE_FORMAT,
    SEARCH_DOCUMENT_SOURCE,
    SHORT_YEAR_FORMAT,
    STATUS,
    TENANT_CHANGE_TRACKING_STATUS,
    TENANT_CHANGE_TYPE,
    TIME_WINDOW,
    UNIT_AGGREGATION,
    VIEW,
} from 'Commons/config/constants/Constants';
import { addToDate, dateFormatter, getCurrentDate, getMonth, subtractFromDate } from 'Commons/helpers/utils/DateTime';
import CONFIG_TYPE from 'External/containers/configuration/config/ConfigRequestType';
import { LETTER_TYPE } from 'External/containers/customerengagement/config/Config';
import { OVERLOCK_STATUS, RENTED_AVAILABILITY_STATUS, RENTAL_STATUS } from 'External/containers/unit/form/config/Constants';
import { AR_AGGREGATION, DOCUMENTATION_STATUS } from 'External/containers/viewtenant/config/Constants';
import { RENT_ADJUSTMENT_STATUS } from 'External/containers/operations/components/rentAdjustment/config/Constants';
import {
    DOCUMENT_STATUS,
    DOCUMENT_TYPE,
    SUB_DOCUMENT_TYPE,
} from 'Commons/components/business/filtersAndSorts/components/savedDocumentsListFilter/config/Constants';
import { DELIVERY_METHOD } from 'Commons/components/generic/deliveryMethodDropdown/config/Constants';

const pendingInvoiceDeliveryActionConfig = {
    methodType: 'POST',
    body: {
        filter: [{
            terms: {
                status: [DOCUMENT_STATUS.Ready_For_Delivery.value],
                subDocumentType: [SUB_DOCUMENT_TYPE.Invoice_Pre_Billing_Detailed.value],
            },
            exists: { batchId: [true] },
        }],
    },
    customHeaderOptions: {
        type: 'store',
        key: 'facility',
    },
};

const ActionConfig = {
    userProfile: {
        create: {
            methodType: 'POST',
            endPoint: 'user/',
        },
        read: {
            methodType: 'GET',
            endPoint: 'user',
        },
        update: {
            methodType: 'PUT',
            endPoint: 'user/edit',
        },
        delete: {
            methodType: 'DELETE',
            endPoint: 'user/remove',
        },
    },
    facility: {
        read: {
            methodType: 'POST',
            endPoint: FACILITY_ENDPOINTS.search,
            body: {
                view: 'mini',
                includeRelations: {
                    User: {},
                    Permissions: {},
                    Global_Encryption_Key: {},
                    Business_Group: {},
                    Missing_Configs: {},
                    Agreement_Link: {},
                },
                size: 500,
            },
            store: { keys: ['facility', 'userProfile', 'permissionRoles'] },
        },
        update: {
            methodType: 'PUT',
            endPoint: 'facility/edit',
        },
        delete: {
            methodType: 'DELETE',
            endPoint: 'facility/remove',
        },
    },
    unit: {
        read: {
            methodType: 'POST',
            endPoint: UNIT_ENDPOINTS.search,
            body: {
                view: 'list',
                includeRelations: { Tenant: { view: 'mini_list' } },
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
        create: {},
        update: {
            methodType: 'PUT',
            endPoint: UNIT_ENDPOINTS.createUnit,
        },
    },
    rateHistory: {
        read: {
            methodType: 'POST',
            endPoint: UNIT_ENDPOINTS.rateHistorySearch,
            body: {
                view: VIEW.detail.value,
                includeRelations: {
                    Tenant: {},
                    User: {},
                },
                sort: [{ effectiveDate: { order: 'desc' } }],
                size: 20,
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    standaloneApp: {
        read: {
            methodType: 'POST',
            endPoint: STANDALONE_APP_ENDPOINTS.search,
            body: {
                view: VIEW.detail.value,
                filter: [{ terms: { status: ['Active'] } }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    onboarding: {
        read: {
            methodType: 'POST',
            endPoint: ONBOARDING_ENDPOINTS.search,
            body: {
                entities: {
                    Facility: {},
                    Facility_General: {},
                    Tax_Code: {},
                    Unit_Type: {},
                    Customer_General: {},
                    Reservation: {},
                    Move_In: {},
                    Move_Out: {},
                    Tenant: {},
                    Unit: {},
                },
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    vendor: {
        read: {
            methodType: 'POST',
            endPoint: 'vendor/search',
            body: { view: 'list' },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
        create: {},
        update: {
            methodType: 'PUT',
            endPoint: 'vendor',
        },
    },
    inventoryList: {
        read: {
            methodType: 'POST',
            endPoint: 'inventory/search',
            body: { view: 'list' },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    verifyEmail: {
        read: {
            methodType: 'POST',
            endPoint: 'conversation/email/verify-and-enable',
            body: {},
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    tenant: {
        read: {
            methodType: 'POST',
            endPoint: 'tenant/search',
            body: { view: 'list' },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    tenantsWithInvalidAccessCodes: {
        read: {
            methodType: 'POST',
            endPoint: 'tenant/search',
            body: {
                view: 'invalid_access_code',
                filter: [{ terms: { ledger: { gate_access: { is_access_code_invalid: [true] } } } }],
                sort: [{ name: { lastName: { order: 'asc' }, firstName: { order: 'asc' } } }],
                includeRelations: { Unit: {} },
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    unitsWithInvalidAccessCodes: {
        read: {
            methodType: 'POST',
            endPoint: 'unit/search',
            body: {
                view: 'invalid_access_code',
                filter: [{
                    terms: {
                        gate_access: { is_access_code_invalid: [true] },
                        rental_status: [RENTAL_STATUS.Rented],
                    },
                }],
                sort: [{ label: { order: 'asc' } }],
                includeRelations: { Tenant: { view: 'invalid_access_code' } },
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    tenantsForDocument: {
        read: {
            methodType: 'POST',
            endPoint: 'tenant/search',
            // ToDo change the view once modified in backend accordingly
            body: {
                view: 'detail',
                size: 1000,
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    ledgerAccessCode: {
        read: {
            methodType: 'POST',
            endPoint: 'tenant/search',
            body: {
                view: VIEW.access_code.value,
                filter: [{ exists: { ledger: { gateAccess: { code: [true] } } } }],
                size: 1000,
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    unitAccessCode: {
        read: {
            methodType: 'POST',
            endPoint: 'unit/search',
            body: {
                view: VIEW.access_code.value,
                filter: [{ exists: { gateAccess: { code: [true] } } }],
                size: 1000,
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    unitForDocuments: {
        read: {
            methodType: 'POST',
            endPoint: 'unit/search',
            body: {
                view: VIEW.access_code.value,
                filter: [{ terms: { rentalStatus: ['Rented'] } }],
                size: 1000,
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    lead: {
        read: {
            methodType: 'POST',
            endPoint: 'lead/search',
            body: { view: 'detail' },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    config: {
        read: {
            methodType: 'POST',
            endPoint: 'configuration/search',
            body: {
                view: 'detail',
                filter: [],
            },
        },
    },
    ar: {
        read: {
            methodType: 'POST',
            endPoint: 'financial/ar/search',
            body: { view: 'detail' },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    cash: {
        read: {
            methodType: 'POST',
            endPoint: 'financial/cash/search',
            body: { view: 'detail' },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    userRole: {
        read: {
            methodType: 'POST',
            endPoint: 'user-role/search',
            body: { view: 'detail' },
            customHeaderOptions: {
                type: 'store',
                key: 'account',
            },
        },
    },
    account: {
        read: {
            methodType: 'POST',
            endPoint: ACCOUNT_ENDPOINTS.search,
            customHeaderOptions: {
                type: 'store',
                key: 'account',
            },
        },
    },
    businessGroup: {
        read: {
            methodType: 'POST',
            endPoint: BUSINESS_GROUP_ENDPOINTS.search,
            body: { view: 'detail' },
            customHeaderOptions: {
                type: 'store',
                key: 'account',
            },
        },
    },
    user: {
        read: {
            methodType: 'POST',
            endPoint: 'user/search',
            body: { view: 'detail' },
            customHeaderOptions: {
                type: 'store',
                key: 'account',
            },
        },
    },
    preRequisite: {
        read: {
            methodType: 'GET',
            endPoint: RENTAL_ENDPOINTS.prerequisite,
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    reservation: {
        read: {
            methodType: 'POST',
            endPoint: RESERVATION_ENDPOINTS.search,
            body: {
                view: VIEW.list.value,
                includeRelations: {
                    Tenant: { view: 'list' },
                    Unit: {},
                },
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    waitingList: {
        read: {
            methodType: 'POST',
            endPoint: WAITING_LIST_ENDPOINTS.search,
            body: { view: VIEW.list.value },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    unitBev: {
        read: {
            methodType: 'POST',
            endPoint: UNIT_ENDPOINTS.search,
            body: {
                view: 'bev',
                filter: [{ terms: { status: ['Active'] } }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    unitBevMini: {
        read: {
            methodType: 'POST',
            endPoint: UNIT_ENDPOINTS.search,
            body: {
                view: 'mini',
                size: 1000,
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    users: {
        read: {
            methodType: 'POST',
            endPoint: 'user/search',
            body: { view: 'detail' },
        },
    },
    unpaidAr: {
        read: {
            methodType: 'POST',
            endPoint: FINANCIAL_ENDPOINTS.unpaidAr,
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    tenantAlert: {
        read: {
            methodType: 'POST',
            endPoint: TENANT_ENDPOINTS.tenantAlertSearch,
            body: { view: VIEW.detail.value, size: 50 },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    contactLog: {
        read: {
            methodType: 'POST',
            endPoint: TENANT_ENDPOINTS.contactLogSearch,
            body: { view: VIEW.detail.value },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    tenantDepositToBePaid: {
        read: {
            methodType: 'POST',
            endPoint: FINANCIAL_ENDPOINTS.arAggregation,
            body: { aggregationName: AR_AGGREGATION.TENANT_DEPOSIT_TO_BE_PAID },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    maintenanceOperation: {
        read: {
            methodType: 'POST',
            endPoint: MAINTENANCE_OPERATION_ENDPOINTS.search,
            body: {
                view: VIEW.detail.value,
                sort: [{ start_date: { order: 'asc' } }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    manualLateEvent: {
        read: {
            methodType: 'POST',
            endPoint: OPERATIONS_ENDPOINTS.lateEvent,
            body: {
                view: VIEW.list.value,
                filter: [{
                    terms: { status: ['Active'] },
                    range: { effectiveDate: { to: getCurrentDate() } },
                }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    vacantUnitsCountByUnitType: {
        read: {
            methodType: 'POST',
            endPoint: UNIT_ENDPOINTS.aggregation,
            body: { aggregationName: UNIT_AGGREGATION.VACANT_UNITS_COUNT_BY_UNIT_TYPE },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    vacantAndReservedUnitsCountByUnitType: {
        read: {
            methodType: 'POST',
            endPoint: UNIT_ENDPOINTS.aggregation,
            body: { aggregationName: UNIT_AGGREGATION.VACANT_AND_RESERVATION_UNITS_COUNT_BY_UNIT_TYPE },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    failedReturnsCount: {
        read: {
            methodType: 'POST',
            endPoint: RETAIL_SALE_ENDPOINTS.returnsCount,
            body: {
                filter: [
                    {
                        exists: { originalPurchaseOrder: [true] },
                        range: { balance: { fromStrict: 0 } },
                        terms: { status: ['Pending', 'Partial_Pending'] },
                    },
                ],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    failedReturns: {
        read: {
            methodType: 'POST',
            endPoint: RETAIL_SALE_ENDPOINTS.purchaseOrderSearch,
            body: {
                view: 'detail',
                filter: [
                    {
                        exists: { originalPurchaseOrder: [true] },
                        range: { balance: { fromStrict: 0 } },
                        terms: { status: ['Pending', 'Partial_Pending'] },
                    },
                ],
                sort: [{ purchaseDate: { order: 'desc' } }],
                includeRelations: {
                    Tenant: { view: 'detail' },
                    User: {},
                },
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    delinquency: {
        read: {
            methodType: 'POST',
            endPoint: DELINQUENCY_ENDPOINTS.wcSearch,
            body: {
                view: 'list',
                filter: [{
                    range: {
                        noOfDaysLate: { from: 1 },
                        balance: { from_strict: 0 },
                    },
                }],
                include_relations: { Delinquency_Ledger_Info: {} },
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    estimate: {
        read: {
            methodType: 'POST',
            endPoint: 'estimate/search',
            body: { view: 'detail' },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    pendingOverlockUnlockCount: {
        read: {
            methodType: 'POST',
            endPoint: UNIT_ENDPOINTS.count,
            body: {
                filter: [{
                    terms: {
                        overlock_status: [
                            OVERLOCK_STATUS.Unlock_Pending.value,
                            OVERLOCK_STATUS.Overlock_Pending.value,
                        ],
                        status: [STATUS.Active.value],
                        availabilityStatus: [RENTED_AVAILABILITY_STATUS.Available.value,
                            RENTED_AVAILABILITY_STATUS.Unavailable_On_Move_Out.value],
                    },
                }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    restockInventoryCount: {
        read: {
            methodType: 'POST',
            endPoint: RETAIL_SALE_ENDPOINTS.inventoryCount,
            body: {
                filter: [{
                    terms: { status: [STATUS.Active.value] },
                    range: { available_quantity: { to_field_strict: 'reorder_point' } },
                }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    inventoryAuditCount: {
        read: {
            methodType: 'POST',
            endPoint: RETAIL_SALE_ENDPOINTS.inventoryCount,
            body: {
                filter: [{
                    terms: { status: [STATUS.Active.value] },
                    range: { available_quantity: { to_strict: 0 } },
                }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    applyLateEventsCount: {
        read: {
            methodType: 'POST',
            endPoint: PENDING_TASKS_ENDPOINTS.applyLateEventsCount,
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    pastDueReservationsCount: {
        read: {
            methodType: 'POST',
            endPoint: RESERVATION_ENDPOINTS.count,
            body: {
                filter: [{
                    terms: { status: [RESERVATION_STATUS.Reserved] },
                    range: { rentalInfo: { moveInDate: { to: dateFormatter(subtractFromDate(), SEARCH_DATE_FORMAT) } } },
                }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    unitsPendingOverlockUnlock: {
        read: {
            methodType: 'POST',
            endPoint: UNIT_ENDPOINTS.search,
            body: {
                view: 'list',
                filter: [{
                    terms: {
                        status: [STATUS.Active.value],
                        availabilityStatus: [RENTED_AVAILABILITY_STATUS.Available.value,
                            RENTED_AVAILABILITY_STATUS.Unavailable_On_Move_Out.value],
                    },
                }],
                includeRelations: { Tenant: {} },
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    textConversation: {
        read: {
            methodType: 'POST',
            endPoint: CONVERSATION_ENDPOINTS.listText,
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    emailConversation: {
        read: {
            methodType: 'POST',
            endPoint: CONVERSATION_ENDPOINTS.listEmail,
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    text: {
        read: {
            methodType: 'POST',
            endPoint: CONVERSATION_ENDPOINTS.searchText,
            body: {
                view: 'detail',
                sort: [{ createdDate: { order: 'desc' } }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    email: {
        read: {
            methodType: 'POST',
            endPoint: CONVERSATION_ENDPOINTS.searchEmail,
            body: {
                view: 'detail',
                sort: [{ createdDate: { order: 'desc' } }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    textOrEmailUnreadCount: {
        read: {
            methodType: 'POST',
            endPoint: CONVERSATION_ENDPOINTS.unreadCount,
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    achTransaction: {
        read: {
            methodType: 'POST',
            endPoint: ACH_ENDPOINTS.searchTransactions,
            body: { view: 'detail' },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    creditCardBatches: {
        read: {
            methodType: 'POST',
            endPoint: DAILY_SUMMARY_ENDPOINTS.search,
            body: {
                view: 'detail',
                filter: [{ terms: { task: [BATCH_PAYMENT_TASKS.Auto_Payment.value] } }],
                sort: [{ createdDate: { order: 'desc' } }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    lastFinancialExport: {
        read: {
            methodType: 'POST',
            endPoint: DOCUMENT_ENDPOINTS.search,
            body: {
                view: 'detail',
                filter: [{ terms: { documentType: [DOCUMENT_TYPE.Financial_Export.value] } }],
                sort: [{ createdDate: { order: 'desc' } }],
                size: 1,
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    savedDocument: {
        read: {
            methodType: 'POST',
            endPoint: DOCUMENT_ENDPOINTS.search,
            body: {
                view: 'list',
                filter: [{
                    terms: {
                        status: [DOCUMENTATION_STATUS.Active.value,
                            DOCUMENTATION_STATUS.Pending.value,
                            DOCUMENTATION_STATUS.Delivery_Error.value,
                            DOCUMENTATION_STATUS.Generate_Error.value,
                            DOCUMENTATION_STATUS.Upload_Error.value],
                    },
                }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    rentAdjustment: {
        read: {
            methodType: 'POST',
            endPoint: RENT_ADJUSTMENT_ENDPOINTS.search,
            body: {
                view: 'list',
                include_relations: { User: {} },
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    rentAdjustmentGrid: {
        read: {
            methodType: 'POST',
            endPoint: UNIT_ENDPOINTS.search,
            body: {
                view: 'detail',
                include_relations: {
                    Tenant: {},
                    Unit_Type: {},
                },
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    automaticCreditCardDeclinesCount: {
        read: {
            methodType: 'POST',
            endPoint: CARD_PROCESSOR_TRANSACTION_ENDPOINTS.count,
            body: {
                view: 'latest_card_declines',
                filter: [{
                    terms: {
                        lastStatus: [CARD_PROCESSOR_TRANSACTION_STATUS.Error,
                            CARD_PROCESSOR_TRANSACTION_STATUS.Duplicate,
                            CARD_PROCESSOR_TRANSACTION_STATUS.Declined, CARD_PROCESSOR_TRANSACTION_STATUS.Unknown],
                        autoPay: true,
                        parentTransaction: null,
                    },
                    range: { ledger: { balance: { fromStrict: 0 } } },
                }],
                sort: [
                    { ledgerId: { order: 'asc' } },
                    { id: { order: 'desc' } },
                ],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    viewRentAdjustmentGrid: {
        read: {
            methodType: 'POST',
            endPoint: RENT_ADJUSTMENT_ENDPOINTS.search,
            body: {
                view: 'detail',
                include_relations: {
                    User: {},
                    Unit: {},
                    Tenant: {},
                    Unit_Type: {},
                },
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    automaticCreditCardDeclines: {
        read: {
            methodType: 'POST',
            endPoint: CARD_PROCESSOR_TRANSACTION_ENDPOINTS.search,
            body: {
                view: 'latest_card_declines',
                filter: [{
                    terms: {
                        lastStatus: [CARD_PROCESSOR_TRANSACTION_STATUS.Error,
                            CARD_PROCESSOR_TRANSACTION_STATUS.Duplicate,
                            CARD_PROCESSOR_TRANSACTION_STATUS.Declined, CARD_PROCESSOR_TRANSACTION_STATUS.Unknown],
                        parentTransaction: null,
                        autoPay: true,
                    },
                    range: { ledger: { balance: { fromStrict: 0 } } },
                }],
                sort: [
                    { ledgerId: { order: 'asc' } },
                    { id: { order: 'desc' } },
                ],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    lateEvents: {
        read: {
            methodType: 'POST',
            endPoint: UNIT_ENDPOINTS.lateEvents,
            body: { unitId: '' },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    tenantChangesCount: {
        read: {
            methodType: 'POST',
            endPoint: TENANT_CHANGE_TRACKING_ENDPOINTS.count,
            body: { filter: [{ terms: { status: [TENANT_CHANGE_TRACKING_STATUS.Queued] } }] },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    moveInCount: {
        read: {
            methodType: 'POST',
            endPoint: OCCUPANCY_HISTORY_ENDPOINTS.count,
            body: { filter: [{ terms: { status: [OTHER_EXTERNAL_ACTIVITY_TRACKING_STATUS.Queued] } }] },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    moveInSearch: {
        read: {
            methodType: 'POST',
            endPoint: OCCUPANCY_HISTORY_ENDPOINTS.search,
            body: {
                view: 'detail',
                filter: [{ terms: { status: [OTHER_EXTERNAL_ACTIVITY_TRACKING_STATUS.Queued] } }],
                includeRelations: {
                    Unit: {},
                    Tenant: { view: 'detail' },
                    Unit_Type: {},
                },
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    payment: {
        read: {
            methodType: 'POST',
            endPoint: PAYMENT_ENDPOINTS.search,
            body: {
                view: 'list',
                filter: [{ terms: { status: [OTHER_EXTERNAL_ACTIVITY_TRACKING_STATUS.Queued] } }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    tfwReservationCount: {
        read: {
            methodType: 'POST',
            endPoint: RESERVATION_ENDPOINTS.count,
            body: { filter: [{ terms: { approvalStatus: [OTHER_EXTERNAL_ACTIVITY_TRACKING_STATUS.Queued] } }] },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    paymentCount: {
        read: {
            methodType: 'POST',
            endPoint: PAYMENT_ENDPOINTS.count,
            body: { filter: [{ terms: { status: [OTHER_EXTERNAL_ACTIVITY_TRACKING_STATUS.Queued] } }] },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    ccAboutToExpire: {
        read: {
            methodType: 'POST',
            endPoint: TENANT_ENDPOINTS.search,
            body: {
                view: 'detail',
                filter: [
                    {
                        exists: { ledger: { financials: { card_info: { token: [true] } } } },
                        terms: {
                            status: [STATUS.Active.value],
                            ledger: {
                                financials: {
                                    card_info: {
                                        expiration_date: {
                                            year: [
                                                addToDate(
                                                    getCurrentDate(), 30, TIME_WINDOW.DAYS, true, FULL_YEAR_FORMAT,
                                                ),
                                                addToDate(
                                                    getCurrentDate(), 30, TIME_WINDOW.DAYS, true, SHORT_YEAR_FORMAT,
                                                ),
                                            ],
                                        },
                                    },
                                },
                            },
                        },

                        range: {
                            ledger: {
                                financials: {
                                    card_info: {
                                        expiration_date: {
                                            month: {
                                                lte: getMonth() + 1,
                                                gte: getMonth(addToDate(getCurrentDate(), 30, TIME_WINDOW.DAYS)) + 1,
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                ],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    tenantEmailChanges: {
        read: {
            methodType: 'POST',
            endPoint: TENANT_CHANGE_TRACKING_ENDPOINTS.search,
            body: {
                view: 'list',
                filter: [{
                    terms: {
                        status: [TENANT_CHANGE_TRACKING_STATUS.Queued],
                        change_type: [TENANT_CHANGE_TYPE.email],
                    },
                }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    tenantPhoneChanges: {
        read: {
            methodType: 'POST',
            endPoint: TENANT_CHANGE_TRACKING_ENDPOINTS.search,
            body: {
                view: 'list',
                filter: [{
                    terms: {
                        status: [TENANT_CHANGE_TRACKING_STATUS.Queued],
                        change_type: [TENANT_CHANGE_TYPE.phone],
                    },
                }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    tenantAddressChanges: {
        read: {
            methodType: 'POST',
            endPoint: TENANT_CHANGE_TRACKING_ENDPOINTS.search,
            body: {
                view: 'list',
                filter: [{
                    terms: {
                        status: [TENANT_CHANGE_TRACKING_STATUS.Queued],
                        change_type: [TENANT_CHANGE_TYPE.address],
                    },
                }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    tenantAlternateContactChanges: {
        read: {
            methodType: 'POST',
            endPoint: TENANT_CHANGE_TRACKING_ENDPOINTS.search,
            body: {
                view: 'list',
                filter: [{
                    terms: {
                        status: [TENANT_CHANGE_TRACKING_STATUS.Queued],
                        change_type: [TENANT_CHANGE_TYPE.alternate_contact],
                    },
                }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    tenantChangeTypeCount: {
        read: {
            methodType: 'POST',
            endPoint: TENANT_CHANGE_TRACKING_ENDPOINTS.aggregation,
            body: {},
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    depositLog: {
        read: {
            methodType: 'POST',
            endPoint: DEPOSIT_LOG_ENDPOINTS.search,
            body: { view: VIEW.detail.value },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    printFailures: {
        read: {
            methodType: 'POST',
            endPoint: PRINT_FAILURES_ENDPOINTS.search,
            body: { view: 'list' },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    vehicleData: {
        read: {
            methodType: 'POST',
            endPoint: VEHICLE_ENDPOINTS.search,
            body: { view: VIEW.detail.value },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    ccBatchTenants: {
        read: {
            methodType: 'POST',
            endPoint: BATCH_PAYMENT_ENDPOINTS.ledgerSearch,
            body: {
                view: VIEW.detail.value,
                filter: [{
                    terms: { status: [STATUS.Active.value] },
                    range: { balance: { gt: 0 } },
                }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    sampleLetter: {
        read: {
            methodType: 'POST',
            endPoint: CONFIG_ENDPOINTS.search,
            body: {
                view: 'detail',
                filter: [{
                    terms: {
                        config_type: [CONFIG_TYPE.LETTER],
                        entity_type: [ENTITY_LEVEL.System_Facility.value],
                        value: {
                            status: [STATUS.Active.value],
                            letter_type: [LETTER_TYPE.System.value],
                        },
                        _extra: { include_system: true },
                    },
                }],
            },
        },
    },
    pendingRentAdjustmentsForAUnit: {
        read: {
            methodType: 'POST',
            endPoint: RENT_ADJUSTMENT_ENDPOINTS.search,
            body: {
                view: 'detail',
                filter: [{ terms: { status: [RENT_ADJUSTMENT_STATUS.PENDING] } }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    subDocumentPrintFailures: {
        read: {
            methodType: 'POST',
            endPoint: PRINT_FAILURES_ENDPOINTS.search,
            body: {
                view: 'list',
                filter: [{
                    terms: {
                        status: [DOCUMENT_STATUS.Generate_Error.value,
                            DOCUMENT_STATUS.Upload_Error.value,
                            DOCUMENT_STATUS.Delivery_Error.value],
                        deliveryMethod: [DELIVERY_METHOD.Print.value],
                        subDocumentType: [
                            SUB_DOCUMENT_TYPE.Rent_Adjustment.value,
                            SUB_DOCUMENT_TYPE.Late_Event.value,
                            SUB_DOCUMENT_TYPE.Receipt.value,
                        ],
                    },
                    exists: { batchId: [true] },
                }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    printFailuresCount: {
        read: {
            methodType: 'POST',
            endPoint: PENDING_TASKS_ENDPOINTS.printFailuresCount,
            body: {
                filter: [{
                    terms: {
                        status: [DOCUMENT_STATUS.Generate_Error.value,
                            DOCUMENT_STATUS.Upload_Error.value,
                            DOCUMENT_STATUS.Delivery_Error.value],
                        deliveryMethod: [DELIVERY_METHOD.Print.value],
                        subDocumentType: [
                            SUB_DOCUMENT_TYPE.Rent_Adjustment.value,
                            SUB_DOCUMENT_TYPE.Late_Event.value,
                            SUB_DOCUMENT_TYPE.Receipt.value,
                        ],
                    },
                    exists: { batchId: [true] },
                }],
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
    pendingInvoiceDeliveryCount: {
        read: {
            ...pendingInvoiceDeliveryActionConfig,
            endPoint: PENDING_TASKS_ENDPOINTS.pendingInvoiceDeliveryCount,
        },
    },
    pendingInvoiceDelivery: {
        read: {
            ...pendingInvoiceDeliveryActionConfig,
            endPoint: DOCUMENT_ENDPOINTS.search,
            body: {
                ...pendingInvoiceDeliveryActionConfig.body,
                view: VIEW.detail.value,
                includeRelations: { Tenant: {}, Unit: {} },
                sort: [{ createdDate: { order: 'desc' } }],
                source: SEARCH_DOCUMENT_SOURCE.PENDING_INVOICE_DELIVERY,
                size: 20,
            },
        },
    },
    emailAudit: {
        read: {
            methodType: 'POST',
            endPoint: TENANT_ENDPOINTS.contactLogSearch,
            body: {
                view: VIEW.detail.value,
                filter: [
                    {
                        terms: {
                            additional_info: {
                                delivery_status: [
                                    'Delivered',
                                ],
                            },
                            customer_type: ['Tenant'],
                            mode: ['Email'],
                        },
                    },
                ],
                sort: [{ createdDate: { order: 'desc' } }],
                include_relations: { Tenant: { view: 'mini' } },
            },
            customHeaderOptions: {
                type: 'store',
                key: 'facility',
            },
        },
    },
};

export default ActionConfig;
