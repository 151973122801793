import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';
import getCustomerList from '../utils/ConversationHelper';

export default (apiResult, callback, dispatch, localReqOptions) => {
    let processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions) || {};
    const { data = {} } = processedData;
    if (data) {
        const { data: texts = {}, relationalData: { tenant: tenants = [], lead: leads = [] } = {} } = data;
        processedData = {
            ...processedData,
            data: { data: getCustomerList(tenants, leads, texts, 'phoneNumber') },
        };
    }
    return processedData;
};
