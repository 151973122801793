/* eslint-disable no-param-reassign */
const mapIdsByAccessCode = (mappedData, accessCode, id) => {
    const ids = mappedData[accessCode] || [];
    if (!ids.includes(id)) {
        ids.push(id);
        mappedData[accessCode] = ids;
    }
};

export { mapIdsByAccessCode };
