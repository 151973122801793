import { deepCopy } from 'Commons/helpers/utils/DeepCopy';
import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

export default (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const {
        data: {
            data: {
                Reservation_Count_By_Unit_Type: reservedUnits = {},
                Vacant_Units_Count_By_Unit_Type: vacantUnits = {},
            } = {},
        } = {},
    } = processedData;
    let restReservedUnits = deepCopy(reservedUnits);
    let overbookedCounts = Object.keys(vacantUnits).reduce((acc, unitTypeId) => {
        const { [unitTypeId]: { totalCount: vacantCount = 0 } = {} } = vacantUnits;
        const { [unitTypeId]: { totalCount: reservedCount = 0 } = {}, ...rest } = restReservedUnits;
        restReservedUnits = { ...rest };
        return {
            ...acc,
            [unitTypeId]: { totalCount: reservedCount - vacantCount },
        };
    }, {});

    overbookedCounts = { ...overbookedCounts, ...restReservedUnits };

    processedData.data.data = overbookedCounts;
    return processedData;
};
