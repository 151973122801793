import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

const emailAuditFormatCustomerId = (data) => {
    const { data: resultData = [], relationalData: { tenant: tenantData = [] } = {} } = data;
    const getTenantNameFromCustomerId = (customerId) => {
        const tenant = tenantData.find(({ id }) => id === customerId);
        return tenant ? { ...tenant.name, id: tenant.id } : '';
    };
    resultData.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.tenantName = getTenantNameFromCustomerId(item.customerId);
        return item;
    });
    return resultData;
};

export default (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);
    const { data = {} } = processedData;
    processedData.data.data = emailAuditFormatCustomerId(data);
    return processedData;
};
