import { mapEnum } from '../../../../../commons/helpers/utils/Formatter';
import enums from '../../config/Enums';

const RENTAL_STATUS = mapEnum(enums.rentalStatus);

const UNIT_DEFAULT_SORT = {
    label: 'Unit Number',
    value: 'Unit Number',
    sort: [{ label: { order: 'asc' } }],
};

const UNIT_DEFAULT_FILTER = {
    terms: {
        status: ['Active'],
        additionalInfo: { includeInRentableInventoryCount: ['true', 'false'] },
    },
};

export { RENTAL_STATUS, UNIT_DEFAULT_SORT, UNIT_DEFAULT_FILTER };
