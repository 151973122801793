import Icon from 'Commons/components/generic/icon/components/Icon';
import { mapEnum, createEnumMap } from 'Commons/helpers/utils/Formatter';
import localisable from 'Commons/config/strings/localisable';
import enums from '../../config/Enums';

const YES_NO_LIST = [
    {
        label: 'Yes',
        value: 'true',
    },
    {
        label: 'No',
        value: 'false',
    },
];
const REQUIRED_LIST = [
    {
        label: 'Always',
        value: 'always',
    },
    {
        label: 'On Demand',
        value: 'onDemand',
    },
    {
        label: 'Optional',
        value: 'optional',
    },
];


const SHOW_HIDE_LIST = [
    {
        label: 'Show',
        value: true,
    },
    {
        label: 'Hide',
        value: false,
    },
];

const ORIENTATION_LIST = [
    {
        label: 'Vertical',
        value: 'vertical',
    },
    {
        label: 'Horizontal',
        value: 'horizontal',
    },
];
const SECTION_NAMES = {
    BASIC_INFORMATION: 'Basic Information',
    OCCUPANCY: 'Occupancy',
    INSURANCE: 'Insurance',
    INVOICE: 'Invoice',
    LEASE: 'Lease',
    MAP: 'Map',
};
const EDIT_ACTION_ITEMS_LIST = [
    {
        addon: { start: <Icon type="custom" icon="cp-view" /> },
        label: 'Preview',
    },
    {
        addon: { start: <Icon>history</Icon> },
        label: 'History',
    },
    {
        addon: { start: <Icon type="custom" icon="cp-delete" /> },
        label: 'Delete',
    },
];

const PERIOD_LABEL_MAP = {
    Day: 'Daily',
    Month: 'Monthly',
    Week: 'Weekly',
    Quarter: 'Quarterly',
    Twenty_Eight_Day: '28-days',
    Bi_Annual: 'Bi-Annual',
    Annual: 'Annual',
    Seasonal: 'Seasonal',
};


const PERIOD = mapEnum(enums.period);
const PERIOD_WITH_CUSTOM_LABEL = mapEnum(enums.period, label => PERIOD_LABEL_MAP[label]);
const OVERLOCK_STATUS = mapEnum(enums.overlockStatus);
const RENTAL_STATUS = mapEnum(enums.rentalStatus);
const PROMO_PLAN_STATE = mapEnum(enums.promoPlanState);
const CALCULATION_STYLE = mapEnum(enums.calculationStyle, label => (label === 'Percentage' ? localisable.percentageOfUnitRate : label));
const VACANT_AVAILABILITY_STATUS = mapEnum(enums.vacantAvailabilityStatus);
const RENTED_AVAILABILITY_STATUS = mapEnum(enums.rentedAvailabilityStatus);
const LATE_EVENT_TRIGGER = mapEnum(enums.lateEventTrigger);
const LATE_EVENT_STATUS = mapEnum(enums.lateEventStatus);
const LEASE_STATUS = mapEnum(enums.leaseSignStatus);
const NON_OVERRIDABLE_FIELDS = ['availabilityStatus', 'rentalStatus', 'nextRentAssessmentDate',
    'nextAmountPayable', 'rentChargeCategoryId', 'nextRentAssessmentRate', 'currentRate'];

const MONTHS_IN_PERIOD = {
    [PERIOD.Month.value]: 1,
    [PERIOD.Quarter.value]: 3,
    [PERIOD.Bi_Annual.value]: 6,
    [PERIOD.Annual.value]: 12,
};

const NRAD_ACTION_TYPE = createEnumMap(enums.nradActionType);
const RATE_HISTORY_STATUS = mapEnum(enums.rateHistoryStatus);

const DEPENDENT_FIELDS = {
    NEXT_AMOUNT_PAYABLE: [{
        valueFieldName: 'currentRate',
        initFieldName: 'initialCurrentRate',
    }],
};

const RESERVATION_INFO_TENANT_NAME_LENGTH_BREAKPOINT = 25;
const RATE_HISTORY_WIDTH_FOR_RESPONSIVE = 1032;
const EXTRA_SPACE_IN_RATE_HISTORY_HEIGHT_FOR_RESPONSIVE = 108;
const EXTRA_SPACE_IN_RATE_HISTORY_HEIGHT = 78;
const EXTRA_SPACE_IN_RATE_HISTORY_WIDTH = 64;

export {
    PERIOD,
    YES_NO_LIST,
    RENTAL_STATUS,
    SECTION_NAMES,
    REQUIRED_LIST,
    SHOW_HIDE_LIST,
    DEPENDENT_FIELDS,
    OVERLOCK_STATUS,
    PROMO_PLAN_STATE,
    CALCULATION_STYLE,
    MONTHS_IN_PERIOD,
    NRAD_ACTION_TYPE,
    ORIENTATION_LIST,
    RATE_HISTORY_STATUS,
    NON_OVERRIDABLE_FIELDS,
    EDIT_ACTION_ITEMS_LIST,
    PERIOD_WITH_CUSTOM_LABEL,
    VACANT_AVAILABILITY_STATUS,
    RENTED_AVAILABILITY_STATUS,
    RATE_HISTORY_WIDTH_FOR_RESPONSIVE,
    EXTRA_SPACE_IN_RATE_HISTORY_WIDTH,
    EXTRA_SPACE_IN_RATE_HISTORY_HEIGHT,
    RESERVATION_INFO_TENANT_NAME_LENGTH_BREAKPOINT,
    EXTRA_SPACE_IN_RATE_HISTORY_HEIGHT_FOR_RESPONSIVE,
    LATE_EVENT_TRIGGER,
    LATE_EVENT_STATUS,
    PERIOD_LABEL_MAP,
    LEASE_STATUS,
};
