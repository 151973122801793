import STATUS from 'Commons/config/constants/StoreKeyStatus';
import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

const delinquencyPostProcessor = (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);

    const { data: allProcessedData, data: { data: tenants = [], totalCount: resTotalCount = 0, relationalData: statusList = {} } = {} } = processedData || {};
    const { Delinquency_Ledger_Info: processedLedgers = {} } = statusList;
    const data = [];

    const { storeData: { delinquency: { data: { actualRows = 0, totalCount = 0 } = {} } = {} } = {} } = localReqOptions || {};
    let newTotalCount = totalCount || resTotalCount;

    if (tenants && Array.isArray(tenants) && tenants.length) {
        tenants.forEach((tenant) => {
            const { ledger = [] } = tenant;
            ledger.forEach((eachLedger) => {
                const delinquencyInfo = processedLedgers[eachLedger.id] || [];
                data.push({ ...tenant, delinquencyInfo, ledger: [{ ...eachLedger }] });
            });
            newTotalCount += (ledger.length - 1);
        });
    }

    return {
        data: {
            ...allProcessedData,
            data,
            totalCount: newTotalCount,
            actualRows: actualRows + tenants.length,
        },
        status: STATUS.LOADED,
    };
};

export { delinquencyPostProcessor };
