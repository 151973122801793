import { mapEnum } from 'Commons/helpers/utils/Formatter';

const letterSource = ['Main_Application', 'Tenant_Portal', 'Kiosk', 'Pay_At_The_Gate'];
const paperSize = ['Standard', 'Legal'];
const letterFormat = ['html', 'tx'];
const buttonType = ['Save', 'Exit_Without_Saving', 'Custom_Merge_Fields', 'Insert_Merge_Field'];
const letterType = ['System'];

const LETTER_SOURCE = mapEnum(letterSource);
const PAPER_SIZE = mapEnum(paperSize);
const LETTER_FORMAT = mapEnum(letterFormat);
const BUTTON_TYPE = mapEnum(buttonType);
const LETTER_TYPE = mapEnum(letterType);
const MERGE_FIELD_SECTION_TYPE = { headerButton: 'Button' };


export { LETTER_SOURCE, PAPER_SIZE, LETTER_FORMAT, BUTTON_TYPE, LETTER_TYPE, MERGE_FIELD_SECTION_TYPE };
